import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import { useQuery } from '@apollo/client';
import { GET_ALL_MODULES } from '../../../graphql/Catalogs/Modules';
import Swal from 'sweetalert2';
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows';
import DataTable from 'react-data-table-component';
import DataTableApp from '../../Layout/DataTableApp';

const ModulesList = () => {
    const [list, setList] = useState([])

    const { loading, error, data } = useQuery(GET_ALL_MODULES)

    useEffect(() => {
        if (!loading) {
            if (error) {
                Swal.fire({
                    title: 'Ocurrio un error inesperado',
                    text: error,
                    icon: 'error'
                })
            }

            let list = []
            data.allModules.map((element) => {

                let buttons = <ActionButtonsForRows
                    baseUrl={'settings/modules'}
                    element={element}
                    editButton
                    showButton
                    deleteButton
                />

                return list.push({
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    path: element.path,
                    actions: buttons
                })
            })

            setList(list)
        }
    }, [loading, error, data])


    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },
        {
            name: 'Descripcion',
            selector: row => row.description,
            sortable: false,
        },
        {
            name: 'URL',
            selector: row => row.path,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]

    return (
        <DashboardLayout>
            <CardBox
                title={"Modulos"}
                cardTools
                loading={loading}
                content={
                    <>
                        <DataTableApp
                            data={list}
                            columns={columns}
                            progressPending={loading}
                            pagination
                            striped
                            dense
                        />
                    </>}
            />
        </DashboardLayout>
    );
};

export default ModulesList;