import React from 'react';

import { DashboardLayout } from './Layout/DashboardLayout'
import { Button } from "reactstrap"


const NotFound = () => {
    return (
        <DashboardLayout>
            <div className='error-page'>
                <div className='headline text-warning'>404</div>
                <div className='error-content'>
                    <h2 className='fas fa-exclamation-triangle text-warning'>Pagina no encontrada...</h2>
                    <p>
                    No pudimos encontrar la página que estabas buscando.
                        Mientras tanto, puedes <a href="/">volver al dashboard</a>.
                    </p>
                </div>

            </div>

        </DashboardLayout>
    )
}

export default NotFound