import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import { Col, Label, Row } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import DualListBox from 'react-dual-listbox'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ALL_SGA } from '../../../../graphql/Catalogs/SGA'
import { AlertApp } from '../../../Global/AlertApp'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// apollo
import {
    GET_ALL_HAZARDOUS_SUBSTANCE_TYPES,
} from '../../../../graphql/Catalogs/TypesOfHazardousSubstances'
import {  ONE_ITEM } from '../../../../graphql/Catalogs/BoItems'
import { CREATE_DANGEROUS_GOOD, GET_ONE_DANGEROUS_GOOD, UPDATE_DANGEROUS_GOOD } from '../../../../graphql/Catalogs/DangerousGoods'

const DangerousGoodsClass = () => {
    const { id: _id, show } = useParams()
    const navigate = useNavigate()
    const [hazardousSubstance, setHazardouesSubtance] = useState({})
    const [hazardousSubstances, setHazardouesSubtances] = useState({})
    const [isNeutral, setIsNeutral] = useState(false)
    const [optSGA, setOptSGA] = useState([])
    const [selectedSGA, setSelectedSGA] = useState([])
    const [waiting, setWaiting] = useState(false)


    const validationSchema = Yup.object().shape({
        itemCode: Yup.string().required('El numero de articulo es requerido'),
        itemName: Yup.string(),
        ItmsGrpCod: Yup.string().notRequired(),
        ph: Yup.bool().default(false).notRequired(),
        pHmin: Yup.number().notRequired(),
        pHmax: Yup.number().notRequired(),
        notes: Yup.string().notRequired()
    })
    const { control, handleSubmit, reset, getValues, setValue, setError , clearErrors} = useForm({ resolver: yupResolver(validationSchema) })

    const { loading, data, error } = useQuery(GET_ONE_DANGEROUS_GOOD, {
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })
    const { loading: loadingSubstances, data: dataSubstances, error: errorSubstances } = useQuery(GET_ALL_HAZARDOUS_SUBSTANCE_TYPES, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingSGA, data: dataSGA, error: errorSGA } = useQuery(GET_ALL_SGA)

    const [create] = useMutation(CREATE_DANGEROUS_GOOD)
    const [update] = useMutation(UPDATE_DANGEROUS_GOOD)
    const [findItem] = useLazyQuery(ONE_ITEM)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            }
            if (data.oneDangerouesItem) {
                reset(data.oneDangerouesItem)
                setHazardouesSubtance(data.oneDangerouesItem)
                setValue('ph', !data.oneDangerouesItem.ph)
                setValue('ItmsGrpCod', data.oneDangerouesItem.itemGroup.itmsGrpNam)
                let list = []
                data.oneDangerouesItem.dangerousInfo.map((element) => list.push(
                    element.substanceType.id
                ))
                setSelectedSGA(list)
            }
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingSubstances) {
            if (errorSubstances) {
                AlertApp({ type: 'errorToast', message: errorSubstances })
            } else {
                setHazardouesSubtances(dataSubstances.allHazardousSubstanceTypes)
                let list = []
                dataSubstances.allHazardousSubstanceTypes.map((element) => {
                    list.push({
                        value: element.id,
                        label: `${element.name}`,
                        url: element.categories?.length > 0 ?
                            element.categories?.map((category) => {
                                return require(`../../../../assets/images/SGA/${category.category.sga}`)
                            })
                            : [],
                    })
                })
                setOptSGA(list)
                setValue('ph', false)
            }
        }
    }, [loadingSubstances, errorSubstances, dataSubstances])

    useEffect(() => {
        if (!loadingSGA) {
            if (errorSGA) {
                AlertApp({ type: 'errorToast', message: errorSGA })
            } else {
                if (dataSGA.allRiskCategory) {
                    let list = []
                    dataSGA.allRiskCategory.map((element) => {
                        list.push({
                            value: element.id,
                            label: `${element.name}`,
                        })
                    })
                    // setOptSGA(list)
                }
            }
        }
    }, [loadingSGA, dataSGA, errorSGA])

    const onChangePH = () => {
        const ph = getValues('ph')
        setIsNeutral(ph)
        if (ph) {
            setValue('pHmax', 7)
            setValue('pHmin', 7)
        } else {
            setValue('pHmax', '')
            setValue('pHmin', '')
        }
    }
    const onFindItem = async (event) => {
        try {
            if (event.key === "Enter") {
                const findText = getValues('itemCode')
                clearErrors('itemCode')
                if (findText != '') {
                    const result = await findItem({
                        variables: {
                            code: findText
                        }
                    })
                    
                    if (result.data.oneItem.itemCode) {
                        setValue('itemCode', result.data.oneItem.itemCode)
                        setValue('itemName', result.data.oneItem.itemName)
                        setValue('ItmsGrpCod', result.data.oneItem.itemGroup.itmsGrpNam)
                    }else{
                        AlertApp({ type: 'errorToast', message: `No existe el articulo ${getValues('itemCode')}` })
                        setError('itemCode',"No existe el articulo")
                    }

                }
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onSubmit = async (data) => {
        try {
            setWaiting(true)
            if (!_id) {

                const result = await create({
                    variables: {
                        input: {
                            itemCode: data.itemCode,
                            typesIDs: selectedSGA,
                            pHmin: data.pHmin,
                            pHmax: data.pHmax,
                            note: data.notes,
                        }
                    }
                })
                if (result.data.createDangerousItem) {
                    AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                    navigate('/warehouses/dangerousitems')
                }
            } else {
                const result = await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            itemCode: data.itemCode,
                            typesIDs: selectedSGA,
                            pHmin: data.pHmin,
                            pHmax: data.pHmax,
                            note: data.notes,
                        }
                    }
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                navigate('/warehouses/dangerousitems')
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                loading={loading || loadingSGA || loadingSubstances || waiting}
                title={`${_id ? "S" : "Nueva s"}ustancia peligrosa ${_id ? _id : ""}`}
                footer
                btnSubmit={!show ? handleSubmit(onSubmit) : null}
                btnReturn={() => navigate(-1)}
                content={<>
                    <Row>
                        <Col>
                            <InputApp name={"itemCode"} label={"Número de artículo"} control={control} onKeyDown={(e) => onFindItem(e)} isReadOnly={show || _id} />
                        </Col>
                        <Col>
                            <InputApp name={"itemName"} label={"Descripción"} control={control} isReadOnly={true} />
                        </Col>
                        <Col>
                            <InputApp name={"ItmsGrpCod"} label={"Grupo de artículos"} control={control} isReadOnly={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <InputApp name={"ph"} label={"Neutro"} inputType='checkbox' control={control} changeAction={() => onChangePH()} isReadOnly={show} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2}>
                                    <InputApp name={"pHmin"} label={"Escala de pH"} placeholder={"N/A"} control={control} onlyNumbers={true} step={".1"} isReadOnly={show || isNeutral} />
                                </Col>
                                <Col lg={2}>
                                    <InputApp name={"pHmax"} label={"Escala de pH"} placeholder={"N/A"} control={control} onlyNumbers={true} step={".1"} isReadOnly={show || isNeutral} />
                                </Col>
                                <Col>
                                    <InputApp inputType='textarea' name={"notes"} label={"Comentarios"} placeholder={"Escribir comentario adicional"} control={control} onlyNumbers={true} step={".1"} isReadOnly={show} />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Label>SGA:</Label>
                            <DualListBox
                                options={optSGA}
                                selected={selectedSGA}
                                onChange={(newValue) => {
                                    console.log(newValue)
                                    setSelectedSGA(newValue)
                                }}

                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            {!loadingSubstances > 0 && (selectedSGA.map((element) => {
                                const sga = optSGA.find((option) => option.value == element)
                                return sga.url?.map((image) => {

                                    return <img src={image} key={element}></img>
                                })

                            }))}
                        </Col>
                    </Row>
                </>}
            />
        </DashboardLayout>
    )
}

export default DangerousGoodsClass