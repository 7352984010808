import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import InputApp from '../../Layout/InputApp';
import { useMutation, useQuery } from '@apollo/client';
import { AlertApp } from '../../Global/AlertApp';
// apollo
import { CREATE_MODULE, GET_MODULE, UPDATE_MODULE } from '../../../graphql/Catalogs/Modules';

const Module = () => {
    const [loading, setLoading] = useState(false)
    const [module, setModule] = useState([])
    const { id: _id, show } = useParams()
    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre del modulo es requerido'),
        description: Yup.string().required('La descripcion del modulo es requerida'),
        path: Yup.string().required('El path del modulo es requerido'),
    })

    const { handleSubmit, reset, control, methods } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const { loading: loadingModule, error: errorModule, data: dataModule } = useQuery(GET_MODULE, {
        fetchPolicy: 'network-only',
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })
    const [create] = useMutation(CREATE_MODULE)
    const [update] = useMutation(UPDATE_MODULE)

    useEffect(() => {
        if (_id) {
            if (!loadingModule) {
                if (errorModule) {
                    AlertApp({type: 'error', message: errorModule})
                }
                setModule(dataModule.oneModule)
                reset(dataModule.oneModule)
            }
        }
    }, [loadingModule, errorModule, dataModule])

    const submitHandler = async (data) => {
        try {
            setLoading(true)
            if (_id) {
                await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            name: data.name,
                            description: data.description,
                            path: data.path,
                        }
                    }
                })

                AlertApp({ message:'Operacion finalizada con exito'})
                navigate('/settings/modules')
            } else {
                await create({
                    variables: {
                        input: {
                            name: data.name,
                            description: data.description,
                            path: data.path,
                        }
                    }
                })
                AlertApp({message:'Operacion finalizada con exito'})
                navigate('/settings/modules')
            }
        } catch (error) {
            AlertApp({type:'error', message: error})
        } finally {
            setLoading(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={`Modulo ${module ? module.id : ''}`}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/modules")}
                loading={loading || loadingModule}
                footer
                content={
                    <>
                        <FormProvider {...methods}>
                            <form>
                                <Row>
                                    <Col>
                                        <InputApp name={"name"} control={control} label={"Nombre"} tabIndex={1} isReadOnly={show} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputApp name={"description"} control={control} label={"Descripcion"} tabIndex={2} isReadOnly={show} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputApp name={"path"} control={control} label={"Ruta de acceso"} tabIndex={3} isReadOnly={show} />
                                    </Col>
                                </Row>
                            </form>
                        </FormProvider>

                    </>
                }
            />
        </DashboardLayout>
    );
};

export default Module;