import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { Badge, Col, Row } from 'reactstrap'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import InputApp from '../../../Layout/InputApp'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import { AlertApp } from '../../../Global/AlertApp';


// apollo
import { GET_ONE_BUSINESS_STAGE_TASK, CREATE_BUSINESS_STAGE_TASK, UPDATE_BUSINESS_STAGE_TASK, GET_ALL_COMMERCIAL_STAGES, GET_ONE_COMERCIAL_STAGE } from '../../../../graphql/Catalogs/CommercialStage';

function CommercialSubStagesNew() {
    const { id: _id, show } = useParams()
    const [listOfStages, setListOfStages] = useState([])
    const [listOfCategorys, setListOfCategorys] = useState([])
    const [stageTask, setStageTask] = useState([])
    const [waiting, setWaiting] = useState(false)
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        baseID: Yup.number().required('Se debe seleccionar la etapa de Oportunidad'),
        name: Yup.string().required('El nombre de la tare es obligatoria'),
        description: Yup.string().default(''),
        stepID: Yup.number().required('Se debe seleccionar la etapa de Oportunidad'),
    })

    const { handleSubmit, reset, control, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const validationType = [
        { label: 'Sin validacion', value: 0 },
        { label: 'Informacion en socio de negocio', value: 1 },
        { label: 'Anexar documento', value: 2 },
    ]

    const { loading, error, data } = useQuery(GET_ONE_BUSINESS_STAGE_TASK, {
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })

    const { loading: loadingCommercialStages, error: errorCommercialStages, data: dataCommercialStages } = useQuery(GET_ALL_COMMERCIAL_STAGES, {
        fetchPolicy: 'cache-and-network',
    })

    const [create] = useMutation(CREATE_BUSINESS_STAGE_TASK)
    const [update] = useMutation(UPDATE_BUSINESS_STAGE_TASK)
    const [oneComercialStage] = useLazyQuery(GET_ONE_COMERCIAL_STAGE)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            }
            if (data.oneBusinessStageTask) {
                reset(data.oneBusinessStageTask)
                setStageTask(data.oneBusinessStageTask)
            }
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingCommercialStages) {
            if (errorCommercialStages) {
                AlertApp({ type: 'errorToast', message: errorCommercialStages })
            }
            let list = []
            if (dataCommercialStages) {

                dataCommercialStages.allCommercialStage.map((element) => {
                    return list.push({
                        value: element.id,
                        step: element.stepId,
                        label: element.name,
                    })
                })

            }
            setListOfStages(list)
        }
    }, [loadingCommercialStages, errorCommercialStages, dataCommercialStages])

    const sumbitSelectCommercialStage = async (e) => {

        const { data: result } = await oneComercialStage({
            variables: { id: parseInt(e.target.value) },
            fetchPolicy: 'network-only'
        })
       
        setValue('stepID', result.oneCommercialStage.totalTasks + 1)
        if(result.oneCommercialStage.categories.length > 0){
            let list = []
            result.oneCommercialStage.categories.map((element) => {
                return list.push({
                    value: element.id,
                    label: element.name,
                })
            })
           
            setListOfCategorys(list)
        }
       
        
    }

    const submitHandler = async (data) => {
        try {

            setWaiting(true)
            if (!_id) {
                await create({
                    variables: {
                        input: {
                            name: data.name,
                            description: data.description,
                            baseID: data.baseID,
                            stepId: data.stepID,
                            hasValidations: false,
                        }
                    }
                })

                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialsubstages')
            } else {

                await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            name: data.name,
                            description: data.description,
                            baseID: data.baseID,
                            stepId: data.stepID,
                            hasValidations: false,
                        }
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialsubstages')
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Sub etapas comerciales"}
                footer
                loading={loading || loadingCommercialStages}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/sales/commercialsubstages")}
                content={
                    <FormProvider>
                        <form>
                            <Row>
                                <Col>
                                    <InputApp inputType={"select"} name={"stageID"} label={"Etapa de oportunidad ligada"} listOfOptions={listOfStages} control={control} changeAction={(e) => sumbitSelectCommercialStage(e)} />
                                </Col>
                                <Col>
                                    <InputApp inputType={"select"} name={"baseID"} label={"Categoria"} listOfOptions={listOfCategorys} control={control} />
                                </Col>
                                <Col>
                                    <InputApp name={"stepID"} label={"Número de etapa"} placeholder={"Indice"} control={control} isReadOnly={true} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp name={"name"} label={"Etapa comercial"} placeholder={"Nombre de la sub etapa comercial"} control={control} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp inputType={"select"} name={"validationType"} label={"Validaciones"} control={control} listOfOptions={validationType} />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                    <InputApp name={"fieldName"} label={"Nombre de campo"} placeholder={"Nombre de campo en socios de negocio"} control={control} />
                                </Col>
                                <Col>
                                    <InputApp name={"fieldValue"} label={"Valor de campo"} placeholder={"Valor de campo en socios de negocio"} control={control} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp name={"doc"} label={"Requiere anexar formato"} placeholder={"Nombre de campo en socios de negocio"} control={control} />
                                </Col>
                                <Col>
                                    <InputApp name={"fieldValue"} label={"Url de formato"} placeholder={"Seleccionar un formato"} control={control} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp name={"doc"} label={"Requiere anexar formato"} placeholder={"Nombre de campo en socios de negocio"} control={control} />
                                </Col>
                                <Col>
                                    <InputApp name={"fieldValue"} label={"Url de formato"} placeholder={"Seleccionar un formato"} control={control} />
                                </Col>
                            </Row> */}
                        </form>
                    </FormProvider>
                }
            />
        </DashboardLayout>
    )
}

export default CommercialSubStagesNew