import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

function ToDoListLayout({ data = [] }) {
    const navigate = useNavigate()
    const descriptionTimer = (date) => {
        let timeEnd = new Date()
        let hours = Math.abs( moment(date).diff(moment(timeEnd), 'hours'))
        if (hours < 1) {
            return `${Math.abs(moment(date).diff(moment(timeEnd), 'minutes'))} min`
        }
       
        if (hours > 23) {
            return `${Math.abs(moment(date).diff(moment(timeEnd), 'days'))} dias`
        }
        return `${hours} hrs`
    }
    return (
        <ul className="todo-list" data-widget="todo-list">
            {data.map((element) => {

                return <li key={element.id} className={element.status === "L" ? 'done' : ''}>
                    {/* <!-- drag handle --> */}
                    <span className="handle">
                        <i className="fas fa-ellipsis-v"></i>
                        <i className="fas fa-ellipsis-v"></i>
                    </span>
                    {/* <!-- checkbox --> */}
                    <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" readOnly checked={element.status === "L"} name={`toDoCheck${element.id}`} id={`toDoCheck${element.id}`} />
                        <label></label>
                    </div>
                    {/* <!-- todo text --> */}
                    <span className="text">{element.title}</span>
                    {/* <!-- Emphasis label --> */}
                    <small className={`badge badge-${element.badgeType}`}><i className="far fa-clock"></i> {`${descriptionTimer(element.status === "L" ? element.closeDate : element.actionDate)}`}</small>
                    {/* <!-- General tools such as edit or delete--> */}
                    <div className="tools">
                        {element.status === "O" && (
                            <>
                                <i className="fas fa-edit" onClick={() => navigate(`/crm/sales/salesvisit/edit/${element.id}`)}></i>
                            </>
                        )}

                        <i className="fas fa-trash-o"></i>
                    </div>
                </li>
            })}
        </ul>
    )
}

export default ToDoListLayout