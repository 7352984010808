import moment from "moment";
import { AlertApp } from "./components/Global/AlertApp";

export const SumarRestarDias = (fecha, operacion, dias) => {
  var date = moment(fecha).format("yyyy-MM-DD").split("-"),
    hoy = new Date(date[0], date[1], date[2]),
    dias = parseInt(dias),
    calculado = new Date(),
    dateResul =
      operacion == "sumar" ? hoy.getDate() + dias : hoy.getDate() - dias;
  calculado.setDate(dateResul);
  return calculado;
};

export const DownloadingFiles = (target, targetID, name, type) => {
  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : ""
  );

  fetch(`${process.env.REACT_APP_SORE_HOST}/sore/`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      target: target,
      id: targetID,
    }),
    redirect: "follow",
  })
    .then((response) => {
      response.blob().then((blob) => {
        if (blob != undefined) {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], {
              type: type,
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noreferrer";
          // link.setAttribute(
          //     'download',
          //     `${name}`,
          // );

          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          AlertApp({
            type: "errorToast",
            message: "No se encontro el archivo solicitado",
          });
        }
      });
    })
    .catch((error) => AlertApp({ type: "errorToast", message: error }));
};

export const currencyFormat = (itemValue, currency) => {
  return (
    `${currency}` +
    itemValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};
