import { gql} from "@apollo/client"


export const GET_ONE_BUSINESS_STAGE_TASK = gql`
query oneBusinessStageTask($id: Int!) {
    oneBusinessStageTask(id: $id) {
      id
      name
      description
      baseID
      stepID
      status
      hasValidations
      category {
        id
        name
        baseID
        commercialStage {
          id
          name
          stepId
        }
      }
    }
  }
`

export const GET_ONE_COMERCIAL_STAGE = gql`
query oneCommercialStage($id: Int!) {
    oneCommercialStage(id: $id) {
      id
      name
      description
      entry
      stepId
      cloPrcnt
      totalTasks
      categories {
        id
        name
        baseID
        tasks {
          id
          name
          description
          stepID
          baseID
          status
          hasValidations
        }
      }
    }
  }
`

export const GET_ALL_COMMERCIAL_STAGES = gql`
query allCommercialStage {
    allCommercialStage {
      id
      name
      description
      entry
      stepId
      cloPrcnt
      baseID
      totalTasks
      categories {
        id
        name
        baseID
        tasks {
          id
          name
          description
          baseID
          stepID
          status
          hasValidations
        }
      }
    }
  }  
`

export const GET_ALL_BUSINESS_STAGES = gql`
query allBusinessStageTask {
    allBusinessStageTask {
      id
      name
      description
      baseID
      status
      hasValidations
      stepID
      category {
        id
        name
        baseID
        commercialStage {
          id
          name
        }
      }
    }
  }
`

export const CREATE_COMERCIAL_STAGE = gql`
mutation createCommercialStage($input: CommercialStageInput!) {
  createCommercialStage(input: $input) {
    id
    name
    description
    entry
    stepId
    cloPrcnt
    baseID
  }
}` 

export const CREATE_BUSINESS_STAGE_TASK= gql`
mutation createBusinessStageTask($input: BusinessStageTaskInput!) {
    createBusinessStageTask(input: $input) {
      id
      name
      description
      baseID
      status
      hasValidations
      category {
        id
        name
      }
    }
  }
`

export const UPDATE_BUSINESS_STAGE_TASK= gql`
mutation updateBusinessStageTask($id: Int!, $input: BusinessStageTaskInput!) {
    updateBusinessStageTask(id: $id, input: $input) {
      id
      name
      description
      baseID
      status
      hasValidations
      category {
        id
        name
      }
    }
  }
`

export const DELETE_BUSINESS_STAGE_TASK= gql`
mutation deleteBusinessStageTask($id: Int!){
    deleteBusinessStageTask(id: $id)
  }
`