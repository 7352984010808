import { gql} from "@apollo/client"

export const GET_ALL_MODULES = gql`
query allModules {
    allModules {
      id
      name
      description
      path
    }
  }
`;

export const GET_MODULE = gql`
query oneModule($id: Int!) {
    oneModule(id: $id) {
      id
      name
      description
      path
    }
  }
`;

export const CREATE_MODULE = gql`
mutation createModule($input: ModuleInput!) {
    createModule(input: $input) {
      id
      name
      description
      path
    }
  }
`;

export const UPDATE_MODULE = gql`
mutation updateModule($id: Int!, $input: ModuleInput!) {
    updateModule(id: $id, input: $input) {
      id
      name
      description
      path
    }
  }
`;

export const DELETE_MODULE = gql`
mutation deleteModule($id: Int!){
    deleteModule(id: $id)
 }
`;