import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Progress, Row } from 'reactstrap'
import { useQuery } from '@apollo/client';
import CardBox from '../../Layout/CardBox';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertApp } from '../../Global/AlertApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputApp from '../../Layout/InputApp'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { ActionsSBOType, CardTypeOptions, CurrencyCodesTypesSBO, DivisionTypeOptions, paymentTermCodesTypesSBO } from '../../../Types';
import moment from 'moment';
// apollo
import { GET_ALL_INTEREST_LEVEL, GET_ALL_TERRITORYS, GET_ALL_INDUSTRY } from '../../../graphql/QueryManager'
import { CREATE_OPPORTUNITY, GET_OPPORTUNITY, UPDATE_OPPORTUNITY, CREATE_CONTACT_PERSON, UPDATE_CONTACT_PERSON, PROGRESS_OPPORTUNITY, CREATE_ADDRESSS_CARD, UPDATE_ADDRESSS_CARD } from '../../../graphql/Catalogs/Opportunities';
import ContactCardTab from './ContactCardTab';
import { GET_ALL_SALES_VISITS } from '../../../graphql/Catalogs/ScheduleSalesVisit';
function CommercialStagesNew() {
  const { id: _id, show } = useParams()
  const navigate = useNavigate()
  const [opportunity, setOpportunity] = useState([])
  const [menu, setMenu] = useState(1)
  const [optOfInterestLevel, setOptOfInterestLevel] = useState([])
  const [optOfTerritorys, setOptOfTerritorys] = useState([])
  const [optOfIndustrys, setOptOfIndustrys] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [listOfSchedules, setListOfSchedules] = useState([])
  const [listOfEvents, setListOfEvents] = useState([])


  const validationSchema = Yup.object().shape({
    status: Yup.string(),
    title: Yup.string(),
    type: Yup.string(),
    start: Yup.date(),
    end: Yup.date(),
    predEnd: Yup.date(),
    division: Yup.string(),
    memo: Yup.string(),
    commercialProcess: Yup.object(),
    cloPrcnt: Yup.number(),
    currentStage: Yup.number(),
    stage: Yup.object(),
    cardCode: Yup.string(),
    cardName: Yup.string(),
    cardType: Yup.string(),
    card: Yup.object(),
    slpCode: Yup.number(),
    cprCode: Yup.number(),
    territryID: Yup.number(),
    intRate: Yup.number(),
    numReason: Yup.number(),
    completedTasks: Yup.number(),
    completedCurrentTasks: Yup.number(),
  })

  const { handleSubmit, control, methods, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { loading, data, error } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: _id ? parseInt(_id) : 0
    }
  })

  const { loading: loadingInterestLevel, error: errorInterestLevel, data: dataInterestLevel } = useQuery(GET_ALL_INTEREST_LEVEL, {
    fetchPolicy: 'cache-and-network',
  })

  const { loading: loadingTerritorys, error: errorTerritorys, data: dataTerritorys } = useQuery(GET_ALL_TERRITORYS, {
    fetchPolicy: 'cache-and-network',
  })

  const { loading: loadingIndustry, error: errorIndustry, data: dataIndustry } = useQuery(GET_ALL_INDUSTRY, {
    fetchPolicy: 'cache-and-network',
  })
  const { loading: loadingSchedules, error: errorSchedules, data: dataSchedules } = useQuery(GET_ALL_SALES_VISITS, {
    variables: {
      id: _id ? parseInt(_id) : 0
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (_id)
      if (!loading) {
        if (error) {
          AlertApp({ type: 'error', message: error })
        }

        if (data.oneOpportunity) {
          setOpportunity(data.oneOpportunity)
          reset(data.oneOpportunity)

          setValue("start", moment(new Date(data.oneOpportunity.start)).format('YYYY-MM-DD'))
          setValue("predEnd", moment(new Date(data.oneOpportunity.start)).format('YYYY-MM-DD'))
        }
      }
  }, [loading, data, error])

  useEffect(() => {
    if (!loadingInterestLevel) {
      if (errorInterestLevel) {
        AlertApp({ type: 'errorToast', message: errorInterestLevel })
      }
      let list = []
      if (dataInterestLevel) {

        dataInterestLevel.allInterestLevel.map((element) => {
          return list.push({
            value: element.num,
            label: element.descript,
          })
        })

      }
      setOptOfInterestLevel(list)
    }
  }, [loadingInterestLevel, errorInterestLevel, dataInterestLevel])

  useEffect(() => {
    if (!loadingTerritorys) {
      if (errorTerritorys) {
        AlertApp({ type: 'errorToast', message: errorTerritorys })
      }
      let list = []
      if (dataTerritorys) {

        dataTerritorys.allTerritrys.map((element) => {
          return list.push({
            value: element.territryID,
            label: element.descript,
          })
        })

      }
      setOptOfTerritorys(list)
    }
  }, [loadingTerritorys, errorTerritorys, dataTerritorys])

  useEffect(() => {
    if (!loadingIndustry) {
      if (errorIndustry) {
        AlertApp({ type: 'errorToast', message: errorIndustry })
      }
      let list = []
      if (dataIndustry) {

        dataIndustry.allIndustrys.map((element) => {
          return list.push({
            value: element.indCode,
            label: element.indName,
          })
        })

      }
      setOptOfIndustrys(list)
    }
  }, [loadingIndustry, errorIndustry, dataIndustry])

  useEffect(() => {
    if (!loadingSchedules) {
      if (errorSchedules) {
        AlertApp({ type: 'errorToast', message: errorSchedules })
      }
      let list = []
      let events = []
      if (dataSchedules.allSalesVisitByOpportunityID) {

        dataSchedules.allSalesVisitByOpportunityID.map((element) => {

          events.push({
            id: element.id,
            title: element.title,
            start: element.actionDate,
            end: '2024-03-22',
            backgroundColor: '#0073b7', //Blue
            borderColor: '#0073b7' //Blue
          })
          let salesVisit = { ...element }
          salesVisit.badgeType = 'info'
          return list.push(salesVisit)
        })
      }
      setListOfSchedules(list)
      setListOfEvents(events)
    }
  }, [loadingSchedules, errorSchedules, dataSchedules])
  const addButtonsTools = () => {
    return (
      <>
        <a className="btn btn-success" href={`${location.pathname}/add`}>
          <FontAwesomeIcon icon={"plus-square"} />{" Agendar Visita"}
        </a>

      </>

    )
  }



  const addPageLink = (element) => {
    return <li key={element.id} className="page-item"><a href="#" onClick={() => setMenu(element.id)} className="page-link">{element.name}</a></li>
  }

  const addCheckButton = (element) => {
    const task = opportunity.content.find((row) => row.task.id == element.id)
    if (!task)
      return <></>
    
    const shelude = listOfSchedules.find((row) => row.tasks.some((content) => {
      return content.opportunityRowID == task.lineNum
    }))
    
    return <li key={element.id} className={`${task ? task.complete ? 'done' : '' : ''}`}>
      <span className="handle">
        <i className="fas fa-ellipsis-v"></i>
        <i className="fas fa-ellipsis-v"></i>
      </span>
      <div className="icheck-primary d-inline ml-2">
        <input type="checkbox" defaultChecked={task ? task.complete : false} />

      </div>
      <span className="text">{element.name}</span>
      {shelude && (
        <small className={`badge badge-${shelude.badgeType}`}><i class="far fa-clock"></i> Agendado en {ActionsSBOType[shelude.action]} {shelude.id} </small>
      )}
      <div className="tools">
       <button className='btn btn-sm'> <i className="fas fa-edit"></i> </button>
        <i className="fas fa-trash-o"></i>
      </div>
    </li>
  }

  const addressesForm = (addressType) => {
    return <>
      {/* <Row>
        <Col>
          <InputApp name={`${addressType}.tel1`} label={"Pais"} control={control} />
        </Col>
        <Col>
          <InputApp name={"tel1"} label={"Estado"} control={control} />
        </Col>
        <Col>
          <InputApp name={"lastName"} label={"Ciudad"} control={control} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputApp name={"state"} label={"Calle/ Número"} control={control} />
        </Col>
        <Col>
          <InputApp name={"lastName"} label={"Colonia"} control={control} />
        </Col>
        <Col>
          <InputApp name={"e_mail"} label={"Código Postal"} control={control} />
        </Col>
      </Row> */}
    </>

  }
  const submitHandler = async (data) => {
    
    try {
      setWaiting(true)

      if (_id) {


        AlertApp({ message: 'Operacion finalizada con exito' })
        navigate('/settings/modules')
      } else {

        AlertApp({ message: 'Operacion finalizada con exito' })
        navigate('/settings/modules')
      }
    } catch (error) {
      AlertApp({ type: 'error', message: error })
    } finally {
      setWaiting(false)
    }
  }
  return (
    <DashboardLayout>
      <CardBox
        title={`Oportunidad [${_id}] `}
        btnSubmit={!show ? handleSubmit(submitHandler) : null}
        btnReturn={() => navigate("/crm/commercialstages/")}
        actionTools={addButtonsTools()}
        footer
        content={<FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Card>
              <CardHeader>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id={`tab-opportunity-tab`} data-toggle="pill" href={`#tabs-one`} role="tab" aria-controls={`tabs-one`} aria-selected="true">Oportunidad</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id={`tabs-forms-tab`} data-toggle="pill" href={`#tabs-two`} role="tab" aria-controls={`tabs-two`} aria-selected="false">Datos maestros</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id={`tabs-task-tab`} data-toggle="pill" href={`#tabs-three`} role="tab" aria-controls={`tabs-three`} aria-selected="false">Etapas</a>
                  </li>
                </ul>
              </CardHeader>
              <CardBody>
                <div className="tab-content">
                  <div className="tab-pane fade active show" id={`tabs-one`} role="tabpanel" aria-labelledby={`tab-opportunity-tab`}>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <InputApp name={"commercialProcess.name"} label={"Proceso comercial"} control={control} isReadOnly={true} />
                          </Col>
                          <Col>
                            <InputApp name={"start"} label={"Fecha de inicio"} inputType='date' control={control} isReadOnly={show || _id} />
                          </Col>
                          <Col>
                            <InputApp name={"status"} label={"Status"} control={control} defaultValue={"Abierto"} isReadOnly={true} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"title"} label={"Titulo"} control={control} placeholder={'Nombre de la oportunidad'} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"intRate"} label={"Nivel de interes"} control={control} inputType={"select"} listOfOptions={optOfInterestLevel} />
                          </Col>

                          <Col>
                            <InputApp name={"predEnd"} label={"Fecha de cierre prevista"} inputType='date' control={control} />
                          </Col>

                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"slpCode"} label={"Representate"} control={control} inputType={"select"} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"division"} label={"Division"} inputType='select' listOfOptions={DivisionTypeOptions} control={control} />
                          </Col>
                          <Col>
                            <InputApp name={"territryID"} label={"Territorio"} control={control} inputType={"select"} listOfOptions={optOfTerritorys} />
                          </Col>
                          <Col>
                            <InputApp name={"indCode"} label={"Ramo"} control={control} inputType={"select"} listOfOptions={optOfIndustrys} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"memo"} label={"Comentarios"} inputType='textarea' control={control} />
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col xs={3}>
                            <InputApp name={"cardType"} label={`Tipo de socio`} inputType='select' listOfOptions={CardTypeOptions} control={control} isReadOnly={show || _id} />
                          </Col>
                          <Col>
                            <InputApp name={"cardCode"} label={"Código de negocio"} control={control} placeholder={'Código de socio negocio'} onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }} isLoading={waiting} isReadOnly={show || _id} />
                          </Col>
                          <Col>
                            <InputApp name={"cardName"} label={"Nombre de negocio"} control={control} placeholder={'Razón social'} isReadOnly={show || _id} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputApp name={"stage.name"} label={"Etapa"} control={control} isReadOnly={true} />
                          </Col>
                          <Col>
                            <InputApp name={"completedTasks"} label={"Tareas completadas"} control={control} isReadOnly={true} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Progress value={opportunity.stage?.cloPrcnt} style={{ height: '50px' }} > %{opportunity.stage?.cloPrcnt} {opportunity.stage?.name} </Progress>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="tab-pane fade" id={`tabs-two`} role="tabpanel" aria-labelledby={`tab-forms-tab`}>

                    <Row>
                      {/* Datos maestros de socio de negocio */}
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <InputApp name={"card.cardType"} label={`Tipo de socio`} inputType='select' listOfOptions={CardTypeOptions} control={control} />
                              </Col>
                              <Col>
                                <InputApp name={"card.cardCode"} label={`Código de negocio`} control={control} />
                              </Col>
                              <Col>
                                <InputApp name={"card.cardName"} label={`Razón social`} control={control} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <InputApp name={"card.cardFName"} label={`Nombre Comercial`} control={control} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <InputApp name={"card.currency"} label={`Moneda`} inputType='select' listOfOptions={CurrencyCodesTypesSBO} control={control} />
                              </Col>
                              <Col>
                                <InputApp name={"card.groupNum"} label={`Condiciones comerciales`} inputType='select' listOfOptions={paymentTermCodesTypesSBO} control={control} />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <InputApp name={"card.paymentProcess"} label={`Proceso de pago`} inputType='textarea' control={control} />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className='card-secondary'>
                              <CardHeader>
                                <CardTitle>Direcciones</CardTitle>
                                <div className={"card-tools"}>
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a className="nav-link active" id={`tab-bildToDef-tab`} data-toggle="pill" href={`#tabs-bildToDef`} role="tab" aria-controls={`tabs-bildToDef`} aria-selected="true">Facturacion</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id={`tabs-shipToDef-tab`} data-toggle="pill" href={`#tabs-shipToDef`} role="tab" aria-controls={`tabs-shipToDef`} aria-selected="false">Entrega</a>
                                    </li>
                                  </ul>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="tab-content">
                                  <div className="tab-pane fade active show" id={`tabs-bildToDef`} role="tabpanel" aria-labelledby={`tab-bildToDef-tab`}>
                                    {addressesForm("bildToDef")}
                                  </div>
                                  <div className="tab-pane fade" id={`tabs-shipToDef`} role="tabpanel" aria-labelledby={`tab-shipToDef-tab`}>
                                    {addressesForm("shipToDef")}
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                        </Row>
                      </Col>
                      {/* Personas de contacto */}
                      <Col>
                        <Row>
                          <Col>
                            <Card className='card-secondary'>
                              <CardHeader>
                                <CardTitle>Contactos</CardTitle>
                                <div className={"card-tools"}>
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a className="nav-link active" id={`tab-cprPurCode-tab`} data-toggle="pill" href={`#tabs-cprPurCode`} role="tab" aria-controls={`tabs-cprPurCode`} aria-selected="true">Compras</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id={`tabs-cprPymntCode-tab`} data-toggle="pill" href={`#tabs-cprPymntCode`} role="tab" aria-controls={`tabs-cprPymntCode`} aria-selected="false">Pagos</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id={`tabs-cprWhsCode-tab`} data-toggle="pill" href={`#tabs-cprWhsCode`} role="tab" aria-controls={`tabs-cprWhsCode`} aria-selected="false">Almacén</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id={`tabs-cprPrcsCode-tab`} data-toggle="pill" href={`#tabs-cprPrcsCode`} role="tab" aria-controls={`tabs-cprPrcsCode`} aria-selected="false">Procesos</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id={`tabs-cprUsrCode-tab`} data-toggle="pill" href={`#tabs-cprUsrCode`} role="tab" aria-controls={`tabs-cprUsrCode`} aria-selected="false">Usuario</a>
                                    </li>
                                  </ul>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="tab-content">
                                  <div className="tab-pane fade active show" id={`tabs-cprPurCode`} role="tabpanel" aria-labelledby={`tab-cprPurCode-tab`}>
                                    {<ContactCardTab contactPerson={opportunity.card?.contactPersons.find((row) => row.id == opportunity.card?.cprCode)} />}
                                  </div>
                                  <div className="tab-pane fade" id={`tabs-cprPymntCode`} role="tabpanel" aria-labelledby={`tab-cprPymntCode-tab`}>
                                    {<ContactCardTab contactPerson={opportunity.card?.contactPersons.find((row) => row.id == opportunity.card?.cprPymntCode)} />}

                                  </div>
                                  <div className="tab-pane fade" id={`tabs-cprWhsCode`} role="tabpanel" aria-labelledby={`tab-cprWhsCode-tab`}>
                                    {<ContactCardTab contactPerson={opportunity.card?.contactPersons.find((row) => row.id == opportunity.card?.cprWhsCode)} />}
                                  </div>
                                  <div className="tab-pane fade" id={`tabs-cprPrcsCode`} role="tabpanel" aria-labelledby={`tab-cprPrcsCode-tab`}>
                                    {<ContactCardTab contactPerson={opportunity.card?.contactPersons.find((row) => row.id == opportunity.card?.cprPrcsCode)} />}
                                  </div>
                                  <div className="tab-pane fade" id={`tabs-cprUsrCode`} role="tabpanel" aria-labelledby={`tab-cprUsrCode-tab`}>
                                    {<ContactCardTab contactPerson={opportunity.card?.contactPersons.find((row) => row.id == opportunity.card?.cprUsrCode)} />}
                                  </div>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <button type={"button"} className={"btn btn-success"}>Actualizar</button>

                              </CardFooter>

                            </Card>
                          </Col>

                        </Row>
                      </Col>
                    </Row>


                  </div>
                  <div className="tab-pane fade" id={`tabs-three`} role="tabpanel" aria-labelledby={`tabs-task-tab`}>
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          <i className="ion ion-clipboard mr-1"></i>
                          Check List de etapas comerciales {`(Etapa ${menu})`}
                        </CardTitle>
                        <div className="card-tools">
                          <ul className="pagination pagination-sm">
                            <li className="page-item"><a href="#" onClick={() => setMenu(menu - 1)} className="page-link">&laquo;</a></li>
                            {opportunity.commercialProcess?.stages?.map((element) => {
                              return addPageLink(element)
                            })}
                            <li className="page-item"><a href="#" onClick={() => setMenu(menu + 1)} className="page-link">&raquo;</a></li>
                          </ul>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {opportunity.commercialProcess?.stages?.map((stage) => {
                          return (
                            <>
                              <ul key={stage.id} className="todo-list" data-widget="todo-list" hidden={stage.id != menu}>
                                <Card className={"card-tabs"}>
                                  <CardHeader>
                                    <ul className="nav nav-tabs" id="category-tabs-one-tab" role="tablist">
                                      {stage.categories.map((category) => {
                                        return (
                                          <li className="nav-item" key={category.id}>
                                            <a className="nav-link" id={`category-tabs-one-${category.id}-tab`} data-toggle="pill" href={`#category-tabs-one-${category.id}`} role="tab" aria-controls={`category-tabs-one-${category.id}`} aria-selected="true">{category.name}</a>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </CardHeader>
                                  <CardBody key={"check-list"}>
                                    <div className="tab-content">
                                      {stage.categories.map((category) => {
                                        return (
                                          <>
                                            <div key={category.id} className="tab-pane fade" id={`category-tabs-one-${category.id}`} role="tabpanel" aria-labelledby={`category-tabs-one-${category.id}-tab`}>
                                              <ul key={category.id} className="todo-list" data-widget="todo-list" hidden={stage.id != menu}>
                                                {category.tasks.map((task) => {
                                                  return addCheckButton(task)
                                                })
                                                }
                                              </ul>
                                            </div>
                                          </>
                                        )
                                      })}
                                    </div>
                                  </CardBody>
                                </Card>
                              </ul>
                            </>

                          )
                        })
                        }
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </form>
        </FormProvider>}
      />
    </DashboardLayout>
  )
}

export default CommercialStagesNew