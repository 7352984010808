import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputApp from '../../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import DataTableApp from '../../../Layout/DataTableApp'
import { useMutation, useQuery } from '@apollo/client'
import { AlertApp } from '../../../Global/AlertApp'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows'

// apollo
import { CREATE_COMERCIAL_STAGE, GET_ALL_COMMERCIAL_STAGES } from '../../../../graphql/Catalogs/CommercialStage'
import { CREATE_COMMERCIAL_PROCESS, GET_ONE_COMERCIAL_PROCESSS } from '../../../../graphql/Catalogs/CommercialProcesses'
import { GET_ALL_OPPORTUNITIES_STAGES } from '../../../../graphql/QueryManager'
import ButtonApp from '../../../Layout/ButtonApp'
function CommercialProcesseNew() {
    const { id: _id, show } = useParams()
    const navigate = useNavigate()
    const [waiting, setWaiting] = useState(false)
    const [commercialProcess, setCommercialProcess] = useState([])
    const [listOfStages, setListOfStages] = useState([])
    const [listOfCategorys, setListOfCategorys] = useState([])
    const [listOfTask, setListOfTask] = useState([])
    const [optOfStages, setOptOfStages] = useState([])
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre del proceso comercial es obligatorio'),
    })

    const { handleSubmit, reset, control, getValues } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const { loading, error, data, } = useQuery(GET_ONE_COMERCIAL_PROCESSS, {
        variables: {
            id: _id ? parseInt(_id) : 0
        },
        fetchPolicy: 'cache-and-network'
    })

    // const { loading: loadingStages, error: errorStages, data: dataStages } = useQuery(GET_ALL_COMMERCIAL_STAGES, {        
    //     fetchPolicy: 'cache-and-network'
    // })

    const { loading: loadingB1Stages, error: errorB1Stages, data: dataB1Stages } = useQuery(GET_ALL_OPPORTUNITIES_STAGES, {
        variables: {
            type: 'S'
        }
    })

    const [create] = useMutation(CREATE_COMMERCIAL_PROCESS)
    const [createStage] = useMutation(CREATE_COMERCIAL_STAGE, {
        refetchQueries: [
            {
                query: GET_ONE_COMERCIAL_PROCESSS,
                variables: {
                    id: parseInt(_id)
                }
            }
        ]
    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({
                    type: 'errorToast',
                    message: error,
                })
            }
            reset(data.oneCommercialProcess)
            setCommercialProcess(data.oneCommercialProcess)
           
            if (data.oneCommercialProcess) {
                let listCategorys = []
                data.oneCommercialProcess.stages.map((stage) => {
                    stage.categories.map((category) => {
                        console.log(category)
                        let btnCategory = <ActionButtonsForRows
                            baseUrl={'/settings/sales/commercialprocesstaskcategory'}
                            element={category}
                            editButton
                            showButton
                            deleteButton
                        />
                      return  listCategorys.push({
                            id: category.id,
                            name: category.name,
                            stage: stage.name,
                            baseID: category.baseID,
                            actions: btnCategory,
                        })

                    })
                })
                setListOfCategorys(listCategorys)
            }
        }
    }, [loading, error, data])

    // useEffect(() => {
    //     if (!loadingStages) {
    //         if (errorStages) {
    //             AlertApp({ type: 'errorToast', message: errorStages })
    //         }
    //         let list = []
    //         let listCategorys = []
    //         let listTasks = []
    //         if (dataStages) {


    //             dataStages.allCommercialStage.map((element) => {

    //                 let buttons = <ActionButtonsForRows
    //                     baseUrl={'settings/modules'}
    //                     element={element}
    //                     editButton
    //                     showButton
    //                     deleteButton
    //                 />

    //                 element.categories.map((category) => {
    //                     let btnCategory = <ActionButtonsForRows
    //                         baseUrl={'/settings/sales/commercialprocesstaskcategory'}
    //                         element={element}
    //                         editButton
    //                         showButton
    //                         deleteButton
    //                     />

    //                     listCategorys.push({
    //                         id: category.id,
    //                         name: category.name,
    //                         stage: element.name,
    //                         baseID: category.baseID,
    //                         actions: btnCategory,
    //                     })

    //                     category.tasks.map((task) => {
    //                         let btnTask = <ActionButtonsForRows
    //                             baseUrl={'settings/modules'}
    //                             element={element}
    //                             editButton
    //                             showButton
    //                             deleteButton
    //                         />

    //                         listTasks.push({
    //                             id: task.id,
    //                             name: task.name,
    //                             stepID: task.stepID,
    //                             baseID: task.baseID,
    //                             actions: btnTask,
    //                         })
    //                     })
    //                 })


    //                 return list.push({
    //                     id: element.id,
    //                     name: element.name,
    //                     description: element.description,
    //                     entry: element.entry,
    //                     stepId: element.stepId,
    //                     cloPrcnt: element.cloPrcnt,
    //                     totalTasks: element.totalTasks,
    //                     taskCategories: element.taskCategories,
    //                     actions: buttons,
    //                 })
    //             })

    //         }
    //         setListOfStages(list)
    //         setListOfCategorys(listCategorys)
    //         setListOfTask(listTasks)
    //     }
    // }, [loadingStages, errorStages, dataStages])

    useEffect(() => {
        if (!loadingB1Stages) {
            if (errorB1Stages) {
                AlertApp({ type: 'errorToast', message: errorStages })
            }
            let list = []

            if (dataB1Stages) {
                dataB1Stages.allOpportunitiesStage.map((element) => {

                    return list.push({
                        value: element.num,
                        label: element.descript,
                        key: element,
                    })
                })

            }
            setOptOfStages(list)

        }
    }, [loadingB1Stages, errorB1Stages, dataB1Stages])

    const columnsStages = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },
        {
            name: 'SBO - Nombre',
            selector: row => row.description,
            sortable: false,
        },
        {
            name: 'Número de etapa',
            selector: row => row.stepId,
            sortable: false,
        },
        {
            name: 'SBO - Entry',
            selector: row => row.entry,
            sortable: false,
        },
        {
            name: 'Porcentaje de cierre',
            selector: row => row.cloPrcnt,
            sortable: false,
        },
        {
            name: 'Total de tareas',
            selector: row => row.totalTasks,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]

    const columnsCategory = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },
        {
            name: 'Etapa',
            selector: row => row.stage,
            sortable: false,
        },

        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]

    const columnsTask = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },

        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]

    const handlerAddStage = async () => {

        const stage = optOfStages.find((element) => element.key.num === parseInt(getValues("stageNum")))

        const result = await createStage({
            variables: {
                input: {
                    baseID: parseInt(_id),
                    cloPrcnt: stage.key.cloPrcnt,
                    description: stage.key.descript,
                    entry: stage.key.num,
                    name: stage.key.descript,
                    stepId: stage.key.stepId
                }
            }
        })

        console.log(result)
    }

    const submitHandler = async (data) => {
        try {
            setWaiting(true)
            if (_id) {

                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialprocesses')
            } else {
                const result = await create({
                    variables: {
                        name: data.name,
                    }
                })

                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialprocesses')
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={`${_id ? `Proceso comercial [${_id}]` : 'Crear nuevo proceso comercial'}`}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/sales/commercialprocesses")}
                footer
                content={<>
                    <form>
                        <Row>
                            <Col>
                                <InputApp name={"name"} label={"Nombre de Proceso comercial"} placeholder={'Nombre'} control={control} />
                            </Col>
                        </Row>
                        {_id && (
                            <Row>
                                <Col>
                                    <Card className={'card-primary card-tabs'}>
                                        <CardHeader>
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id={`tabs-one-stages-tab`} data-toggle="pill" href={`#tabs-one-stages`} role="tab" aria-controls={`tabs-one-stages`} aria-selected="true">{`${commercialProcess.stages?.length} Etapas`}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id={`tabs-one-category-tab`} data-toggle="pill" href={`#tabs-one-category`} role="tab" aria-controls={`tabs-one-category`} aria-selected="false">{`${listOfCategorys.length} Categorias`}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id={`tabs-one-task-tab`} data-toggle="pill" href={`#tabs-one-task`} role="tab" aria-controls={`tabs-one-task`} aria-selected="false">{`${listOfTask.length} Tareas`}</a>
                                                </li>
                                            </ul>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id={`tabs-one-stages`} role="tabpanel" aria-labelledby={`tabs-one-stages-tab`}>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"stageNum"} label={"Etapa SBO"} inputType='select' control={control} listOfOptions={optOfStages} />
                                                            <ButtonApp text={"Agregar"} faIcon={"plus"} action={handlerAddStage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <DataTableApp
                                                                columns={columnsStages}
                                                                data={commercialProcess.stages}
                                                                dense
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id={`tabs-one-category`} role="tabpanel" aria-labelledby={`tabs-one-category-tab`}>
                                                    <Row>
                                                        <Col>
                                                            <ButtonApp text={"Agregar"} faIcon={"plus"} action={() => navigate('/settings/sales/commercialprocesstaskcategory/add')} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <DataTableApp
                                                                data={listOfCategorys}
                                                                columns={columnsCategory}
                                                                dense
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id={`tabs-one-task`} role="tabpanel" aria-labelledby={`tabs-one-task-tab`}>
                                                    <Row>
                                                        <Col>
                                                            <DataTableApp
                                                                data={listOfTask}
                                                                columns={columnsTask}
                                                                dense
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}

                    </form>
                </>}
            />
        </DashboardLayout>
    )
}

export default CommercialProcesseNew