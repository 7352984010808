import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Card, CardTitle, CardBody, CardHeader, Progress, Label, Badge, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from 'reactstrap'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import InputApp from '../../Layout/InputApp';
import { AddressesSBOType, CardTypeOptions, CurrencyCodesTypesSBO, DivisionTypeOptions, paymentTermCodesTypesSBO } from '../../../Types';
import { AlertApp } from '../../Global/AlertApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScheduleSalesVisit from './ScheduleSalesVisit';
import moment from 'moment'
import DataTableApp from '../../Layout/DataTableApp';
import ContactCardTab from './ContactCardTab';
import AddressesCardTab from './AddressesCardTab';
import CalendarApp from '../../Layout/CalendarApp';
import ToDoListLayout from '../../Layout/ToDoListLayout';
import { AuthContext } from '../../../AuthContext';

// apollo
import {
    CREATE_OPPORTUNITY
    , GET_OPPORTUNITY
    , UPDATE_OPPORTUNITY
    , CREATE_CONTACT_PERSON
    , UPDATE_CONTACT_PERSON
    , PROGRESS_OPPORTUNITY
    , CREATE_ADDRESSS_CARD
    , UPDATE_ADDRESSS_CARD
} from '../../../graphql/Catalogs/Opportunities';
import { GET_ALL_COMMERCIAL_PROCESSES, } from '../../../graphql/Catalogs/CommercialProcesses';
import { GET_ALL_SALES_PERSONS, GET_ALL_TERRITORYS, GET_BUSINESS_PARTNER, GET_ALL_INTEREST_LEVEL, GET_BUSINESS_PARTNER_CONTACT_PERSONS, GET_ALL_INDUSTRY, GET_ALL_COUNTRIES, GET_ALL_STATES } from '../../../graphql/QueryManager';
import { CREATE_SALES_VISIT, GET_ALL_SALES_VISITS } from '../../../graphql/Catalogs/ScheduleSalesVisit';


function OpportunitiesNew() {
    const { id: _id, show, card: _cardCode, type: _cardType } = useParams()
    const { user: userContext } = useContext(AuthContext)
    const navigate = useNavigate()
    const [opportunity, setOpportunity] = useState([])
    const [optOfCommercialProcesses, setOptOfCommercialProcesses] = useState([])
    const [optOfSalesPersons, setOptOfSalesPersons] = useState([])
    const [optOfTerritorys, setOptOfTerritorys] = useState([])
    const [optOfIndustrys, setOptOfIndustrys] = useState([])
    const [optOfInterestLevel, setOptOfInterestLevel] = useState([])
    const [optOfContactPerson, setOptOfContactPerson] = useState([])
    const [optOfCountries, setOptOfCountries] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [listOfSchedules, setListOfSchedules] = useState([])
    const [listOfEvents, setListOfEvents] = useState([])
    const [menu, setMenu] = useState(false)

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        type: Yup.string().default("S"),
        cardCode: Yup.string().required('El Código de socio de negocio es obligatorio'),
        cardName: Yup.string().required('El Nombre de socio de negocio es obligatorio'),
        cardType: Yup.string().required('El Tipo de socio de negocio es obligatorio'),
        card: Yup.object(),
        baseID: Yup.number(),
        start: Yup.date().required('La fecha de inicio es requerida'),
        slpCode: Yup.number().required('El empleado de ventas es requerido'),
        division: Yup.string().required('Seleccionar una división'),
        territryID: Yup.number().required('El empleado de ventas es requerido'),
        intRate: Yup.number().required('Seleccionar una opcion'),
        cprCode: Yup.number().notRequired().default(-1),
        indCode: Yup.number().notRequired(),
    })
    const { handleSubmit, control, methods, getFieldState, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const { loading, data, error } = useQuery(GET_OPPORTUNITY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })

    const { loading: loadingCard, data: dataCard, error: errorCard } = useQuery(GET_BUSINESS_PARTNER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: _cardCode ? _cardCode : '',
            type: _cardType ? _cardType : ''
        }
    })

    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network',
    })

    const { loading: loadingTerritorys, error: errorTerritorys, data: dataTerritorys } = useQuery(GET_ALL_TERRITORYS, {
        fetchPolicy: 'cache-and-network',
    })

    const { loading: loadingCommercialProcesses, error: errorCommercialProcesses, data: dataCommercialProcesses } = useQuery(GET_ALL_COMMERCIAL_PROCESSES, {
        fetchPolicy: 'cache-and-network',
    })

    const { loading: loadingInterestLevel, error: errorInterestLevel, data: dataInterestLevel } = useQuery(GET_ALL_INTEREST_LEVEL, {
        fetchPolicy: 'cache-and-network',
    })

    const { loading: loadingIndustry, error: errorIndustry, data: dataIndustry } = useQuery(GET_ALL_INDUSTRY, {
        fetchPolicy: 'cache-and-network',
    })

    const { loading: loadingContacPerson, error: errorContacPerson, data: dataContacPerson } = useQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: opportunity.cardCode ? opportunity.cardCode : (_cardCode ? _cardCode : '')
        }
    })

    const { loading: loadingCountries, error: errorCountries, data: dataCountries } = useQuery(GET_ALL_COUNTRIES, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingSchedules, error: errorSchedules, data: dataSchedules } = useQuery(GET_ALL_SALES_VISITS, {
        variables: {
            id: _id ? parseInt(_id) : 0
        },
        fetchPolicy: 'cache-and-network'
    })

    const [getBusinessPartner] = useLazyQuery(GET_BUSINESS_PARTNER)
    const [getContacPersons] = useLazyQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS)
    const [getAllStates] = useLazyQuery(GET_ALL_STATES)

    const [create] = useMutation(CREATE_OPPORTUNITY)
    const [update] = useMutation(UPDATE_OPPORTUNITY)
    const [getProgress] = useMutation(PROGRESS_OPPORTUNITY, {
        refetchQueries: [
            {
                query: GET_OPPORTUNITY,
                variables: {
                    id: parseFloat(_id)
                }
            }
        ]
    })

    const [createContactPerson] = useMutation(CREATE_CONTACT_PERSON)
    const [updateContactPerson] = useMutation(UPDATE_CONTACT_PERSON)
    const [createAddress] = useMutation(CREATE_ADDRESSS_CARD)
    const [updateAddress] = useMutation(UPDATE_ADDRESSS_CARD)
    const [createSalesVisit] = useMutation(CREATE_SALES_VISIT)

    useEffect(() => {
        if (_id) {
            if (!loading) {
                if (error) {
                    AlertApp({ type: 'error', message: error })
                }
                if (data.oneOpportunity) {
                    setOpportunity(data.oneOpportunity)
                    reset(data.oneOpportunity)
                    setValue("start", moment(new Date(data.oneOpportunity.start)).format('YYYY-MM-DD'))
                    setValue("predEnd", moment(new Date(data.oneOpportunity.start)).format('YYYY-MM-DD'))
                }

            }
        } else {
            if (userContext.Rol == 3) {
                setValue("slpCode", parseInt(userContext.SalesPersonCode))
                setValue("cardType", "C")
                setValue("start", moment(new Date()).format('YYYY-MM-DD'))
            }
        }
    }, [loading, data, error])

    useEffect(() => {
        if (!loadingCard) {
            if (errorCard) {
                AlertApp({ type: 'errorTotast', message: errorCard })
            } else {
                if (dataCard.oneBusinessPartner) {
                    setValue("cardCode", dataCard.oneBusinessPartner.cardCode)
                    setValue("cardType", dataCard.oneBusinessPartner.cardType)
                    setValue("cardFName", dataCard.oneBusinessPartner.cardFName)
                    setValue("cardName", dataCard.oneBusinessPartner.cardName)
                    setValue("slpCode", dataCard.oneBusinessPartner.slpCode)
                    setValue("territryID", dataCard.oneBusinessPartner.territory)
                    setValue("indCode", dataCard.oneBusinessPartner.industryC)
                    setValue("card.currency", dataCard.oneBusinessPartner.currency)
                    setValue("card.groupNum", dataCard.oneBusinessPartner.groupNum)
                }
            }
        }
    }, [loadingCard, errorCard, dataCard])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                    })
                })

            }
            setOptOfSalesPersons(list)
            if (userContext.Rol == 3) {
                setValue("slpCode", parseInt(userContext.SalesPersonCode))
            }
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson, userContext])

    useEffect(() => {
        if (!loadingTerritorys) {
            if (errorTerritorys) {
                AlertApp({ type: 'errorToast', message: errorTerritorys })
            }
            let list = []
            if (dataTerritorys) {

                dataTerritorys.allTerritrys.map((element) => {
                    return list.push({
                        value: element.territryID,
                        label: element.descript,
                    })
                })

            }
            setOptOfTerritorys(list)
        }
    }, [loadingTerritorys, errorSalesPerson, dataTerritorys])

    useEffect(() => {
        if (!loadingCommercialProcesses) {
            if (errorCommercialProcesses) {
                AlertApp({ type: 'errorToast', message: errorCommercialProcesses })
            }
            let list = []
            if (dataCommercialProcesses) {

                dataCommercialProcesses.allCommercialProcess.map((element) => {
                    return list.push({
                        value: element.id,
                        label: element.name,
                        // firtsStage: element.stages ? element.stages[0].id : 0
                    })
                })

            }
            setOptOfCommercialProcesses(list)
            setValue('baseID', 1)
        }
    }, [loadingCommercialProcesses, errorCommercialProcesses, dataCommercialProcesses])

    useEffect(() => {
        if (!loadingInterestLevel) {
            if (errorInterestLevel) {
                AlertApp({ type: 'errorToast', message: errorInterestLevel })
            }
            let list = []
            if (dataInterestLevel) {

                dataInterestLevel.allInterestLevel.map((element) => {
                    return list.push({
                        value: element.num,
                        label: element.descript,
                    })
                })

            }
            setOptOfInterestLevel(list)
        }
    }, [loadingInterestLevel, errorInterestLevel, dataInterestLevel])

    useEffect(() => {
        if (!loadingIndustry) {
            if (errorIndustry) {
                AlertApp({ type: 'errorToast', message: errorIndustry })
            }
            let list = []
            if (dataIndustry) {

                dataIndustry.allIndustrys.map((element) => {
                    return list.push({
                        value: element.indCode,
                        label: element.indName,
                    })
                })

            }
            setOptOfIndustrys(list)
        }
    }, [loadingIndustry, errorIndustry, dataIndustry])

    useEffect(() => {
        if (!loadingContacPerson && (opportunity.cardCode || _cardCode)) {
            if (errorContacPerson) {
                AlertApp({ type: 'errorToast', message: errorContacPerson })
            }
            let list = []
            if (dataContacPerson.allContactPersonByCard) {

                dataContacPerson.allContactPersonByCard.map((element) => {
                    return list.push({
                        value: element.cntctCode,
                        label: element.name,
                    })
                })

            }
            setOptOfContactPerson(list)
        }
    }, [loadingContacPerson, errorContacPerson, dataContacPerson, opportunity])

    useEffect(() => {
        if (!loadingCountries) {
            if (errorCountries) {
                AlertApp({ type: 'errorToast', message: errorCountries })
            }
            let list = []
            if (dataCountries.allCountries) {

                dataCountries.allCountries.map((element) => {
                    return list.push({
                        value: element.code,
                        label: element.name,
                    })
                })
            }
            setOptOfCountries(list)
        }
    }, [loadingCountries, errorCountries, dataCountries])

    useEffect(() => {
        if (!loadingSchedules) {
            if (errorSchedules) {
                AlertApp({ type: 'errorToast', message: errorSchedules })
            }
            let list = []
            let events = []
            if (dataSchedules.allSalesVisitByOpportunityID) {

                dataSchedules.allSalesVisitByOpportunityID.map((element) => {
                    if (element.status === "O")
                        events.push({
                            id: element.id,
                            title: element.title,
                            start: element.actionDate,
                            end: '2024-03-22',
                            backgroundColor: '#0073b7', //Blue
                            borderColor: '#0073b7' //Blue
                        })
                    let salesVisit = { ...element }
                    salesVisit.badgeType = 'info'
                    return list.push(salesVisit)
                })
            }
            setListOfSchedules(list)
            setListOfEvents(events)
        }
    }, [loadingSchedules, errorSchedules, dataSchedules])

    const columnsContact = [
        {
            name: '#',
            selector: row => row.cntType,
            sortable: false,
        },
        {
            name: 'Nombre Completo',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: false,
        },
        {
            name: 'Teléfono 1',
            selector: row => row.tel1,
            sortable: false,
        },
        {
            name: 'Teléfono móvil',
            selector: row => row.tel2,
            sortable: false,
        },
        {
            name: 'Correo',
            selector: row => row.e_MailL,
            sortable: false,
        },
    ]
    const columnsAddresses = [
        {
            name: '#',
            selector: row => AddressesSBOType[row.adresType],
            sortable: false,
        },
        {
            name: 'Calle/Numero',
            selector: row => row.street,
            sortable: false,
        },
        {
            name: 'Pais',
            selector: row => row.country,
            sortable: false,
        },
        {
            name: 'Estado',
            selector: row => row.state,
            sortable: false,
        },
        {
            name: 'Ciudad',
            selector: row => row.city,
            sortable: false,
        },
        {
            name: 'Colonia',
            selector: row => row.block,
            sortable: false,
        },
    ]
    const addButtonsTools = () => {
        return (
            <>
                <ButtonGroup>
                    <button className="btn btn-secondary" onClick={() => AlertApp({ type: 'react', content: (<CalendarApp data={listOfEvents} />) })}>
                        <FontAwesomeIcon icon={"calendar"} />{" Agenda"}
                    </button>
                    <button className="btn btn-secondary" onClick={() => AlertApp({ type: 'react', content: (<ScheduleSalesVisit opportunity={opportunity} create={createSalesVisit} />) })}>
                        <FontAwesomeIcon icon={"plus-square"} />{" Agendar visita"}
                    </button>
                    <ButtonGroup>
                        <UncontrolledDropdown>
                            <DropdownToggle caret>
                                Etapa  {opportunity.stage?.name}
                            </DropdownToggle>
                            <DropdownMenu>
                                {opportunity.commercialProcess?.stages?.map((stage) => {
                                    return <DropdownItem key={stage.id} disabled={stage.id == opportunity.stage?.id} onClick={() => AlertApp({ type: 'infoToast', message: 'Hay tareas pendientes en esta etapa' })}>
                                        {stage.name}
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                </ButtonGroup>
            </>
        )
    }
    const onHandlerFindCode = async (event) => {
        event.preventDefault()
        try {

            setWaiting(true)
            if (getFieldState('cardType').invalid) {
                setError('cardType')
            }
            const code = getValues('cardCode')
            const type = getValues('cardType')

            const result = await getBusinessPartner({
                variables: {
                    code,
                    type
                }
            })
            if (result.data.oneBusinessPartner) {

                setValue("cardName", result.data.oneBusinessPartner.cardName)
                setValue("slpCode", result.data.oneBusinessPartner.slpCode)
                setValue("territryID", result.data.oneBusinessPartner.territory)
                setValue("indCode", result.data.oneBusinessPartner.industryC)
                setValue("card.currency", result.data.oneBusinessPartner.currency)
                setValue("card.groupNum", dataCard.oneBusinessPartner.groupNum)
                const contacs = await getContacPersons({
                    variables: { code: result.data.oneBusinessPartner.cardCode }
                })

                let list = []
                if (contacs.data.allContactPersonByCard) {

                    contacs.data.allContactPersonByCard.map((element) => {
                        return list.push({
                            value: element.cntctCode,
                            label: element.name,
                        })
                    })
                }
                setOptOfContactPerson(list)
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    const submitHandler = async (data) => {
        try {
            setWaiting(true)
            if (!_id) {
                const result = await create({
                    variables: {
                        input: {
                            title: data.title,
                            type: data.type,
                            start: data.start,
                            predEnd: data.predEnd,
                            division: data.division,
                            memo: data.memo,
                            baseID: data.baseID,
                            currentStage: 1,
                            cardCode: data.cardCode,
                            cardName: data.cardName,
                            cardType: data.cardType,
                            slpCode: data.slpCode,
                            cprCode: data.cprCode,
                            territryID: data.territryID,
                            intRate: data.intRate,
                            indCode: data.indCode,
                        }
                    }
                })

                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/crm/opportunities')
            } else {

                const result = await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            title: data.title,
                            type: data.type,
                            start: data.start,
                            predEnd: data.predEnd,
                            division: data.division,
                            memo: data.memo,
                            baseID: data.baseID,
                            currentStage: opportunity.currentStage,
                            cardCode: data.cardCode,
                            cardName: data.cardName,
                            cardType: data.cardType,
                            slpCode: data.slpCode,
                            cprCode: data.cprCode,
                            territryID: data.territryID,
                            intRate: data.intRate,
                            indCode: data.indCode,
                            cardInput: {
                                baseID: parseInt(_id),
                                cardCode: data.card.cardCode,
                                cardName: data.card.cardName,
                                cardType: data.card.cardType,
                                cardFName: data.card.cardFName,
                                groupNum: data.card.groupNum,
                                currency: data.card.currency,
                                paymentProcess: data.card.paymentProcess,
                            }
                        }
                    }
                })

                const progress = await getProgress({
                    variables: {
                        id: parseInt(_id)
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })
                // navigate('/settings/users')
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={`${_id ? `Oportunidad ${_id}` : `Crear una nueva oportunidad`}`}
                loading={loading || waiting || loadingSalesPerson || loadingTerritorys || loadingCommercialProcesses || loadingInterestLevel || loadingIndustry || loadingContacPerson || loadingCountries || loadingSchedules}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/crm/opportunities")}
                actionTools={addButtonsTools()}
                footer
                tabs
                content={
                    <FormProvider  {...methods}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <Row>
                                <Col lg={7} sm={12}>
                                    <Card className={"card-secondary"}>
                                        <CardHeader>
                                            <CardTitle>Datos de Oportunidad</CardTitle>
                                            <div className={"card-tools"}>
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id={`tab-opportunity-tab`} data-toggle="pill" href={`#tabs-opportunity`} role="tab" aria-controls={`tabs-opportunity`} aria-selected="true" onClick={() => setMenu(false)}>General</a>
                                                    </li>
                                                    <li className="nav-item" hidden={!_id}>
                                                        <a className="nav-link" id={`tabs-calendar-tab`} data-toggle="pill" href={`#tabs-calendar`} role="tab" aria-controls={`tabs-calendar`} aria-selected="false" onClick={() => setMenu(true)}>Actividades</a>
                                                    </li>
                                                    <li className="nav-item" hidden={!_id}>
                                                        <a className="nav-link" id={`tabs-tasks-tab`} data-toggle="pill" href={`#tabs-tasks`} role="tab" aria-controls={`tabs-tasks`} aria-selected="false" onClick={() => setMenu(true)}>Tareas</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </CardHeader>

                                        <CardBody>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id={`tabs-opportunity`} role="tabpanel" aria-labelledby={`tab-opportunity-tab`}>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <InputApp name={"baseID"} label={"Proceso comercial"} inputType='select' listOfOptions={optOfCommercialProcesses} control={control} isReadOnly={show || _id} />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <InputApp name={"start"} label={"Fecha de inicio"} inputType='date' control={control} isReadOnly={show || _id} />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <InputApp name={"status"} label={"Status"} control={control} defaultValue={"Abierto"} isReadOnly={true} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"title"} label={"Titulo"} control={control} placeholder={'Nombre de la oportunidad'} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"slpCode"} label={"Representate"} control={control} inputType={"select"} isReadOnly={userContext.SalesPersonCode} listOfOptions={optOfSalesPersons} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"intRate"} label={"Nivel de interes"} control={control} inputType={"select"} listOfOptions={optOfInterestLevel} />
                                                        </Col>

                                                        <Col>
                                                            <InputApp name={"predEnd"} label={"Fecha de cierre prevista"} inputType='date' control={control} />
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"memo"} label={"Comentarios"} inputType='textarea' control={control} />
                                                        </Col>
                                                    </Row>
                                                    {_id && (
                                                        <Row>

                                                            <Col>
                                                                <InputApp name={"stage.name"} label={"Etapa"} control={control} isReadOnly={true} />
                                                            </Col>
                                                            <Col>
                                                                <InputApp name={"completedTasks"} label={"Tareas completadas"} control={control} isReadOnly={true} />
                                                            </Col>
                                                            <Col>
                                                                <InputApp name={"totalActivities"} defaultValue={listOfSchedules.length} label={"Actividades"} control={control} isReadOnly={true} />
                                                            </Col>
                                                            <Col>
                                                                <Label>Progreso {`- ${opportunity.stage?.name}`}</Label>
                                                                <Progress value={opportunity.stage?.cloPrcnt} style={{ height: '50px' }} > %{opportunity.stage?.cloPrcnt} </Progress>
                                                            </Col>
                                                        </Row>
                                                    )}

                                                </div>
                                                <div className="tab-pane fade" id={`tabs-calendar`} role="tabpanel" aria-labelledby={`tab-calendar-tab`}>
                                                    <Row>
                                                        <Col lg={3} xs={6}>
                                                            <div className={"small-box bg-info"}>
                                                                <div className={"inner"}>
                                                                    <h3>{listOfEvents.length}</h3>

                                                                    <p>Actividades</p>
                                                                </div>
                                                                <div className={"icon"}>
                                                                    <i className={"ion ion-stats-bars"}></i>
                                                                </div>
                                                                <a href="#" className="small-box-footer">Mas info <i className="fas fa-arrow-circle-right"></i></a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col>
                                                            <ToDoListLayout data={listOfSchedules} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id={`tabs-tasks`} role="tabpanel" aria-labelledby={`tab-tasks-tab`}>
                                                    {!loading && (
                                                        <>                                                        
                                                            {
                                                               
                                                                opportunity.commercialProcess?.stages.find((element) => element.entry === opportunity.currentStage)?.categories.map((element) => {
                                                                    return <>
                                                                        <div className={`card card-${element.tasks.length === opportunity.content.filter((item) => item.task.baseID === element.id && item.complete).length ? 'success' : 'secondary'}`}>
                                                                            <div className={"card-header"}>
                                                                                <h4 className={"card-title w-100"}>
                                                                                    <a className={"d-block w-100 collapse collapsed"} data-toggle={"collapse"} href={`#collapse${element.id}`} aria-expanded={"false"}>
                                                                                        {`${element.name}   ${element.tasks.length} / ${opportunity.content.filter((item) => item.task.baseID === element.id && item.complete).length}`}
                                                                                    </a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id={`collapse${element.id}`} className="todo-list collapse" data-widget="todo-list">
                                                                                {opportunity.content.filter((item) => item.task.baseID === element.id).map((task) => {
                                                                                    return <>
                                                                                        <li key={task.id} className={`${task ? task.complete ? 'done' : '' : ''}`}>
                                                                                            <span className="handle">
                                                                                                <i className="fas fa-ellipsis-v"></i>
                                                                                                <i className="fas fa-ellipsis-v"></i>
                                                                                            </span>
                                                                                            <div className="icheck-primary d-inline ml-2">
                                                                                                <input type="checkbox" defaultChecked={task ? task.complete : false} />

                                                                                            </div>
                                                                                            <span className="text">{task.task.name}</span>

                                                                                            <div className="tools">
                                                                                                <button className='btn btn-sm'> <i className="fas fa-edit"></i> </button>
                                                                                                <i className="fas fa-trash-o"></i>
                                                                                            </div>
                                                                                        </li>
                                                                                    </>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={5} sm={12}>
                                    <Card className={"card-secondary"}>
                                        <CardHeader>
                                            <CardTitle>Datos del Socio</CardTitle>
                                            <div className={"card-tools"}>
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id={`tab-general-tab`} data-toggle="pill" href={`#tabs-general`} role="tab" aria-controls={`tabs-general`} aria-selected="true">General</a>
                                                    </li>
                                                    <li className="nav-item" hidden={!_id}>
                                                        <a className="nav-link" id={`tabs-contacts-tab`} data-toggle="pill" href={`#tabs-contacts`} role="tab" aria-controls={`tabs-contacts`} aria-selected="false" >Contactos</a>
                                                    </li>
                                                    <li className="nav-item" hidden={!_id}>
                                                        <a className="nav-link" id={`tabs-bildToDef-tab`} data-toggle="pill" href={`#tabs-bildToDef`} role="tab" aria-controls={`tabs-bildToDef`} aria-selected="false">Direcciones</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id={`tabs-general`} role="tabpanel" aria-labelledby={`tab-general-tab`}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <InputApp name={"cardType"} label={`Tipo de socio`} inputType='select' listOfOptions={CardTypeOptions} control={control} />
                                                        </Col>
                                                        <Col xs={3}>
                                                            <InputApp name={"cardCode"} label={"Código de negocio"} control={control} placeholder={'Código de socio negocio'} onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }} isLoading={waiting} />
                                                        </Col>
                                                        <Col>
                                                            <InputApp name={"cardName"} label={"Nombre de negocio"} control={control} placeholder={'Razón social'} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"card.cardFName"} label={"Nombre comercial"} control={control} placeholder={'Nombre comercial'} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"cprCode"} label={"Contacto"} inputType='select' listOfOptions={optOfContactPerson} control={control} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"division"} label={"Division"} inputType='select' listOfOptions={DivisionTypeOptions} control={control} />
                                                        </Col>
                                                        <Col>
                                                            <InputApp name={"territryID"} label={"Territorio"} control={control} inputType={"select"} listOfOptions={optOfTerritorys} />
                                                        </Col>
                                                        <Col>
                                                            <InputApp name={"indCode"} label={"Ramo"} control={control} inputType={"select"} listOfOptions={optOfIndustrys} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"card.currency"} label={`Moneda`} inputType='select' listOfOptions={CurrencyCodesTypesSBO} control={control} />
                                                        </Col>
                                                        <Col>
                                                            <InputApp name={"card.groupNum"} label={`Condiciones comerciales`} inputType='select' listOfOptions={paymentTermCodesTypesSBO} control={control} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"card.paymentProcess"} label={`Proceso de pago`} inputType='textarea' placeholder={'Descripción del proceso de pago del cliente'} control={control} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fad" id={`tabs-contacts`} role="tabpanel" aria-labelledby={`tab-contacts-tab`}>

                                                    {opportunity.card && (
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <ButtonGroup>
                                                                        <button type={"button"} className={"btn btn-success"} onClick={() => AlertApp({ type: 'react', content: (<ContactCardTab create={createContactPerson} update={updateContactPerson} progress={getProgress} opportunity={opportunity} />) })}>Agregar</button>
                                                                    </ButtonGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <DataTableApp
                                                                        data={opportunity.card.contactPersons}
                                                                        columns={columnsContact}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>

                                                    )}
                                                </div>
                                                <div className="tab-pane fade" id={`tabs-bildToDef`} role="tabpanel" aria-labelledby={`tab-bildToDef-tab`}>
                                                    {opportunity.card && (
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <ButtonGroup>
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle caret>
                                                                                Agregar
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => AlertApp({ type: 'react', content: (<AddressesCardTab create={createAddress} update={updateAddress} addressType={"B"} allCountries={optOfCountries} progress={getProgress} getStates={getAllStates} opportunity={opportunity} />) })}>
                                                                                    Direccion Fiscal
                                                                                </DropdownItem>
                                                                                <DropdownItem onClick={() => AlertApp({ type: 'react', content: (<AddressesCardTab create={createAddress} update={updateAddress} addressType={"S"} allCountries={optOfCountries} progress={getProgress} getStates={getAllStates} opportunity={opportunity} />) })}>
                                                                                    Direccion Entrega
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>

                                                                    </ButtonGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <DataTableApp
                                                                        data={opportunity.card.addresses}
                                                                        columns={columnsAddresses}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>
                }
            />
        </DashboardLayout>
    )
}

export default OpportunitiesNew