import React from 'react'

const CardInfo = ({ title = "", value = 0, type = "info", actionClick = null }) => {
    return (
        <div className={`small-box bg-${type}`} >
            <div className={"inner"}>
                <h3>{value}</h3>

                <p>{title}</p>
            </div>
            <div className={"icon"}>
                <i className={"ion ion-stats-bars"}></i>
            </div>
            <a href="#" className="small-box-footer" onClick={() => {
                if(actionClick)
                    actionClick()
            }}>Mas info <i className="fas fa-arrow-circle-right"></i></a>
        </div>
    )
}

export default CardInfo