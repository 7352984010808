import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { useQuery } from '@apollo/client'
import { GET_ALL_HAZARDOUS_SUBSTANCE_TYPES } from '../../../graphql/Catalogs/TypesOfHazardousSubstances'
import { AlertApp } from '../../Global/AlertApp'
import DataTableApp from '../../Layout/DataTableApp'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'

const DangerousSubstances = () => {
    const [dataList, setDataList] = useState([])
    const [dataColumns, setDataColumns] = useState([])
    const { loading, data, error } = useQuery(GET_ALL_HAZARDOUS_SUBSTANCE_TYPES)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                let list = []
            
                data.allHazardousSubstanceTypes.map((element) => {
                    let cloneElement = { ...element }


                    let buttons = <ActionButtonsForRows
                        baseUrl={'settings/dangeroussubstances/classification'}
                        element={element}
                        editButton
                        showButton
                        deleteButton
                        deleteAction={() => console.log('delete')}
                    />

                    cloneElement.actions = buttons
                  
                    return list.push(cloneElement)
                })
                setDataList(list)
                               
            }
        }
    }, [loading, data, error])
    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },        
        {
            name: 'SGA',
            selector: row => row.categories.map((element) => `${element.category.name} `)
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    return (
        <DashboardLayout>
            <CardBox
                title={"Sustancias peligrosas"}
                cardTools
                content={<>
                    <DataTableApp
                        data={dataList}
                        columns={columns}
                        dense
                    />
                </>}
            />
        </DashboardLayout>
    )
}

export default DangerousSubstances