import { gql } from "@apollo/client";

export const GET_ONE_SGA = gql`
query oneRiskCategory($id: Int!) {
    oneRiskCategory(id: $id) {
      id
      name
      description
      sga
    }
  }
`
export const GET_ALL_SGA = gql`
query allRiskCategory {
    allRiskCategory {
      id
      name
      description
      sga
    }
  }
`
export const CREATE_SGA = gql`
mutation createRiskCategory($input: RiskCategoryInput!){
    createRiskCategory(input: $input)
}
` 
export const UPDATE_SGA = gql`
mutation updateRiskCategory($id: Int!, $input: RiskCategoryInput!){
    updateRiskCategory(id: $id, input: $input)
  }
` 
export const DELETE_SGA = gql`
mutation deleteRiskCategory($id: Int!){
    deleteRiskCategory(id: $id)
  }
`