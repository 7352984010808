import React from "react"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/es'

export default function CalendarApp({
  data,
  raiseEventItem,
  initialView = null,
  ...props }) {
  return (
    <div className="FullCalendar">
      <FullCalendar
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          listPlugin
        ]}
        initialView={initialView ?? "dayGridMonth"}
        expandRows={true}
        events={data}
        locales={esLocale}
        locale={'es'}
        titleFormat={{ month: 'long', day: 'numeric', }}
        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: true }}
        eventMinHeight={20}
        selectable={true}
        hiddenDays={[6, 7]}
        weekends={false}
        // dateClick={function (info) {
        //   alert('Clicked on: ' + info.dateStr);
        //   alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        //   alert('Current view: ' + info.view.type);

        // }}

        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        }}
        eventContent={raiseEventItem}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
          
        }}
        {...props}
      />
    </div>

  )
}