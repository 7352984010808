import { gql } from "@apollo/client"

export const CREATE_DANGEROUS_GOOD = gql`
mutation createDangerousItem($input: DangerouesItemInput!){
    createDangerousItem(oInput: $input)
  }`

export const UPDATE_DANGEROUS_GOOD = gql`
mutation updateDangerousItem($id: Int!, $input: DangerouesItemInput!) {
  updateDangerousItem(id: $id, oInput: $input)
}`

export const DELETE_DANGEROUES_GOOD = gql`
mutation deleteDangerousItem($id: Int!) {
  deleteDangerousItem(id: $id)
}`

export const GET_ONE_DANGEROUS_GOOD = gql`
query oneDangerouesItem($id: Int!) {
  oneDangerouesItem(id: $id) {
    id
    itemCode
    itemName
    itmsGrpCod
      itemGroup {
      itmsGrpCod
      itmsGrpNam
          }
    codeBars
    invntItem
    ph
    pHmin
    pHmax
    notes
    compatibilityInfo {
      id
      baseID
      refID
      conventionID
      lvlID
    }
    dangerousInfo {
      id
      baseID
      hazardousSubstanceID
      substanceType {
        id
        name
      }
    }
    images
  }
}`
export const GET_ONE_DANGEROUS_GOOD_CODE = gql`
query oneDangerouesItemByCode($id: String!) {
  oneDangerouesItemByCode(code: $id) {
    id
    itemCode
    itemName
    itmsGrpCod
      itemGroup {
      itmsGrpCod
      itmsGrpNam
          }
    codeBars
    invntItem
    ph
    pHmin
    pHmax
    notes
    compatibilityInfo {
      id
      baseID
      refID
      conventionID
      lvlID
    }
    dangerousInfo {
      id
      baseID
      hazardousSubstanceID
      substanceType {
        id
        name
      }
    }
    images
  }
}`
export const GET_ALL_DANGEROUS_GOODS = gql`
query allDangerousItems {
    allDangerouesItems {
      id
      itemCode
      itemName
      itmsGrpCod
       itemGroup {
      itmsGrpCod
      itmsGrpNam
          }
      codeBars
      invntItem
      ph
      pHmin
      pHmax
      notes
      compatibilityInfo {
        id
        baseID
        refID
        conventionID
        lvlID
      }
      dangerousInfo {
        id
        baseID
        hazardousSubstanceID
        substanceType {
          id
          name
        }
      }
      images
    }
  }`