import React from "react";
import { useLocation } from "react-router-dom";

import BodyWrapper from "./BodyWrapper";
import { BreadcrumbItem } from "reactstrap";

export const DashboardLayout = ({ children }) => {
    // const location = useLocation()
    // const BrandPath = () => {
    //     let index = 0
    //     location.pathname.split('/').map((path) => {
    //         if (path !== '') {
    //             index++
    //             return <BreadcrumbItem className="breadcrumb-item active"><a href={path} key={index} >{path}</a></BreadcrumbItem>
    //         }
    //     })
    // }
    return (
        <BodyWrapper>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"></h1>
                        </div>
                        <div className="col-sm-6">
                            {/* <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Inicio</a></li>
                                {
                                    
                                    location.pathname.split('/').map((path) => {
                                        
                                        if (path !== '') {
                                    
                                            return <BreadcrumbItem className="breadcrumb-item active"><a href={path} key={path} >{path}</a></BreadcrumbItem>
                                        }
                                    })
                                }
                            </ol> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    {children}
                    <aside className="control-sidebar control-sidebar-dark">
                        {/* <!-- Control sidebar content goes here --> */}
                        <div className="p-3">
                            <h5>Title</h5>
                            <p>Sidebar content</p>
                        </div>

                    </aside>
                </div>
            </div>
        </BodyWrapper>
    )
}