import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// apollo
import { GET_ALL_ACTIVITIES } from '../../graphql/Catalogs/Activities'
import DataTableApp from '../Layout/DataTableApp'
import ActionButtonsForRows from '../Layout/ActionButtonsForRows'
import { GET_ALL_SALES_PERSONS } from '../../graphql/QueryManager'
import CalendarApp from '../Layout/CalendarApp'
import { GET_CALENDAR } from '../../graphql/Contexts/Calendar'
import { AlertApp } from '../Global/AlertApp'

const SalesPersonHome = () => {
    const navigate = useNavigate()
    // const [dataList, setDataList] = useState([])
    const [dataEvents, setEvents] = useState([])
    const [showAll, setShowAll] = useState(false)
    // const [listOfSalesPerson, setListOfSalesPerson] = useState([])
    // const { loading, data, error } = useQuery(GET_ALL_ACTIVITIES, {
    //     fetchPolicy: 'cache-and-network'
    // })
    const { loading : loadingCalendar, data: dataCalendar, error: errorCalendar } = useQuery(GET_CALENDAR, {
        fetchPolicy: 'cache-and-network'
    })
    // const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
    //     fetchPolicy: 'cache-and-network'
    // })
    // useEffect(() => {
    //     if (!loadingSalesPerson) {
    //         if (errorSalesPerson) {
    //             AlertApp({ type: 'errorToast', message: errorSalesPerson })
    //         }
    //         let list = []
    //         if (dataSalesPerson) {

    //             dataSalesPerson.allSalesPersons.map((element) => {
    //                 return list.push({
    //                     value: element.slpCode,
    //                     label: element.slpName,
    //                     disabled: element.active === "N",
    //                 })
    //             })

    //         }
    //         setListOfSalesPerson(list)
    //     }
    // }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])
    // useEffect(() => {
    //     if (!loading) {
    //         if (error) {
    //             AlertApp({ type: 'toastError', message: error })
    //         }
    //         let list = []

    //         if (data.allActivities) {
    //             data.allActivities
    //                 .map((element) => {
    //                     let cloneElement = { ...element }
    //                     cloneElement.id = element.activityCode

    //                     let buttons = <ActionButtonsForRows
    //                         baseUrl={'crm/activities'}
    //                         element={{ id: element.activityCode }}
    //                         editButton={element.closed == "tNO"}
    //                     />
    //                     cloneElement.actions = buttons
    //                     return list.push(cloneElement)
    //                 })
    //         }
    //         setDataList(list)
    //     }
    // }, [loading, data, error])
    useEffect(() => {
        if (!loadingCalendar) {
            if (errorCalendar) {
                AlertApp({ type: 'errorToast', message: errorCalendar })
            } else {
                setEvents(dataCalendar.calendar)
              
            }
        }
    }, [loadingCalendar, dataCalendar, errorCalendar])

    // const columns = [
    //     {
    //         name: '#',
    //         selector: row => row.activityCode,
    //         sortable: false,
    //         width: '80px',
    //     },
    //     {
    //         name: 'Actividad',
    //         selector: row => `${row.cardName}`,
    //         sortable: false
    //     },
    //     {
    //         name: 'Empleado',
    //         selector: row => `${listOfSalesPerson.find((s) => s.value == row.salesEmployee)?.label}`,
    //         sortable: false,
    //         width: '120px',
    //     },
    //     {
    //         name: 'Fecha',
    //         selector: row => `${moment(row.startDate).format('L')}`,
    //         sortable: false
    //     },
    //     {
    //         name: 'Hora',
    //         selector: row => `${moment(row.startTime).format('LT') === '12:00 AM' ? '' : moment(row.startTime).format('LT')}`,
    //         sortable: false
    //     },
    //     {
    //         name: '',
    //         selector: row => row.actions,
    //         sortable: false
    //     },
    // ]

    return (
        <>
            <Row>
                <Col lg={2} xs={4}>
                    <div className={"small-box bg-info"} >
                        <div className={"inner"}>
                            <h3>{dataEvents.length}</h3>

                            <p>Actividades</p>
                        </div>
                        <div className={"icon"}>
                            <i className={"ion ion-stats-bars"}></i>
                        </div>
                        <a href="crm/activities" className="small-box-footer">Mas info <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {$`Mis actividades ${showAll ? '' : "(Hoy)"}`}
                            </CardTitle>
                            {/* <div className='card-tools'>
                                <button className={`btn btn-${showAll ? '' : 'info'} `} onClick={() => setShowAll(false)} >Hoy</button>
                                <button className={`btn btn-${showAll ? 'info' : ''} `} onClick={() => setShowAll(true)} >Todas</button>
                            </div> */}
                        </CardHeader>
                        <CardBody>
                            {/* <Row>
                                <Col>
                                    <DataTableApp
                                        data={dataList.filter((element) => showAll ? element : moment(element.activityDate).date() == moment(new Date()).date())}
                                        columns={columns}
                                        dense
                                    />
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <CalendarApp
                                    events={dataEvents}
                                    initialView="timeGridWeek"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </>
    )
}

export default SalesPersonHome