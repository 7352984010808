import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonGroup, Spinner } from "reactstrap"

export const ButtonApp = ({
    text,
    buttonType = 'default',
    processing = false,
    faIcon = "",
    additionalClass = '',
    action = () => { },
    actionButtonType = "submit",
    cancelAction = () => { },
    ...props
}) => {

    return (<>
        {buttonType === 'submit' && (
            <>
                <Button
                    disabled={processing}
                    color={"primary"}
                    className={`${additionalClass}`}
                    type="submit"
                    {...props}>
                    {processing ? <><Spinner size={"sm"}> </Spinner><span>{' '}{text}</span></> : (<> <FontAwesomeIcon icon={faIcon} />{' '}{text}</>)}
                </Button>
            </>
        )}
        {buttonType === 'default' && (
            <>
                <Button
                    disabled={processing}
                    onClick={(event) => action(event)}
                    className={`${additionalClass}`}
                    
                    {...props}>{faIcon ? <FontAwesomeIcon icon={faIcon} /> : <></>} {processing ? (<Spinner size={"sm"} />) : (<></>)}  {text}
                </Button>
            </>
        )}
        {buttonType === 'form' && (
            <>
                <Button color="success" disabled={processing}  {...props}><FontAwesomeIcon icon="fa-solid fa-square-plus" />{processing ? (<Spinner size={"sm"} />) : (<></>)}  {text}</Button>
            </>
        )}
        {buttonType === 'formGroup' && (
            <>
                <ButtonGroup>
                    <Button
                        disabled={processing}
                        color="success"
                        type={actionButtonType}
                        className={`btn-md ${additionalClass}`}
                        onClick={(event) => action(event)}
                        {...props}>
                        <FontAwesomeIcon icon="fa-solid fa-circle-down" />  {"Agregar"}
                    </Button>
                    <Button
                        disabled={processing}
                        className={`btn-md ${additionalClass}`}
                        onClick={(event) => cancelAction(event)}
                        {...props}>
                        <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" /> {"Cancelar"}
                    </Button>
                </ButtonGroup>
            </>
        )}
        {buttonType === 'funtion' && (
            <>
                <Button color="primary"
                    disabled={processing}
                    className={`${additionalClass}`}
                    onClick={(event) => action(event)}
                    {...props}><FontAwesomeIcon icon="fa-solid fa-square-plus" />  {text}</Button>
            </>
        )}
        {buttonType === 'cancel' && (
            <>
                <Button
                    disabled={processing}
                    className={`${additionalClass}`}
                    onClick={(event) => cancelAction(event)}
                    {...props}><FontAwesomeIcon icon="fa-solid fa-rectangle-xmark" />  {processing}</Button>
            </>
        )}
    </>)
}
export default ButtonApp 