import { gql } from "@apollo/client";

export const GET_OPPORTUNITY = gql`
  query oneOpportunity($id: Int!) {
    oneOpportunity(id: $id) {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      completedTasks
      completedCurrentTasks
      commercialProcess {
        id
        name
        stages {
          id
          name
          entry
          stepId
          cloPrcnt
          totalTasks
          categories {
            id
            name
            useSysForm
            tasks {
              id
              name
              description
              stepID
              status
            }
          }
        }
      }
      cloPrcnt
      currentStage
      stage {
        id
        name
        cloPrcnt
      }
      cardCode
      cardName
      cardType
      card {
        id
        cardCode
        cardName
        cardType
        cardFName
        bildToDef
        shipToDef
        addresses {
          id
          address
          adresType
          cardCode
          country
          state
          city
          street
          block
          zipCode
        }
        cprCode
        cprPurCode
        cprPymntCode
        cprWhsCode
        cprPrcsCode
        cprUsrCode
        contactPersons {
          id
          cntType
          cntctCode
          firstName
          lastName
          tel1
          tel2
          e_MailL
        }
        groupNum
        paymentTerm {
          id
          pymntGroup
        }
        currency
        paymentProcess
      }
      slpCode
      salesPerson {
        slpCode
        slpName
      }
      cprCode
      contactPerson {
        cntctCode
        name
      }
      territryID
      territory {
        territryID
        descript
      }
      intRate
      interestLevel {
        num
        descript
      }
      indCode
      industry {
        indCode
        indName
        indDesc
      }
      numReason
      reasonForClosure {
        descript
      }
      content {
        id
        lineNum
        baseID
        taskID
        task {
          id
          name
          validationType
          baseID
        }
        complete
      }
    }
  }
`;

export const GET_ALL_OPPORTUNITIES = gql`
  query allOpportunities {
    allOpportunities {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      completedTasks
      origin
      commercialProcess {
        id
        name
        stages {
          id
          name
        }
      }
      cloPrcnt
      currentStage
      stage {
        id
        name
      }
      cardCode
      cardName
      cardType
      slpCode
      salesPerson {
        slpCode
        slpName
      }
      cprCode
      contactPerson {
        cntctCode
        name
      }
      territryID
      territory {
        territryID
        descript
      }
      intRate
      interestLevel {
        num
        descript
      }
      indCode
      industry {
        indCode
        indName
        indDesc
      }
      numReason
      reasonForClosure {
        descript
      }
      content {
        id
        lineNum
        baseID
        taskID
        task {
          id
          name
        }
        complete
      }
      wspOpptItems {
        id
        db
        itemCode
        itemName
        quantity
        itemValue
        lineTotal
        currency
        line
        objType
        docEntry
        docNum
        comments
      }
    }
  }
`;

export const GET_ALL_QUOTATION_OPPORTUNITIES = gql`
  query allQuotationOpportunities($start: DateTime, $end: DateTime) {
    allQuotationOpportunities(startDate: $start, endDate: $end) {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      completedTasks
      origin
      maxSumLoc
      maxSumSys
      currency
      commercialProcess {
        id
        name
        stages {
          id
          name
        }
      }
      cloPrcnt
      currentStage
      stage {
        id
        name
        cloPrcnt
      }
      cardCode
      cardName
      cardType
      slpCode
      salesPerson {
        slpCode
        slpName
      }
      cprCode
      contactPerson {
        cntctCode
        name
      }
      territryID
      territory {
        territryID
        descript
      }
      intRate
      interestLevel {
        num
        descript
      }
      indCode
      industry {
        indCode
        indName
        indDesc
      }
      numReason
      reasonForClosure {
        descript
      }
      wspOpptItems {
        id
        db
        itemCode
        itemName
        quantity
        price
        lineTotal
        currency
        line
        objType
        docEntry
        docNum
        memo
      }
    }
  }
`;

export const CREATE_OPPORTUNITY = gql`
  mutation createOpportunity($input: OpportunityInput!) {
    createOpportunity(input: $input) {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      cloPrcnt
      currentStage
      cardCode
      cardName
      cardType
      slpCode
      cprCode
      territryID
      intRate
      indCode
      numReason
    }
  }
`;

export const CREATE_QUOTATION_OPPORTUNITY = gql`
  mutation createQuotationOpportunity($input: QuotationOpportunityInput!) {
    createQuotationOpportunity(input: $input) {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      cloPrcnt
      currentStage
      cardCode
      cardName
      cardType
      slpCode
      cprCode
      territryID
      intRate
      indCode
      numReason
    }
  }
`;

export const UPDATE_QUOTATION_OPPORTUNITY = gql`
  mutation updateQuotationOpportunity(
    $id: Int!
    $input: QuotationOpportunityUpdateInput!
  ) {
    updateQuotationOpportunity(id: $id, input: $input)
  }
`;

export const UPDATE_OPPORTUNITY = gql`
  mutation updateOpportunity($id: Int!, $input: OpportunityInput!) {
    updateOpportunity(id: $id, input: $input) {
      id
      opprId
      status
      title
      type
      start
      end
      predEnd
      division
      memo
      baseID
      cloPrcnt
      currentStage
      cardCode
      cardName
      cardType
      slpCode
      cprCode
      territryID
      intRate
      indCode
      numReason
    }
  }
`;

export const GET_ALL_OPPORTUNITIES_DEFECT_CAUSE = gql`
  query allOpportunitiesDefectCause {
    allOpportunitiesDefectCause {
      num
      descript
      sortOrder
    }
  }
`;

export const PROGRESS_OPPORTUNITY = gql`
  mutation validateProgressOpportunity($id: Int!) {
    validateProgressOpportunity(id: $id) {
      completedTasks
    }
  }
`;

export const DELETE_OPPORTUNITY = gql`
  mutation deleteOpportunity($id: Int!) {
    deleteOpportunity(id: $id)
  }
`;

export const CREATE_CONTACT_PERSON = gql`
  mutation createContactPerson($input: ContactPersonInput!) {
    createContactPerson(input: $input)
  }
`;

export const UPDATE_CONTACT_PERSON = gql`
  mutation updateContactPerson($id: Int!, $input: ContactPersonInput!) {
    updateContactPerson(id: $id, input: $input)
  }
`;

export const DELETE_CONTACT_PERSON = gql`
  mutation deleteContactPerson($id: Int!) {
    deleteContactPerson(id: $id)
  }
`;

export const CREATE_ADDRESSS_CARD = gql`
  mutation createAddressesCard($input: BusinessPartnerAddressesInput!) {
    createAddressesCard(input: $input)
  }
`;

export const UPDATE_ADDRESSS_CARD = gql`
  mutation updateAddressesCard(
    $id: Int!
    $input: BusinessPartnerAddressesInput!
  ) {
    updateAddressesCard(id: $id, input: $input)
  }
`;

export const DELETE_ADDRESSS_CARD = gql`
  mutation deleteAddressesCard($id: Int!) {
    deleteAddressesCard(id: $id)
  }
`;
