import React, { useReducer } from "react";
import { ApolloProvider } from "@apollo/client";
import cliente from "./apollo";
import { Notifications } from 'react-push-notification';

// import the fortawesome library
import { library } from '@fortawesome/fontawesome-svg-core'
// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import AppRoutes from './components/Routes'
import { AuthContext } from './AuthContext'
import { AuthReducer } from './AuthReducer'
import { CalendarProvaider } from "./CalendarContext"


const App = () => {
    const [user, dispatch] = useReducer(AuthReducer, {})
    library.add(fab, fas, far)
    return (
        <AuthContext.Provider value={{ user, dispatch }}  >
            <ApolloProvider client={cliente}>
                <CalendarProvaider>
                    <Notifications />
                    <AppRoutes />
                </CalendarProvaider>
            </ApolloProvider>
        </AuthContext.Provider>
    )
}

export default App