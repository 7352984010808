import React from "react";
import { DashboardLayout } from "./DashboardLayout";

export const LoadingLayer = () => {
  return (
    <DashboardLayout>

      <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__wobble" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div>

    </DashboardLayout>
  )
}
export default LoadingLayer