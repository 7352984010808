import React, { useEffect, useState } from 'react'
import CalendarApp from '../Layout/CalendarApp'
import { DashboardLayout } from '../Layout/DashboardLayout'
import { useQuery } from '@apollo/client'

//apollo
import { GET_CALENDAR } from '../../graphql/Contexts/Calendar'
import { AlertApp } from '../Global/AlertApp'
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { useCalendarContext } from '../../CalendarContext'

const UserCalendar = () => {
    const { events: _events, dispath } = useCalendarContext()
    const [events, setEvents] = useState([])
    const { loading, data, error } = useQuery(GET_CALENDAR, {
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                setEvents(data.calendar)
                dispath({
                    type: 'save',
                    events: data.calendar
                })
            }
        }
    }, [loading, data, error])
    return (
        <DashboardLayout>
            <Row>
                <Col md={3} lg={3}>
                  
                </Col>
                <Col md={9} lg={9}>
                    <Card>
                        <CardBody>
                            <Spinner hidden={!loading} size={"lg"}  >{''}</Spinner>
                            {!loading && (
                                <CalendarApp
                                    events={events}
                                />
                            )}

                        </CardBody>
                    </Card>

                </Col>
            </Row>

        </DashboardLayout>
    )
}

export default UserCalendar