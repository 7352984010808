import React, { useEffect } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertApp } from '../../Global/AlertApp';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, } from 'reactstrap';
import InputApp from '../../Layout/InputApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactTypes } from '../../../Types';

function ContactCardTab({modal = null, create = null, update = null, progress = null, opportunity = null, contactPerson = null }) {
    const validationSchema = Yup.object().shape({
        cntType: Yup.string().required("Seleccionar un tipo de contacto"),
        firstName: Yup.string().required("El nombre es necesario"),
        lastName: Yup.string().required("El apellido es necesario"),
        tel1: Yup.string().notRequired(),
        tel2: Yup.string().notRequired(),
        address: Yup.string().notRequired(),
        e_MailL: Yup.string().email(),
    })

    const { handleSubmit, control, methods, reset } = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {
        if (contactPerson)
            reset(contactPerson)
    }, [contactPerson])

    const submitHandler = async (data) => {
        try {

            if (contactPerson) {
                const result = await update({
                    variables: {
                        id: contactPerson.id,
                        input: {
                            cntType: cntType,
                            cardCode: opportunity.cardCode,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            tel1: data.tel1,
                            tel2: data.tel2,
                            e_MailL: data.e_MailL,
                            opportunityID: opportunity.id,
                        }
                    }
                })

                const resultProgress = await progress({
                    variables: {
                        id: opportunity.id,
                    }
                })
                AlertApp({type: 'okToast', message: 'Operacion finalizada con exito' })
            } else {
                const result = create({
                    variables: {
                        input: {
                            cntType: data.cntType,
                            cardCode: opportunity.cardCode,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            tel1: data.tel1,
                            tel2: data.tel2,
                            e_MailL: data.e_MailL,
                            opportunityID: opportunity.id,
                        }
                    }
                })

                await progress({
                    variables: {
                        id: opportunity.id,
                    }
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                modal.clickConfirm()
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {

        }
    }
    return (
        <FormProvider   {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Card className='card-info'>
                    <CardHeader>
                        <CardTitle>Nuevo contacto </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <InputApp name={"cntType"} label={`Tipo de contacto`} inputType='select' listOfOptions={ContactTypes} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={`firstName`} label={"Nombre"} control={control} />
                            </Col>
                            <Col>
                                <InputApp name={`lastName`} label={"Apellido"} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={`e_MailL`} label={"Correo electrónico"} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={`tel1`} label={"Teléfono 1"} control={control} />
                            </Col>
                            <Col>
                                <InputApp name={`tel2`} label={"Teléfono móvil"} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={`address`} label={"Dirección"} control={control} />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="control-btn-box">
                            <button
                                type="submit"
                                onClick={() => { }}
                                className={`btn btn-primary`}
                            >
                                <FontAwesomeIcon icon={"plus"} /> Guardar
                            </button>
                            <button
                                type="button"
                                onClick={() => { modal.close() }}
                                className="btn btn-secondary"
                            >
                                <FontAwesomeIcon icon={"times"} /> Cancelar
                            </button>
                        </div>
                    </CardFooter>
                </Card>
            </form>
        </FormProvider>
    )
}

export default ContactCardTab