import { gql } from "@apollo/client";

export const GET_BUSINESS_CATEGORY_TASK = gql`
query oneBusinessTaskCategory($id: Int!) {
  oneBusinessTaskCategory(id: $id) {
    id
    name
    baseID
    commercialStage {
      id
      description
    }
    useSysForm
    taskCount
    tasks {
      id
      name
    }
  }
}` 

export const GET_ALL_BUSINESS_CATEGORIES_TASK = gql`
query allBusinessTaskCategories {
    allBusinessTaskCategories {
      id
      name
      baseID
      commercialStage {
        id
        description
        activityID
      }
      useSysForm
      taskCount
      tasks {
        id
        name
      }
    }
  }`

export const CREATE_BUSINESS_CATEGORIE_TASK  = gql`
mutation createBusinessTaskCategory($baseID: Int!, $name: String!) {
    createBusinessTaskCategory(baseID: $baseID, name: $name) {
      id
      name
      baseID
      commercialStage {
        id
        name
      }
      useSysForm
      taskCount
      tasks {
        id
        name
      }
    }
  }`