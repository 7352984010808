import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';


import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import InputApp from '../../Layout/InputApp';


// apollo
import { CREATE_ROLE, GET_ROLE, UPDATE_ROLE } from '../../../graphql/Catalogs/Roles';


const Role = () => {
    const [role, setRole] = useState([])
    const [loading, setLoading] = useState(false)
    const { id: _id, show } = useParams()
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre del modulo es requerido'),

    })

    const { handleSubmit, reset, control } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const { loading: loadingRole, error: errorRole, data: dataRole } = useQuery(GET_ROLE, {
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })
    const [create] = useMutation(CREATE_ROLE)
    const [update] = useMutation(UPDATE_ROLE)

    useEffect(() => {
        if (_id) {
            if (!loadingRole) {
                if (errorRole) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: errorRole,
                    })
                }
                if(dataRole){
                    setRole(dataRole.oneRole)
                    reset(dataRole.oneRole)
                }
               
               
            }
        }
    }, [loadingRole, errorRole, dataRole])

    const submitHandler = async (data) => {
        try {
            setLoading(true)
            if (_id) {
                await update({
                    variables: {
                        id: parseInt(_id),
                        input: data.name,
                    }
                })

                Swal.fire('Operacion finalizada con exito')
                navigate('/settings/roles')
            } else {
                await create({
                    variables: {
                        input: data.name,
                    }
                })
                Swal.fire('Operacion finalizada con exito')
                navigate('/settings/roles')
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        } finally {
            setLoading(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                footer
                title={"Rol"}
                loading={loading}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/roles")}
                content={
                    <>
                        <form>
                            <Row>
                                <Col>
                                    <InputApp name={"name"} label={"Nombre"} control={control} tabIndex={1} isReadOnly={show} />
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            />
        </DashboardLayout>
    );
};

export default Role;