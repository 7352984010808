import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';


import InputApp from '../../Layout/InputApp';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PERMISSION, GET_PERMISSION, UPDATE_PERMISSION } from '../../../graphql/Catalogs/Permissions';
import Swal from 'sweetalert2';


const Permission = () => {
    const [permission, setPermission] = useState(false)
    const [loading, setLoading] = useState(false)
    const { id: _id, show } = useParams()
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre del modulo es requerido'),
    })

    const { handleSubmit, reset, control, } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const { loading: loadingPermission, error: errorPermission, data: dataPermission } = useQuery(GET_PERMISSION, {
        fetchPolicy: 'network-only',
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })

    const [create] = useMutation(CREATE_PERMISSION)
    const [update] = useMutation(UPDATE_PERMISSION)

    useEffect(() => {
        if (_id) {
            if (!loadingPermission) {
                if (errorPermission) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: errorRole,
                    })
                }
                if (dataPermission) {
                    setPermission(dataPermission.oneRole)
                    reset(dataPermission.oneRole)
                }


            }
        }
    }, [loadingPermission, errorPermission, dataPermission])

    const submitHandler = async (data) => {
        try {
            setLoading(true)
            if (_id) {
                await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            name: data.name
                        },
                    }
                })

                Swal.fire('Operacion finalizada con exito')
                navigate('/settings/permissions')
            } else {
                await create({
                    variables: {
                        input: {
                            name: data.name
                        },
                    }
                })
                Swal.fire('Operacion finalizada con exito')
                navigate('/settings/permissions')
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        } finally {
            setLoading(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Permiso"}
                loading={loading}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/permissions")}
                footer
                content={<>
                    <form>
                        <Row>
                            <Col>
                                <InputApp name={"name"} label={"Nombre"} control={control} />
                            </Col>
                        </Row>
                    </form>
                </>}
            />
        </DashboardLayout>
    );
};

export default Permission;