import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardHeader, CardBody, CardTitle, Col, Row, UncontrolledTooltip, Spinner } from 'reactstrap'
import DataTableApp from '../../Layout/DataTableApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
import { AlertApp } from '../../Global/AlertApp'
import { DownloadingFiles } from '../../../Utilities'
import CompleteCertificate from './QualityCertificates/CompleteCertificate';

// apollo
import { GET_ALL_BATCH_INFORMATION_ORDER, AUTH_BATCH_INFORMATION_ORDER } from '../../../graphql/Catalogs/MerchandiseReception/CommoditiesReceipt'
import ButtonApp from '../../Layout/ButtonApp';
import InputApp from '../../Layout/InputApp';
import { useForm } from 'react-hook-form';

const QualityCertificates = () => {
    const [dataList, setDataList] = useState([])
    const [filterList, setFilterList] = useState([])
    const [fillterState, setFillterSate] = useState("0")
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [batchInfo, setBatchInfo] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        skip: 0,
        take: 15,
    })
    const [pageInfo, setPageInfo] = useState({
        hasNextPage: false,
        hasPreviousPage: false,
        totalCount: 0,
        tasksCount: 0,
    })
    const { control } = useForm()

    const { loading, data, error, refetch } = useQuery(GET_ALL_BATCH_INFORMATION_ORDER, {
        fetchPolicy: 'cache-and-network'
    })
    const [onAuthBatchInfo] = useMutation(AUTH_BATCH_INFORMATION_ORDER)
    const toggle = () => {
        setModalIsOpen(!modalIsOpen)
    }


    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            }
            setDataList(data.allBatchNumberInformation)
            setFilterList(data.allBatchNumberInformation)
            setPageInfo({
                hasNextPage: false,
                hasPreviousPage: false,
                totalCount: data.allBatchNumberInformation.length,
                tasksCount: 0,
            })
            setBatchInfo(data.allBatchNumberInformation[87])
        }
    }, [loading, data, error])
    useEffect(() => {
        const list = dataList.filter((s) => s.status === fillterState)
        setFilterList(list)
        setPageInfo({
            hasNextPage: false,
            hasPreviousPage: false,
            totalCount: list.length,
            tasksCount: 0,
        })
    }, [fillterState, dataList])
    const columns = [
        {
            name: 'Lote / Num Serie',
            selector: row => row.distNumber,
            sortable: true,
        },
        {
            name: 'Solicitante',
            selector: row => row.user?.fullName,
            sortable: true,
        },
        {
            name: 'SKU',
            selector: row => `${row.itemCode} - ${row.itemName}`,
            sortable: true,
        },
        {
            name: 'Fecha de certificado',
            selector: row => moment(row.certDate).format('YYYY-MM-DD HH:mm'),
            sortable: true,
        },
        {
            name: 'Hora de certificado',
            selector: row => row.status === '0' ? moment(row.certDate).locale('es-mx').fromNow() : `Completado`,
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: 'COA',
            selector: row => <>
                <button
                    id={`fileCoa${row.id}`}
                    key={`fileCoa${row.id}`}
                    onClick={() => DownloadingFiles("CoaAtt", row.id, row.attached1, row.attached1Ext)}
                    className='btn btn-success btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /></button>
                <UncontrolledTooltip target={`fileCoa${row.id}`}>Documentacion proveedor</UncontrolledTooltip>
                <button
                    id={`request${row.id}`}
                    key={`request${row.id}`}
                    onClick={() => DownloadingFiles("QuaAtt", row.id, row.attached2, row.attached2Ext)}
                    className='btn btn-primary btn-sm'
                ><FontAwesomeIcon icon={`fa-solid fa-file-arrow-down`} /></button>
                <UncontrolledTooltip target={`request${row.id}`}>Descargar Solicitud de certificado</UncontrolledTooltip>

                {row.status === "-1" && (
                    <>
                        <button
                            id={`decline${row.id}`}
                            key={`decline${row.id}`}
                            onClick={() => {
                                toggle()
                                setBatchInfo(row)
                            }}
                            className='btn btn-danger btn-sm'
                        ><FontAwesomeIcon icon={`fa-solid fa-file-circle-xmark`} /></button>
                        <UncontrolledTooltip target={`decline${row.id}`}>Certificado rechazado</UncontrolledTooltip>
                    </>
                )}
                <button
                    id={`validate${row.id}`}
                    key={`validate${row.id}`}
                    hidden={row.status === "-1"}
                    onClick={() => {
                        toggle()
                        setBatchInfo(row)
                    }}
                    className={`btn btn-${row.status === "1" ? "success" : "secondary"} btn-sm`}
                ><FontAwesomeIcon icon={`fa-solid ${row.status === "1" ? "fa-file-circle-check" : "fa-file-circle-question"}`} /></button>
                <UncontrolledTooltip target={`validate${row.id}`}>{`${row.status === "1" ? "Certificado Valido" : "Completar certificado"}`}</UncontrolledTooltip>
            </>,
            sortable: false,
        },

    ]
    return (
        <DashboardLayout>
            <Row>
                <Col lg={2} md={2}>
                    <ButtonApp text={`Actualizar`} additionalClass={"btn-block mb-3"} color={"primary"} onClick={() => refetch()} />
                    <Card>
                        <CardHeader>
                            <CardTitle>Certificados</CardTitle>
                            <div className={"card-tool"}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody className={"p-0"}>
                            <ul className={"nav nav-pills flex-column"}>
                                <li className="nav-item active">
                                    <a href="#" className="nav-link" onClick={() => setFillterSate("0")}>
                                        <FontAwesomeIcon icon={"fa-solid fa-file-circle-question"} />{' Pendientes'}
                                        <span className="badge bg-primary float-right">{dataList.filter((s) => s.status === "0").length}</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link" onClick={() => setFillterSate("1")}>
                                        <FontAwesomeIcon icon={"fa-solid fa-file-circle-check"} />{' Aprobado'}
                                        <span className="badge bg-success float-right">{dataList.filter((s) => s.status === "1").length}</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link" onClick={() => setFillterSate("-1")}>
                                        <FontAwesomeIcon icon={"fa-solid fa-square-phone"} /> {'Rechazados'}
                                        <span className="badge bg-danger float-right">{dataList.filter((s) => s.status === "-1").length}</span>
                                    </a>
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={10} md={10} hidden>
                    <Card className={"card card-primary card-outline"}>
                        <CardHeader>
                            <CardTitle>Solicitudes de análisis</CardTitle>
                            <div className="card-tools">
                                <InputApp name={"filter"} inputType={"textButton"} placeholder="Buscar Certificado" control={control} />
                            </div>
                        </CardHeader>
                        <CardBody className={"p-0"}>
                            <div className="mailbox-controls">
                                <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square"></i>
                                </button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div>
                                <button type="button" className="btn btn-default btn-sm" onClick={() => { }}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>

                                {/* .float-right  */}
                                <div className="float-right">
                                    {`${pagination.page}-${Math.ceil(pageInfo.totalCount / pagination.take)}/${pageInfo.totalCount} `}
                                    <div className="btn-group">
                                        <button type="button" disabled={!pageInfo.hasPreviousPage} onClick={() => setPagination({ page: pagination.page - 1, skip: pagination.skip - 15, take: pagination.take })} className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" disabled={!pageInfo.hasNextPage} onClick={() => setPagination({ page: pagination.page + 1, skip: pagination.skip + 15, take: pagination.take })} className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive mailbox-messages">
                                {loading ? (<Spinner >{' '}</Spinner>) : (
                                    <table className={"table table-hover table-striped"}>
                                        <tbody>
                                            {filterList.slice(pagination.skip, pagination.take).map((element) => {
                                                return <tr key={element.id}>

                                                    <td><a href="#"><FontAwesomeIcon icon={element.activityStateIcon} className={`text-${element.activityState}`} /></a></td>
                                                    <td className="mailbox-name"><a href="read-mail.html">{element.user?.fullName}</a></td>
                                                    <td className="mailbox-subject"><b>{`${element.document?.cardCode} ${element.document?.cardName}`}</b> {`- Orden Compra: ${element.document?.docNum}`} </td>
                                                    <td className="mailbox-message">{`SKU: ${element.itemCode} - ${element.itemName} Lote: ${element.distNumber}`} </td>
                                                    <td className="mailbox-attachment">
                                                        {
                                                            <>
                                                                <button
                                                                    id={`fileCoa${element.id}`}
                                                                    key={`fileCoa${element.id}`}
                                                                    onClick={() => DownloadingFiles("CoaAtt", element.id, element.attached1, element.attached1Ext)}
                                                                    className='btn btn-success btn-sm'
                                                                ><FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /></button>
                                                                <UncontrolledTooltip target={`fileCoa${element.id}`}>Documentacion proveedor</UncontrolledTooltip>
                                                                <button
                                                                    id={`request${element.id}`}
                                                                    key={`request${element.id}`}
                                                                    onClick={() => DownloadingFiles("QuaAtt", element.id, element.attached2, element.attached2Ext)}
                                                                    className='btn btn-primary btn-sm'
                                                                ><FontAwesomeIcon icon={`fa-solid fa-file-arrow-down`} /></button>
                                                                <UncontrolledTooltip target={`request${element.id}`}>Descargar Solicitud de certificado</UncontrolledTooltip>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="mailbox-date">{moment(element.cerDate).format('YYYY-MM-DD')}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                )}

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={10} md={10} hidden>
                    <Card className={"card card-primary card-outline"}>
                        <CardHeader>
                            <CardTitle>Solicitudes de análisis</CardTitle>
                            <div className="card-tools">
                                <a href="#" className="btn btn-tool" title="Previous"><i className="fas fa-chevron-left"></i></a>
                                <a href="#" className="btn btn-tool" title="Next"><i className="fas fa-chevron-right"></i></a>
                            </div>
                        </CardHeader>
                        <CardBody className={"p-0"}>
                            <div class="mailbox-read-info">
                                <h5>{`Solicitud de certificado : ${batchInfo.document?.cardCode} ${batchInfo.document?.cardName}`}</h5>
                                <h6>{`From: ${batchInfo.user?.fullName}`}
                                    <span className="mailbox-read-time float-right">{`${moment(batchInfo.cerDate).format('YYYY-MM-DD hh:mm')}`}</span></h6>
                            </div>
                            <div className="mailbox-controls with-border text-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm" data-container="body" title="Delete">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm" data-container="body" title="Reply">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm" data-container="body" title="Forward">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div>

                                <button type="button" className="btn btn-default btn-sm" title="Print">
                                    <i className="fas fa-print"></i>
                                </button>
                            </div>
                            <div class="mailbox-read-message">
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={10} md={10}>
                    <Card className={"card-primary card-outline"}>
                        <CardHeader>
                            <CardTitle>Solicitudes de análisis</CardTitle>
                            
                        </CardHeader>
                        <CardBody>
                            <DataTableApp
                                data={filterList}
                                columns={columns}
                                striped
                                dense
                                selectable
                                filter
                                progressPending={loading}
                            />
                        </CardBody>
                    </Card>

                </Col>
            </Row>

            <CompleteCertificate _certificate={batchInfo} _setDialog={setModalIsOpen} _dialog={modalIsOpen} _progress={loading} _onAuth={onAuthBatchInfo} _refetch={refetch} />
        </DashboardLayout>
    )
}

export default QualityCertificates