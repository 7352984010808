import { gql } from "@apollo/client";

export const FIND_BO_ITEM = gql`
  query findBoItem($find: String!) {
    findBoItem(sFindText: $find) {
      itemCode
      itemName
      itmsGrpCod
      codeBars
      purPackMsr
      purPackUn
      salPackMsr
      salPackUn
      indirctTax
      taxCodeAP
      taxCodeAR
      cardCode
      prchseItem
      sellItem
      invntItem
      uDivision
      itemGroup {
        itmsGrpCod
        itmsGrpNam
      }
    }
  }
`;
export const ONE_ITEM = gql`
  query oneItem($code: String!) {
    oneItem(code: $code) {
      itemCode
      itemName
      itmsGrpCod
      codeBars
      purPackMsr
      purPackUn
      salPackMsr
      salPackUn
      indirctTax
      taxCodeAP
      taxCodeAR
      cardCode
      prchseItem
      sellItem
      invntItem
      uDivision
      itemGroup {
        itmsGrpCod
        itmsGrpNam
      }
    }
  }
`;
