import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import DataTable from 'react-data-table-component'
import { AlertApp } from '../../../Global/AlertApp'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows'
import { useQuery } from '@apollo/client'


import { GET_ALL_BUSINESS_CATEGORIES_TASK } from '../../../../graphql/Catalogs/CommercialProcesses/BusinessTaskCategories'
export const CommercialProcessTaskCategoryList = () => {
    const [list, setList] = useState([])

    const { loading, error, data } = useQuery(GET_ALL_BUSINESS_CATEGORIES_TASK)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({
                    type: 'errorToast',
                    message: error,
                })
            }

            let list = []
            data.allBusinessTaskCategories.map((element) => {

                let buttons = <ActionButtonsForRows
                    baseUrl={'settings/sales/commercialprocesstaskcategory'}
                    element={element}
                    editButton
                    showButton
                    deleteButton
                />

                return list.push({
                    id: element.id,
                    name: element.name,
                    stage: element.commercialStage.description,
                    actions: buttons
                })
            })

            setList(list)
        }
    }, [loading, error, data])

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: false,
        },
        {
            name: 'Etapa',
            selector: row => row.stage,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    return (
        <DashboardLayout>
            <CardBox
                title={"Procesos comerciales - categorias"}
                cardTools
                content={<>
                    <DataTable
                        data={list}
                        columns={columns}
                        pagination
                    />
                </>}
            />
        </DashboardLayout>
    )
}
