import { gql } from "@apollo/client";

export const GET_ALL_ROLES = gql`
query allRoles {
    allRoles {
      id
      name
    }
  }
`;

export const GET_ROLE = gql`
query oneRole($id: Int!) {
    oneRole(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_ROLE = gql`
mutation createRole($input: String!) {
    createRole(name: $input) {
      id
      name
    }
  }
`;

export const UPDATE_ROLE = gql`
mutation updateRole($id: Int!, $name: String!){
    updateRole(id: $id, name: $name){
       id
        name
    }
  }
`;

export const DELETE_ROLE = gql`
mutation deleteRole($id: Int!) {
    deleteRole(id: $id)
  }
`;