import { gql } from "@apollo/client";

export const GET_ALL_PERMISSIONS = gql`
query allPermissions {
    allPermissions {
      id
      name
    }
  }
`;

export const GET_PERMISSION = gql`
query onePermission($id: Int!) {
    onePermission(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_PERMISSION = gql`
mutation createPermission($input: PermissionInput!) {
    createPermission(input: $input) {      
      name
    }
  }
`;

export const UPDATE_PERMISSION = gql`
mutation updatePermission($id: Int!, $input: PermissionInput!) {
    updatePermission(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_PERMISSION = gql`
mutation deletePermission($id: Int!){
    deletePermission(id: $id)
  }
`;