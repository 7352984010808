import { gql } from "@apollo/client";

export const GET_ALL_COMMERCIAL_PROCESSES = gql`
query allCommercialProcess {
  allCommercialProcess {
    id
    name
    stages {
      id
      name
    }
  }
}
`;

export const GET_ONE_COMERCIAL_PROCESSS = gql`
query oneCommercialProcess($id: Int!) {
  oneCommercialProcess(id: $id) {
    id
    name
    stages {
      id
      name
      description
      entry
      stepId
      cloPrcnt
      baseID
      cloPrcnt
      totalTasks
      categories {
        id
        name
        baseID
        useSysForm
        taskCount
        tasks {
          id
          name
          description
          baseID
          stepID
          status
          hasValidations
          validationType
          tableName
          fieldID
        }
      }
    }
  }
}
`

export const GET_ALL_TASK_COMPLETE_BY_COMMERIAL_PROCESS = gql`
query allBusinessProcessTasks($id: Int!) {
  allBusinessProcessTasks(baseID: $id) {
    id
    action
    cntctType
    category {
      id
      name
    }
    cntctSbjct
    stageTask {
      id
      name
    }
    title
    details
    opportunityID
    notes
    userID
  }
}
`

export const CREATE_COMMERCIAL_PROCESS = gql`
mutation createCommercialProcess($name: String!) {
  createCommercialProcess(name: $name) {
    id
    name
  }
}
`