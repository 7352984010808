import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation } from "react-router-dom"

const NavItemSidebar = ({ label, path = "#", icon, menu = false }) => {
    const location = useLocation()
    
    return (
        <>           
            {menu && (
                <a href={"#"} className={`nav-link${location.pathname.indexOf(path) !== -1 ? ' active menu-open' : ''}`}>
                    <i className={`nav-icon ${icon}`}/>                  
                    <p>
                        {' '} {label}
                        {menu && (
                        <i className="fas fa-angle-left right"/>
                    )}
                    </p>
                   
                </a>
            )}
            {!menu && (
                <li className="nav-item">
                    <a href={path} className={`nav-link${location.pathname.replace("/add","") == path ? ' active' : ''}`}>
                    <FontAwesomeIcon icon={icon} className={`nav-icon`}/>                   
                        <p>
                            {' '} {label}
                        </p>                       
                    </a>
                </li>
            )}
        </>

    )
};

export default NavItemSidebar;