import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import DataTableApp from '../../../Layout/DataTableApp'
import { useQuery } from '@apollo/client'
import { AlertApp } from '../../../Global/AlertApp'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows';

// apollo
import { GET_ALL_BUSINESS_STAGES, } from '../../../../graphql/Catalogs/CommercialStage'
import { DocStatesSBO } from '../../../../Types'

function CommercialSubStages() {
  const [list, setList] = useState([])

  const { loading, error, data } = useQuery(GET_ALL_BUSINESS_STAGES)

  useEffect(() => {
    if (!loading) {
      if (error) {
        AlertApp({
          type: 'errorToast',
          message: error,
        })
      }

      let list = []
      data.allBusinessStageTask.map((element) => {

        let buttons = <ActionButtonsForRows
          baseUrl={'settings/sales/commercialsubstages'}
          element={element}
          editButton
          showButton
          deleteButton
        />

        return list.push({
          id: element.id,
          name: element.name,
          description: element.description,
          baseID: element.baseID,
          status: element.status,
          hasValidations: element.hasValidations,
          category: element.category,
          actions: buttons
        })
      })

      setList(list)
    }
  }, [loading, error, data])

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      sortable: false,
      width: '80px',
    },
    {
      name: 'Etapa',
      selector: row => row.category.commercialStage.name,
      sortable: false,
    },
    {
      name: 'Categoria',
      selector: row => row.category.name,
      sortable: false,
    },
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: false,
    },   
    {
      name: 'Status',
      selector: row => DocStatesSBO[row.status],
      sortable: false,
    },
    {
      name: '',
      selector: row => row.actions,
      sortable: false,
    },
  ]

  return (
    <DashboardLayout>
      <CardBox
        title={"Sub etapas comerciales"}
        cardTools
        content={
          <DataTableApp
            data={list}
            columns={columns}
            filter
          />
        }
      />
    </DashboardLayout>
  )
}

export default CommercialSubStages