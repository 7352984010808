import { gql } from "@apollo/client"

export const GET_ONE_HAZARDOUS_SUBSTANCE_TYPE = gql`
query oneHazardousSubstanceType($id: Int!) {
  oneHazardousSubstanceType(id: $id) {
    id
    name
    categories {
      id
      category {
        id
        name
        description
        sga
      }
    }
    classes {
      id
      class {
        id
        name
        description
        adr
      }
    }
    compatibility {
      id
      baseID
      left {
        id
        name
        categories {
          category {
            sga
          }
        }
      }
      refID
      right {
        id
        name
        categories {
          category {
            sga
          }
        }
      }
      conventionID
      convention {
        id
        description
      }
      conventionlvl
      lvl {
        id
        description
      }
    }
  }
}`
export const GET_ALL_HAZARDOUS_SUBSTANCE_TYPES = gql`
query allHazardousSubstanceTypes {
    allHazardousSubstanceTypes {
        id
        name
        categories {
          id
          category {
            id
            name
            description
            sga
          }
        }
        classes {
          id
          class {
            id
            name
            description
            adr
          }
        }
    }
  }`

export const GET_ALL_HAZARDOUS_SUBSTANCE_COMPATIBILITIES = gql`
query allCompatibilitySubstance($baseID: Int!, $conventions: Int!) {
  allCompatibilitySubstance(baseID: $baseID, conventions: $conventions) {
    id
    baseID
    right {
      id
      name
    }
    refID
    left {
      id
      name
    }
    conventionID
    convention {
      description
    }
    conventionlvl
    lvl {
      description
    }
  }
}`

export const UPDATE_COMPATIBILITI_SUSTANCE_TYPE = gql`
mutation updateCompatibilitiSustanceType($id: Int!, $convention: Int!, $lvl: Int!) {
  updateCompatibilitiSubstanceType(id: $id, convention: $convention, lvl: $lvl) 
}`
export const CREATE_HAZARDOUS_SUBSTANCE_TYPE = gql`
mutation createHazardousSubstanceType($input: HazardousSubstanceTypeInput!){
    createHazardousSubstanceType(input: $input)
  }
`