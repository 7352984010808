import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavItemSidebar from './NavItemSidebar'


export default function NavMenuSideBar({ path, label, icon, content }) {
    const [open, setOpen] = useState(false)
    const location = useLocation()

    return (
        <li className={`nav-item dropdown${open ? ' menu-open' : ''}${location.pathname.indexOf(path) !== -1 ? ' active menu-open': ''}`} 
        onClick={() => {  
           
            setOpen(true)
            }}>
            <NavItemSidebar path={path} icon={icon} label={label} menu={true} />
            <ul className="nav nav-treeview">
                {content}
            </ul>
        </li>
    )
}
