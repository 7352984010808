import { gql } from "@apollo/client";

export const CREATE_SALES_VISIT = gql`
mutation createSalesVisit($input: SalesVisitInput!) {
    createSalesVisit(input: $input) {
      id
      notes
      userID
      action
      title
      cntctType
      category {
        id
        name
      }
      cntctSbjct
      stageTask {
        id
        name
      }
      status
      details
    }
  }`

  export const GET_SALES_VISIT = gql`
  query oneSalesVisit($id: Int!) {
    oneSalesVisit(id: $id) {
      id
      action
      title
      cntctType
      cntctSbjct
      status
      details
      opportunityID
      notes
      userID
      actionDate
      actionTime
      closeDate
      slpCode
      tasks {
        id
        baseID
        opportunityRowID
        detail {
          id
          lineNum
          baseID
          answer
           complete
          taskID
          task {
            id
            name
            validationType
            category {
              name
            }
          }
        }
      }
    }
  }
  `

  export const GET_ALL_SALES_VISITS = gql`
  query allSalesVisitByOpportunityID($id: Int!) {
    allSalesVisitByOpportunityID(id: $id) {
      id
      action
      title
      cntctType
      cntctSbjct
      status
      details
      opportunityID
      notes
      userID
      actionDate
      actionTime
      closeDate
      slpCode
      tasksCompleted
      tasks {
        id
        baseID
        opportunityRowID
        completed
        detail {
          id
          complete
        }
      }
    }
  }`

  export const SAVE_ANSWER_TASK = gql`
  mutation saveTaskResponse($baseID: Int!, $id: Int!, $answer: String!){
    saveTaskResponse(baseID: $baseID, opportunityRowID: $id, answer: $answer)
 }`