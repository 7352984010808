import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { useQuery, useSubscription } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import { Badge, ButtonGroup, Col, Row } from 'reactstrap'
import DataTableApp from '../../Layout/DataTableApp'

// apollo
import { GET_ALL_BINLOCATIONS, GET_ALL_WAREHOUSES, SUBSCRIPTION_INTERNAL_TRANSFER } from '../../../graphql/Catalogs/Warehouse'
import CardInfo from '../../Layout/CardInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ItemsClasesTypeColor } from '../../../Types'

const BinLocations = () => {
    const [pagination, setPagination] = useState({
        skip: 0,
        take: 3000
    })
    const [pageInfo, setPageInfo] = useState({ hasNextPage: 0, hasPreviousPage: 0, totalCount: 0 })
    const [emptyBins, setEmptyBis] = useState(false)
    const [whsCode, setWhsCode] = useState("-1")
    const [toggleCleared, setToggleCleared] = useState(false);

    const [listOfBinLocations, setListOfBinLocations] = useState([])
    const [dataList, setDataList] = useState([])
    const [listContent, setListContent] = useState([])
    const [listBatches, setListBatches] = useState([])
    const [listOfWarehouses, setListOfWarehouses] = useState([{
        value: "-1",
        label: "Todos",
        selected: true,
    }])

    const { loading, error, data } = useQuery(GET_ALL_BINLOCATIONS, {
        variables: {
            skip: pagination.skip,
            take: pagination.take,
        }
    })
    const { data: _addedTransfer, loading: _loadingTransfer, error: _errorAddedTransfer } = useSubscription(SUBSCRIPTION_INTERNAL_TRANSFER)
    const { loading: loadingWarehouses, error: errorWarehouses, data: dataWarehouses } = useQuery(GET_ALL_WAREHOUSES)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                if (data.allBinLocations) {
                    setPageInfo({
                        hasNextPage: data.allBinLocations.pageInfo.hasNextPage,
                        hasPreviousPage: data.allBinLocations.pageInfo.hasPreviousPage,
                        totalCount: data.allBinLocations.totalCount,
                    })
                    setListOfBinLocations(data.allBinLocations.items)
                    let content = []
                    data.allBinLocations.items.map((element) => {
                        content.push(...element.content)
                    })
                    setListContent(content.sort(({ clase }) => clase))
                }
            }
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!_loadingTransfer) {
            if (!_errorAddedTransfer)
                AlertApp({ type: 'infoToast', message: `Se genero el traspaso :${_addedTransfer.id} del lote: ${_addedTransfer.batchNum}` })
        }
    }, [_addedTransfer, _loadingTransfer])

    useEffect(() => {
        if (listOfBinLocations.length > 0) {
            setToggleCleared(true)
            if (!emptyBins) {
                setDataList(listOfBinLocations)
            } else {
                setDataList(listOfBinLocations.filter((element) => {
                    if (element.isEmpty === false &&
                        (whsCode != "-1" ? element.whsCode === whsCode : true))
                        return element
                }))
            }
        }
    }, [listOfBinLocations, emptyBins, whsCode])

    useEffect(() => {
        if (!loadingWarehouses) {
            if (errorWarehouses) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                if (dataWarehouses.allWarehouses) {
                    let list = []
                    list.push({
                        value: "-1",
                        label: "Todos",
                        selected: true,
                    })
                    dataWarehouses.allWarehouses.map((element) => {
                        list.push({
                            value: element.whsCode,
                            label: `${element.whsCode} - ${element.whsName}`,
                            selected: false,
                        })
                    })
                    setListOfWarehouses(list)
                }
            }
        }
    }, [loadingWarehouses, errorWarehouses, dataWarehouses])

    const columns = [
        {
            name: 'Ubicación',
            selector: row => row.name,
        },
        {
            name: 'Almacen',
            selector: row => row.whsCode,
        },
        {
            name: 'Cantidad de artículos',
            selector: row => row.countItems,
        },
        {
            name: 'Número de artículos',
            selector: row => row.quantityItems,
        },
        {
            name: 'Agrupación',
            selector: row => row.attr1Val
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,

        },
    ]
    const columnsExpandable = [
        {
            name: '#',
            selector: row => <Badge pill color={ItemsClasesTypeColor[row.clase]}>{`${row.itemCode}`}</Badge>,
            sortable: false,
            width: '70px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,
            sortable: false,
            grow: 6,
        },
        {
            name: 'Cantidad',
            selector: row => row.onHandQty,
            sortable: false,
        },
        {
            name: 'Unidad',
            selector: row => row.uomCode,
            sortable: false,
        },
        {
            name: 'Posicion',
            selector: row => listOfBinLocations.length > 0 ? listOfBinLocations.find((element) => element.absEntry === row.binAbs).binCode : '',
            sortable: false,
        },
    ]
    const columnsBatches = [
        {
            name: '#',
            selector: row => row.binCode,
            sortable: false,
            width: '120px',
        },
        {
            name: 'SKU',
            selector: row => <Badge pill color={ItemsClasesTypeColor[row.clase]}>{`${row.itemCode}`}</Badge>,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,
            sortable: false,
            grow: 6,
        },
        {
            name: 'Lote',
            selector: row => row.distNumber,
            sortable: false,
        },
        {
            name: 'Cantidad',
            selector: row => row.onHandQty,
            sortable: false,
        },
    ]
    const handleFilter = (event) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        let list = []

        list = listOfBinLocations.filter((element) => {
            if (element.isEmpty === false &&
                (whsCode != "-1" ? element.whsCode === whsCode : true))
                return element
        }).filter((element) => {

            return JSON.stringify(element)
                .toLocaleLowerCase()
                .indexOf(event.target.value.toLocaleLowerCase()) !== -1
        })
        setDataList(list)
        let rows = []
        let batches = []
        list.map((element) => {
            rows.push(...element.content)
            element.content.map((row) => batches.push(...row.batches))
        })
        setListContent(rows.filter(({ itemCode, itemName }) => {

            return JSON.stringify({ itemCode, itemName })
                .toLocaleLowerCase()
                .indexOf(event.target.value.toLocaleLowerCase()) !== -1
        }))
        setListBatches(batches.filter(({ itemCode, distNumber, mnfSerial }) => {

            return JSON.stringify({ itemCode, distNumber, mnfSerial })
                .toLocaleLowerCase()
                .indexOf(event.target.value.toLocaleLowerCase()) !== -1
        }))

    }
    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data

        if (selectedRows.length > 0) {

            let list = []
            let batches = []
            selectedRows.map((element) => {
                list.push(...element.content)
                element.content.map((row) => batches.push(...row.batches))
            })
            setListContent(list)
            setListBatches(batches)
        } else {
            setListContent([])
            setListBatches([])
        }
    }
    const handleContentChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data

        if (selectedRows.length > 0) {

            let list = []

            selectedRows.map((element) => {
                list.push(...element.batches)
            })

            setListBatches(list)
        }
    }
    const conditionalRowStyles = [
        {
            when: row => row.attr1Val === "CROMICO",
            style: {
                backgroundColor: 'rgb(196,92,20)',
            },
        },
        {
            when: row => row.attr1Val == "CROMATOS LIQUIDOS",
            style: {
                backgroundColor: 'rgb(244,180,132)',
            },
        },
        {
            when: row => row.attr1Val == "ACIDOS",
            style: {
                backgroundColor: 'rgb(252,252,4)',
            },
        },
        {
            when: row => row.attr1Val == "METALES",
            style: {
                backgroundColor: 'rgb(196,228,180)',
            },
        },
        {
            when: row => row.attr1Val == "CIANUROS",
            style: {
                backgroundColor: 'rgb(180,196,228)',
            },
        },
        {
            when: row => row.attr1Val == "MATERIA PRIMA",
            style: {
                backgroundColor: 'rgb(132,148,180)',
            },
        },
        {
            when: row => row.attr1Val == "ALCALINOS",
            style: {
                backgroundColor: 'rgb(180,196,228)',
            },
        },
        {
            when: row => row.attr1Val == "NEUTROS",
            style: {
                backgroundColor: 'rgb(252,220,100)',
            },
        },
    ];

    const buttonTools = () => {
        return (
            <>
                <ButtonGroup>
                    <button className="btn btn-secondary" >
                        <FontAwesomeIcon icon={"location-pin"} />{" Traspaso interno"}
                    </button>
                    <button className="btn btn-secondary" >
                        <FontAwesomeIcon icon={"circle"} />{" Historial"}
                    </button>
                </ButtonGroup>
            </>
        )
    }
    return (
        <DashboardLayout>
            <Row>
                <Col lg={2}>
                    <CardInfo type='primary' value="Ubicaciones" title={dataList.length} />
                </Col>
                <Col lg={2}>
                    <CardInfo type='primary' value="Inventario" title={listContent.length > 0 ? listContent.reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
                <Col lg={2}>
                    <CardInfo value="Kg" title={listContent.length > 0 ? listContent.filter((element) => element.uomCode == "Kg").reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
                <Col lg={2}>
                    <CardInfo value="Pzas" title={listContent.length > 0 ? listContent.filter((element) => element.uomCode == "Pzas").reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
                <Col lg={2}>
                    <CardInfo value="L" title={listContent.length > 0 ? listContent.filter((element) => element.uomCode == "L").reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
                <Col lg={2}>
                    <CardInfo value="gr" title={listContent.length > 0 ? listContent.filter((element) => element.uomCode == "gr").reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
                <Col lg={2} hidden>
                    <CardInfo value="Juego" title={listContent.length > 0 ? listContent.filter((element) => element.uomCode == "Juego").reduce((n, { onHandQty }) => n + onHandQty, 0).toLocaleString("es-MX", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0} />
                </Col>
            </Row>
            <Row>
                <Col lg={7}>
                    <CardBox
                        title={"Lista de ubicaciones"}
                        loading={loading}
                        actionTools={buttonTools()}
                        content={<>
                            <Row>
                                <Col>
                                    <div className="custom-control custom-checkbox">
                                        <input className="custom-control-input" type="checkbox" id="check1" defaultChecked={true} onChange={() => setEmptyBis(!emptyBins)} />
                                        <label htmlFor="check1" className="custom-control-label">Mostrar ubicaciones vacias</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Almacenes</label>
                                        <select className="custom-select" onChange={(event) => {
                                            setWhsCode(event.target.value)
                                         
                                        }}>
                                            {listOfWarehouses.map((element) => {

                                                return <option key={element.value} value={element.value}>{element.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label>Buscar</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Numero de articulo, Código de barras, Lote, ubicacion" onChange={(e) => handleFilter(e)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DataTableApp
                                        data={dataList}
                                        columns={columns}
                                        dense
                                        selectableRows
                                        onSelectedRowsChange={handleChange}
                                        highlightOnHover
                                        pointerOnHover
                                        clearSelectedRows={toggleCleared}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />
                                </Col>
                            </Row>
                        </>}
                    />
                </Col>
                <Col lg={5}>
                    <Row>
                        <Col>
                            <CardBox
                                title={"Contenido"}
                                loading={loading}
                                content={<>
                                    <Row>
                                        <Col>
                                            <h5>A <Badge color='primary' >{`${listContent.filter((element) => element.clase == "A").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>B <Badge color='secondary' >{`${listContent.filter((element) => element.clase == "B").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>C <Badge color='info' >{`${listContent.filter((element) => element.clase == "C").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>D <Badge color='success' >{`${listContent.filter((element) => element.clase == "D").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>AA <Badge color='warning' >{`${listContent.filter((element) => element.clase == "AA").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>PF <Badge color='danger' >{`${listContent.filter((element) => element.clase == "PF").length}`} </Badge></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DataTableApp
                                                data={listContent}
                                                columns={columnsExpandable}
                                                dense
                                                selectableRows
                                                onSelectedRowsChange={handleContentChange}
                                                highlightOnHover
                                                pointerOnHover

                                            />
                                        </Col>
                                    </Row>
                                </>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardBox
                                title={"Lotes"}
                                loading={loading}
                                content={<>
                                    <Row>
                                        <Col>
                                            <h5>A <Badge color='primary' >{`${listBatches.filter((element) => element.clase == "A").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>B <Badge color='secondary' >{`${listBatches.filter((element) => element.clase == "B").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>C <Badge color='info' >{`${listBatches.filter((element) => element.clase == "C").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>D <Badge color='success' >{`${listBatches.filter((element) => element.clase == "D").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>AA <Badge color='warning' >{`${listBatches.filter((element) => element.clase == "AA").length}`} </Badge></h5>
                                        </Col>
                                        <Col>
                                            <h5>PF <Badge color='danger' >{`${listBatches.filter((element) => element.clase == "PF").length}`} </Badge></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DataTableApp
                                                data={listBatches}
                                                columns={columnsBatches}
                                                dense
                                            />
                                        </Col>
                                    </Row>
                                </>}
                            />
                        </Col>
                    </Row>

                </Col>
            </Row>
        </DashboardLayout>
    )
}

export default BinLocations