import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DataTableApp from '../Layout/DataTableApp'

const ChooseFromListModal = ({ chooseFromList, setChooseFromList, title, size = "lg", okButtonText = "Ok", cancelButtonText = "Cerrar", data = [], columns = [], onSelectedAction,selectableRows = false, filter =false}) => {
    const [toggleCleared, setToggleCleared] = useState(false)
    const [selectedRowsCount, setSelectedRowsCount] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])

    const toggle = () => setChooseFromList(!chooseFromList)

    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        setSelectedRowsCount(selectedRows.length)

        if (selectedRows.length > 0) {
            setSelectedRows(selectedRows)
        } else {
            selectedRows([])
        }
    }
    const handleOnSelectedRows = () => {
        if (onSelectedAction) {
            onSelectedAction(selectedRows)
        }
    }

    return (
        <Modal isOpen={chooseFromList} toggle={toggle} size={size}>
            <ModalHeader>{`${title}`}</ModalHeader>
            <ModalBody>
                <DataTableApp
                    data={data}
                    columns={columns}
                    dense
                    highlightOnHover
                    handOnHover
                    selectableRows={selectableRows}
                    filter={filter}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggleCleared} />
            </ModalBody>
            <ModalFooter>
                <button className={`btn btn-primary`} onClick={() => handleOnSelectedRows()} hidden={selectedRowsCount === 0}>
                    {`Agregar [ ${selectedRowsCount} ]`}
                </button>
                <button className={`btn btn-primary`} onClick={toggle}>
                    {okButtonText}
                </button>{' '}
                <button className={`btn btn-secondary`} onClick={toggle}>
                    {cancelButtonText}
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default ChooseFromListModal