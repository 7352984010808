import { gql} from "@apollo/client"

export const GET_ALL_COMMODITIES_RECEPTION = gql`
query allCommoditiesReceipts($status: String!) {
  allCommoditiesReceipts(status: $status) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    items {
      id
      receptionDate
      status
      userID
      comments
      endOfReception
      scheduleDate
      cardCode
      cardName
      objType
      totalQuantity
      firstOrder
      relatedDocuments {
        id
        lineID
        baseID
        baseEntry
        docNum
        objType
        cardCode
        cardName
        docDate
        docDueDate
        canceled
        docStatus
      }
      content {
        id
        lineNum
        baseID
        baseEntry
        baseLine
        userID
        user {
          id
          fullName
        }
        baseType
        itemCode
        itemName
        barcode
        manualScan
        mnfSerial
        lotNumber
        expDate
        quantity
      }
    }
  }
}
` 
export const SUBSCRIPTION_COMMODITIES_RECEIPTS_ADDED = gql`
subscription commodityReceiptAdded {
  commodityReceiptAdded {
    id
    receptionDate
    status
    userID
    comments
    endOfReception
    scheduleDate
    cardCode
    cardName
    objType
    relatedDocuments {
      id
      lineID
      baseID
      baseEntry
      docNum
      objType
      cardCode
      cardName
      docDate
      docDueDate
      canceled
      docStatus
    }
    content {
      id
      lineNum
      baseID
      baseEntry
      baseLine
      userID
      user {
        id
        fullName
      }
      baseType
      itemCode
      itemName
      barcode
      manualScan
      mnfSerial
      lotNumber
      expDate
      quantity
    }
  }
}
`
export const SUBSCRIPTION_COMMODITIES_RECEIPTS_CONTENT_ADDED = gql`
subscription commodityReceiptContentAdded {
  commodityReceiptContentAdded {
    id
    docEntry
    docNum
    docType
    docStatus
    docDate
    docDueDate
    cardCode
    cardName
    numAtCard
    totalQuantity
    totalOpenQty
    indicatorName
    whsCode
    sucursal
    content {
      docEntry
      lineNum
      trgetEntry
      baseRef
      baseType
      baseLine
      lineStatus
      itemCode
      dscription
      quantity
      openQty
      whsCode
      warehouse {
        whsCode
        whsName
        u_Sucursal
      }
      treeType
      unitMsr
    }
    receipts {
      id
      baseEntry
      baseType
      itemCode
      itemName
      barcode
      manualScan
      mnfSerial
      lotNumber
      expDate
      quantity
    }
  }
}
`;
export const SUBSCRIPTION_REQUEST_QUALITY_CERTIFICATE = gql`
subscription requestForQualityCertificate {
  requestForQualityCertificate {
    attached1
    attached1Ext
    attached2
    attached2Ext
    attached3
    attached3Ext
    commitQty
    distNumber
    docEntry
    expDate
    id
    indDate
    itemCode
    itemName
    lineNum
    mnfDate
    objType
    quantity
    status
    userID
    whsCode
  }
} `
export const FINISH_COMMODITIES_RECEIPTS = gql`
mutation finishCommodityReceipt($id: Int!){
  finishCommodityReceipt(id: $id)
}
`
export const SUBSCRIPTION_COMMODITIES_RECEIPTS_FINISH = gql`
subscription purchaseOrderReceiptComplete {
  purchaseOrderReceiptComplete {
    id
      docEntry
      docNum
      docType
      docStatus
      docDate
      docDueDate
      cardCode
      cardName
      numAtCard
      totalQuantity
      totalOpenQty
      indicatorName
      whsCode
      sucursal
      content {
        docEntry
        lineNum
        trgetEntry
        baseRef
        baseType
        baseLine
        lineStatus
        itemCode
        dscription
        quantity
        openQty
        whsCode
        warehouse {
          whsCode
          whsName
          u_Sucursal
        }
        treeType
        unitMsr
      }
      receipts {
        id
        baseEntry
        baseType
        itemCode
        itemName
        barcode
        manualScan
        mnfSerial
        lotNumber
        expDate
        quantity
      }
  }
}

`
export const SUBSCRIPTION_COMMODITIES_RECEIPTS_CANCELED  =gql`
subscription {
  commodityReceiptCanceled {  
    id   
  }
} ` 

export const ADD_BATCH_INFORMATION_ORDER = gql`
mutation($input: BatchNumberInfoInput!) {
  addCommoditiesReceiptBatchInfo(input: $input)
}` 

export const DELETE_BATCH_INFORMATION_ORDER = gql`
mutation deleteCommoditiesReceiptBatchInfo($id: Int!) {
  deleteCommoditiesReceiptBatchInfo(id: $id)
}`
export const AUTH_BATCH_INFORMATION_ORDER = gql`
mutation authCommoditiesReceiptBatchInfo(
  $id: Int!
  $auth: AuthBatchNumberInfoInput!
) {
  authCommoditiesReceiptBatchInfo(id: $id, input: $auth)
}`

export const GET_ALL_BATCH_INFORMATION_ORDER = gql`
query allBatchNumberInformation($docEntry: Int) {
  allBatchNumberInformation(docEntry: $docEntry) {
    id
    userID
    user {
      fullName
    }
    docEntry
    document {
      cardCode
      cardName
      docNum
      comments
    }
    objType
    lineNum
    line {
      quantity
      openQty
    }
    itemCode
    itemName
    item {
      buyUnitMsr
    }
    distNumber
    whsCode
    quantity
    commitQty
    status
    expDate
    mnfDate
    indDate
    attached1
    attached1Ext
    attached2
    attached2Ext
    attached3
    attached3Ext
    qualityNote
    qualityCert
    qualityCertExt
    hasQualityCert
    certDate
  }
}
`