import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { } from 'react'
import { useLocation, useParams } from "react-router-dom";
const CardBox = ({ title, content, footer = false, loading = false, cardTools = false, actionTools = null,
    btnSubmit = null, btnSubmitText = null,
    btnUseMultiSubmit = false, btnMultiSubmit = [{ action: null, text: null }],
    btnReturn = null, btnReturnText = null, statusColor = 'card-primary' }) => {
    const { id: _id } = useParams()
    const location = useLocation()

    const formButtons = (
        <div className="control-btn-box">

            {btnUseMultiSubmit && (
                <div className="btn-group">
                    <button type="submit" className={`btn btn-${_id ? "info" : "primary"}`}
                        onClick={typeof btnSubmit === 'function' ? btnSubmit : () => { }}>
                        <FontAwesomeIcon icon={"plus"} /> {btnSubmitText ? btnSubmitText : _id ? " Guardar" : " Crear"}
                    </button>
                    <button type="button" className={`btn btn-${_id ? "info" : "primary"} dropdown-toggle dropdown-icon`} data-toggle="dropdown">
                        <span className="sr-only"></span>
                    </button>
                    <div className="dropdown-menu" role="menu">
                        {btnMultiSubmit.map((elment, index) => {
                            return <button key={index} type="button" className="dropdown-item" onClick={typeof elment.action === 'function' ? elment.action : () => { }}>{elment.text}</button>
                        })}
                    </div>
                </div>
            )}

            {!btnUseMultiSubmit && btnSubmit && (
                <button
                    type="submit"
                    onClick={typeof btnSubmit === 'function' ? btnSubmit : () => { }}
                    className={`btn btn-${_id ? "info" : "primary"}`}
                >
                    <FontAwesomeIcon icon={"plus"} /> {btnSubmitText ? btnSubmitText : _id ? " Guardar" : " Crear"}
                </button>
            )}
            {btnReturn && (
                <button
                    type="button"
                    onClick={typeof btnReturn === 'function' ? btnReturn : () => { }}
                    className="btn btn-secondary"
                >
                    <FontAwesomeIcon icon={"times"} /> {btnReturnText ? btnReturnText : _id ? " Regresar" : " Cancelar"}
                </button>
            )}
        </div>
    )
    return (
        <div className={`card ${statusColor} card-outline`} >
            <div className="card-header">
                <h3 className="card-title">{title}</h3>
                <div className="card-tools">
                    {actionTools}
                    {cardTools && (<a type="button" className="btn btn-primary" href={`${location.pathname}/add`}>
                        <FontAwesomeIcon icon={"plus-square"} />{" Nuevo"}
                    </a>)}
                </div>
            </div>
            <div className={`card-body ${loading ? '' : ''}`}>
                {content}
            </div>
            {footer && (
                <div className="card-footer">
                    {formButtons}
                </div>
            )}
            {loading && (
                <div className="overlay dark">
                    <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                </div>
            )}
        </div>
    )
}

export default CardBox