import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertApp } from '../../Global/AlertApp';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, } from 'reactstrap';
import InputApp from '../../Layout/InputApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddressesSBOType } from '../../../Types';

function AddressesCardTab({ addressType, create = null, update = null, progress = null, allCountries = [], getStates = null, opportunity = null, address = null }) {
    const [optStates, setOptStates] = useState([])
    const validationSchema = Yup.object().shape({
        // addressType: Yup.string().required("Seleccionar un tipo de contacto"),
        country: Yup.string().required("El pais es necesario"),
        state: Yup.string().required("El estado es necesario"),
        city: Yup.string().required("el campo ciudad es obligatorio"),
        street: Yup.string().required("el campo calle/num es obligatorio"),
        block: Yup.string().required("el campo colonia es obligatorio"),
        zipCode: Yup.string().required("el campo codigo postal es obligatorio"),
    })

    const { handleSubmit, control, methods, reset } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const loadStates = async (country) => {
        try {
            const result = await getStates({
                variables: {
                    country
                }
            })

            let list = []
            if (result.data) {

                result.data.allStates.map((element) => {
                    return list.push({
                        value: element.code,
                        label: element.name,
                    })
                })

            }
            setOptStates(list)
        } catch (error) {
            AlertApp({ type: 'errorToast', error })
        }
    }
    const submitHandler = async (data) => {
        try {

            if (address) {

                AlertApp({ message: 'Operacion finalizada con exito' })

            } else {
                const result = await create({
                    variables: {
                        input: {
                            addressType: addressType,
                            country: data.country,
                            block: data.block,
                            cardCode: opportunity.cardCode,
                            city: data.city,
                            state: data.state,
                            street: data.street,
                            zipCode: data.zipCode,
                            opportunityID: opportunity.id,
                        }
                    }
                })

                progress({
                    variables: {
                        id: opportunity.id,
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })

            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {

        }
    }
    return (
        <FormProvider   {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Card className='card-info'>
                    <CardHeader>
                        <CardTitle>Direccion {AddressesSBOType[addressType]} </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <InputApp name={`country`} label={"Pais"} inputType='select' listOfOptions={allCountries} control={control} changeAction={(e) => loadStates(e.target.value)} />
                            </Col>
                            <Col>
                                <InputApp name={"state"} label={"Estado"} control={control} inputType='select' listOfOptions={optStates} isReadOnly={optStates.length === 0} />
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <InputApp name={"city"} label={"Ciudad"} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={"street"} label={"Calle/ Número"} control={control} />
                            </Col>
                            <Col>
                                <InputApp name={"block"} label={"Colonia"} control={control} />
                            </Col>
                            <Col>
                                <InputApp name={"zipCode"} label={"Código Postal"} control={control} />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div className="control-btn-box">
                            <button
                                type="submit"
                                onClick={() => { }}
                                className={`btn btn-primary`}
                            >
                                <FontAwesomeIcon icon={"plus"} /> Guardar
                            </button>
                            <button
                                type="button"
                                onClick={() => { }}
                                className="btn btn-secondary"
                            >
                                <FontAwesomeIcon icon={"times"} /> Cancelar
                            </button>
                        </div>
                    </CardFooter>
                </Card>
            </form>
        </FormProvider>
    )
}

export default AddressesCardTab