import React, { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import logo from '../../assets/images/Galber_perfil.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Nav, } from "reactstrap"
import NavItemSidebar from './NavItemSidebar'
import NavMenuSideBar from "./NavMenuSideBar"
import { AuthContext } from "../../AuthContext"
import ButtonApp from "./ButtonApp"
import Swal from "sweetalert2"
import { useApolloClient } from "@apollo/client"
import { AlertApp } from "../Global/AlertApp"
import { types } from "../../Types"
export const NavSidebar = ({ user }) => {
  const location = useLocation()
  const { user: userContext, dispatch } = useContext(AuthContext)
  const client = useApolloClient()
  const navigate = useNavigate()

  const onLogOut = () => {
    Swal.fire({
      title: 'Cerrar sesión',
      text: "¿Estás seguro de querer finalizar la sesión?",
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Salir"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          client.clearStore()
          sessionStorage.removeItem('user')
          sessionStorage.removeItem('requestPath')
          localStorage.removeItem('token')
          localStorage.removeItem('configSys')
          localStorage.removeItem('platform')
          navigate('/login')
          AlertApp({ message: 'Session finalizada', type: 'okToast' })

          setTimeout(() => {
            dispatch({
              type: types.logout,
            })

          }, 400)
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: error.message,
          })
        }
      }
    })
  }
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/" className="brand-link">
        <img src={logo} alt="AdminLTE Logo" className="brand-image elevation-3" />
        <span className="brand-text font-weight-light">GWMS</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">

          <div className="row info">
            <a href="/" className="d-block"><FontAwesomeIcon icon={"user"}></FontAwesomeIcon>{' '}{user.UserName}</a>

          </div>
        </div>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <NavItemSidebar path={"/"} icon={"fas fa-tachometer-alt"} label={"Inicio"} />
            {userContext.Rol === 1 && (
              <NavMenuSideBar
                path={'/settings'}
                label={"Configuracion"}
                icon={"fa-gear"}
                menu
                content={
                  <>
                    <NavMenuSideBar
                      path={'/settings/users'}
                      label={"Usuarios"}
                      icon={"fa-solid fa-gear"}
                      content={
                        <>
                          <NavItemSidebar path={"/settings/users"} icon={"far fa-circle"} label={"Accesos"} />
                          <NavItemSidebar path={"/settings/roles"} icon={"far fa-circle"} label={"Roles"} />
                          <NavItemSidebar path={"/settings/modules"} icon={"far fa-circle"} label={"Modulos"} />
                          <NavItemSidebar path={"/settings/permissions"} icon={"far fa-circle"} label={"Permisos"} />
                        </>
                      }
                    />
                    <NavMenuSideBar
                      path={"/settings/sales"}
                      icon={"fa-solid fa-gear"} label={"Ventas"}
                      content={
                        <>
                          <NavItemSidebar path={"/settings/sales/commercialprocesses"} icon={"far fa-circle"} label={"Procesos omerciales"} />
                          <NavItemSidebar path={"/settings/sales/commercialsubstages"} icon={"far fa-circle"} label={"Etapas comerciales"} />
                        </>
                      }
                    />
                    <NavMenuSideBar
                      path={"/settings/dangeroussubstances"}
                      icon={"fa-solid fa-gear"} label={"Sustancias Peligrosas"}
                      content={
                        <>
                          <NavItemSidebar path={"/settings/dangeroussubstances/classification"} icon={"far fa-circle"} label={"Clasificacion"} />
                        </>
                      }
                    />
                  </>
                }
              />)}
            {(userContext.Rol === 1 || userContext.Rol === 7|| userContext.Rol === 8) && (
              <NavMenuSideBar
                path={'/shopping'}
                icon={"fas fa-th"}
                label={"Entradas"}
                content={
                  <>
                    <NavItemSidebar path={"/shopping/purchaseorders"} icon={"far fa-circle"} label={"Ordenes de compra"} />
                    
                  </>
                }
              />)}
            {(userContext.Rol === 1) && (
              <NavMenuSideBar
                path={'/inventory'}
                icon={"fas fa-th"}
                label={"Inventarios"}
                content={
                  <>
                    <NavItemSidebar path={"/inventory/inventoryentry"} icon={"far fa-circle"} label={"Entrada de inventario"} />
                    <NavItemSidebar path={"/shopping/tansfers"} icon={"far fa-circle"} label={"Entradas por traspaso"} />
                  </>
                }
              />)}
            {(userContext.Rol === 1 || userContext.Rol === 6|| userContext.Rol === 8) && (
              <NavMenuSideBar
                path={'/warehouses'}
                icon={"fas fa-th"}
                label={"Almacen"}
                content={
                  <>
                    <NavItemSidebar path={"/warehouses/dangerousitems"} icon={"far fa-circle"} label={"Sustancias peligrosas"} />
                    <NavItemSidebar path={"/warehouses/QualityCertificates"} icon={"far fa-circle"} label={"Certificados"} />
                    <NavItemSidebar path={"/warehouses/binlocations"} icon={"far fa-circle"} label={"Ubicaciones"} />
                    <NavMenuSideBar
                      path={'/warehouses/binlocations'}
                      icon={"fas fa-th"}
                      label={"Operaciones"}
                      content={<>
                        <NavItemSidebar path={"/warehouses/binlocations/inputs"} icon={"far fa-circle"} label={"Entrada"} />
                        <NavItemSidebar path={"/warehouses/binlocations/transfers"} icon={"far fa-circle"} label={"Transpaso Interno"} />

                      </>}
                    />
                  </>
                }
              />)}
            {(userContext.Rol === 1 || userContext.Rol === 3 || userContext.Rol === 4) &&
              (
                <NavMenuSideBar
                  path={'/crm'}
                  icon={"fas fa-th"}
                  label={"CRM"}
                  content={
                    <>
                      <NavItemSidebar path={"/crm/portfolio"} icon={"far fa-circle"} label={"Clientes"} />
                      <NavItemSidebar path={"/crm/opportunities"} icon={"far fa-circle"} label={"Oportunidades"} />
                      <NavItemSidebar path={"/crm/QuotationOpportunities"} icon={"far fa-circle"} label={"Solicitudes de precio"} />
                      <NavItemSidebar path={"/crm/activities"} icon={"far fa-circle"} label={"Actividades"} />
                    </>
                  }
                />
              )}

          </ul>
        </nav>
        <div>
          <ButtonApp text={"Cerrar session"} faIcon={"right-from-bracket"} additionalClass={"btn-block mb-3"} color={"secondary"} action={() => onLogOut()} />
        </div>

      </div>
      
    </aside>
  )
}