import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, UncontrolledTooltip } from "reactstrap"
const ActionButtonsForRows = ({ baseUrl, element, editButton = false, showButton = false, deleteButton = false, deleteAction = null, goToButton = false,
    qualityCertificateButton = false, attachdocumentationButton = false,
    attachdocumentationAction = null, scheduleSalesVisitButton = false, batchInformationButton = false,
    batchInformationAction = null, selectButton = false, selectedAction = null,
    closeOpportunityButtonOptions = false, closeOpportunityOptionsAction = null }) => {

    const createEditButton = () => {
        if (editButton) {
            return <Link
                id={element.id + 'editButton'}
                key={element.id + 'editButton'}
                to={`/${baseUrl}/edit/${element.id}`}
                className='btn btn-secondary btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-pen" /></Link>
        } else {
            return <></>
        }
    }
    const createShowButton = () => {
        if (showButton) {
            return <Link
                id={element.id + 'showButton'}
                key={element.id + 'showButton'}
                to={`/${baseUrl}/details/${element.id}/show`}
                className='btn btn-secondary btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-circle-info" /></Link>
        } else {
            return <></>
        }
    }
    const createDeleteButton = () => {
        if (deleteButton) {
            return <button
                id={element.id + 'deleteButton'}
                key={element.id + 'deleteButton'}
                onClick={() => deleteAction()}
                className='btn btn-danger btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-trash-can" /></button>
        } else {
            return <></>
        }
    }
    const createGoToButton = () => {
        if (goToButton) {
            return (<>
                <Link
                    id={`goToButton${element.id}`}
                    key={`${element.id}goToButton`}
                    to={`/${baseUrl}/edit/${element.id}`}
                    className='btn btn-success btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-share-from-square" /></Link>
                <UncontrolledTooltip target={`goToButton${element.id}`}>Enviar a recepcion</UncontrolledTooltip>
            </>)
        } else {
            return <></>
        }
    }
    const attachdocumentationRow = () => {
        if (attachdocumentationButton) {
            return (<>
                <button
                    id={`attachdocumentationButton${element.id}`}
                    key={`attachdocumentationButton${element.id}`}
                    onClick={() => attachdocumentationAction()}
                    className='btn btn-danger btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-upload" /></button>
                <UncontrolledTooltip target={`attachdocumentationButton${element.id}`}>Adjuntar documentacion</UncontrolledTooltip>
            </>)
        } else {
            return <></>
        }
    }
    const createBatchInformationButton = () => {
        if (batchInformationButton) {
            return (<>
                <button
                    id={`batchInformationButton${element.id}`}
                    key={`batchInformationButton${element.id}`}
                    onClick={() => batchInformationAction()}
                    className='btn btn-info btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-qrcode" /></button>
                <UncontrolledTooltip target={`batchInformationButton${element.id}`}>Informacion de Lotes</UncontrolledTooltip>
            </>)
        } else {
            return <></>
        }
    }
    const createQualityCertificate = () => {
        if (qualityCertificateButton) {
            return (<>
                <Link
                    id={`qualityCertificateButton${element.id}`}
                    key={`${element.id}qualityCertificateButton`}
                    to={`/${baseUrl}/edit/${element.id}`}
                    className='btn btn-info btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-file-circle-question" />
                </Link>
                <UncontrolledTooltip target={`qualityCertificateButton${element.id}`}>Crear solicitud de Certificado de calidad</UncontrolledTooltip>
            </>)
        } else {
            return <></>
        }
    }
    const createScheduleSalesVisit = () => {
        if (scheduleSalesVisitButton) {
            return (<>
                <Link
                    id={`scheduleSalesVisitButton${element.cardCode}`}
                    key={`${element.cardCode}scheduleSalesVisitButton`}
                    to={`/crm/activities/add/${element.cardCode}/cn_Meeting`}
                    className='btn btn-info btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-calendar" />
                </Link>
                <UncontrolledTooltip target={`scheduleSalesVisitButton${element.cardCode}`}>Agendar visita</UncontrolledTooltip>
                <Link
                    id={`scheduleSalesCallButton${element.cardCode}`}
                    key={`${element.cardCode}scheduleSalesCallButton`}
                    to={`/crm/activities/add/${element.cardCode}/cn_Conversation`}
                    className='btn btn-info btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-mobile-screen" />
                </Link>
                <UncontrolledTooltip target={`scheduleSalesCallButton${element.cardCode}`}>Agendar llamada</UncontrolledTooltip>
                <Link
                    id={`newOportunityButton${element.cardCode}`}
                    key={`${element.cardCode}newOportunityButton`}
                    to={`/crm/opportunities/create/${element.cardCode}/${element.cardType}`}
                    className='btn btn-info btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-dice" />
                </Link>
                <UncontrolledTooltip target={`newOportunityButton${element.cardCode}`}>Nueva Oportunidad</UncontrolledTooltip>

            </>)
        } else {
            return <></>
        }
    }
    const createSelectedButton = () => {
        if (selectButton) {
            return <button
                id={`selectButton${element.id}`}
                key={`selectButton${element.id}`}
                onClick={() => selectedAction()}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        } else {
            return <></>
        }
    }

    const createcloseOpportunityButtonOptions = () => {
        if (closeOpportunityButtonOptions) {
            return <button
                id={`closeOpportunityButton${element.id}`}
                key={`closeOpportunityButton${element.id}`}
                onClick={() => closeOpportunityOptionsAction()}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        } else {
            return <></>
        }
    }
    return (<>
        {createEditButton()} {createShowButton()} {createDeleteButton()} {createGoToButton()}{createQualityCertificate()}{createScheduleSalesVisit()}{attachdocumentationRow()}{createBatchInformationButton()}{createSelectedButton()}{createcloseOpportunityButtonOptions()}
    </>)
}

export default ActionButtonsForRows

