import React from "react"
import { Route, Routes, } from "react-router-dom";

import Login from './Login'
import { ProtectedRoute } from "./Routers/ProtectedRoute";
import { PublicRoute } from "./Routers/PublicRoute";
import PrivateRoutes from './Routers/PrivateRoutes';
import EmploymentMasterData from "./Catalogs/Employees/EmploymentMasterData";
const AppRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<ProtectedRoute />}>
                    {/* specify private routes here  */}
                    {PrivateRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />
                    })}
                </Route>

                {/* specify public routes here */}
                <Route path='/' element={<PublicRoute />}>
                    <Route path='/login' element={<Login />} />
                    {/* <Route path='/employeecard' element={<EmploymentMasterData />} /> */}
                </Route>

            </Routes>
        </>


    )
}

export default AppRoutes