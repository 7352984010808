import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import DataTableApp from '../../Layout/DataTableApp';
import { AlertApp } from '../../Global/AlertApp';
import DropzoneInput from '../../Layout/DropzoneInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { isDate, parse } from 'date-fns';
import { DownloadingFiles } from '../../../Utilities';
import addNotification from 'react-push-notification';
const BatchInformationDialog = ({ _order, _item, _addRow, _rows, _dialog, _setDialog, _progress, _reload ,_progressDelete}) => {
    const [uploadFiles, setUploadFiles] = useState([])
    const [waiting, setWaiting] = useState(false)
    const toggle = () => {
        _setDialog(!_dialog)
        reset()
        setUploadFiles([])
    }

    const [dataList, setDataList] = useState([])

    const validationSchema = Yup.object().shape({
        itemCode: Yup.string().notRequired(),
        itemName: Yup.string().notRequired(),
        distNumber: Yup.string().required('El numero de lote es obligatorio')
            .test("is-greater", "El numero de lote son min 1 caracteres", function (value) {
                return value.length >= 1
            }),
        expDate: Yup.date()
            .default(() => new Date())
            .nullable()
            .notRequired()
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date())

                return parsedDate
            })
            .typeError('Se debe seleccionar una fecha valida'),
        lotQuantity: Yup.number().typeError('La cantidad del Lote debe ser un numero valido')
            .required("La cantidad del Lote es obligatoria.")
            .min(1, "El valor debe ser mayor a 0")
            .max(_item.quantity, 'La cantidad excede lo permitido!')
    })

    const { control, reset, methods, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) })

    const columns = [
        {
            name: 'Lote / Num Serie',
            selector: row => row.distNumber,
            sortable: false,
        },
        {
            name: 'Fecha de expiración',
            selector: row => moment(row.expDate).format('YYYY-MM-DD'),
            sortable: false,
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: false,
        },
        {
            name: 'Cantidad aprobada',
            selector: row => row.commitQty,
            sortable: false,
        },
        {
            name: 'COA',
            selector: row => row.attached1,
            sortable: false,
        },
        {
            name: '',
            selector: row => <>
                <button
                    type={"button"}
                    id={`fileCoa${row.id}`}
                    key={`fileCoa${row.id}`}
                    onClick={() => DownloadingFiles("CoaAtt", row.id, row.attached1, row.attached1Ext)}
                    className='btn btn-success btn-sm'
                ><FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /></button>
                <UncontrolledTooltip target={`fileCoa${row.id}`}>Documentación proveedor</UncontrolledTooltip>
                {row.status === "-1" && (
                    <>
                        <button
                            id={`decline${row.id}`}
                            key={`decline${row.id}`}
                            onClick={() => {
                                toggle()
                                setBatchInfo(row)
                            }}
                            className='btn btn-danger btn-sm'
                        ><FontAwesomeIcon icon={`fa-solid fa-file-circle-xmark`} /></button>
                        <UncontrolledTooltip target={`decline${row.id}`}>{`Certificado rechazado: ${row.qualityNote ?? ''}`}</UncontrolledTooltip>
                    </>
                )}
                {row.status === "1" && (
                    <>
                        <button
                            type={"button"}
                            id={`request${row.id}`}
                            key={`request${row.id}`}
                            onClick={() => DownloadingFiles("QuaCert", row.id, row.qualityCert, row.qualityCertExt)}
                            className='btn btn-info btn-sm'
                        ><FontAwesomeIcon icon="fa-solid fa-file-circle-check" /></button>
                        <UncontrolledTooltip target={`request${row.id}`}>Certificado de calidad</UncontrolledTooltip>
                    </>
                )}
            </>,
            sortable: false,
            // width: '80px'
        },
    ]
    const conditionalRowStyles = [
        {
            when: row => row.status === "-1",
            style: {
                backgroundColor: '#ea9999',
            },
        }
    ]

    useEffect(() => {
        setDataList(_rows.filter((element) => element.lineNum == _item.lineNum))
    }, [_item, _rows, _dialog])

    const onSubmit = async (data) => {
        try {
            const listFiles = Object.values(uploadFiles).map((file) => {

                return file
            })
            if (listFiles.length === 0) {
                AlertApp({ type: 'errorToast', message: 'El archivo COA es obligatorio' })
                return false
            }
            await _addRow({
                variables: {
                    input: {
                        docEntry: _order.docEntry,
                        objType: _order.objType,
                        itemCode: _item.itemCode,
                        lineNum: _item.lineNum,
                        distNumber: data.distNumber,
                        expDate: data.expDate,
                        quantity: data.lotQuantity,
                        whsCode: _item.whsCode,
                        attached1: listFiles[0],
                    },
                },
                refetchQueries: _reload,
                context: {
                    headers: {
                        "GraphQL-Preflight": 1
                    }
                }
            })
            reset()
            setUploadFiles([])
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })

        }
    }
    const onPrint = () => {
        DownloadingFiles("BtchInf", _order.docEntry, "Etiquetas.pdf", "application/pdf")
    }
    const sendRequest = () => {
        addNotification({
            title: 'Warning',
            subtitle: 'This is a subtitle',
            message: 'This is a very long message',
            theme: 'darkblue',
            duration: 300000,
            native: true // when using native, your OS will handle theming.
        });
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl' >
            <ModalHeader>{`Información de números de Lote de la Orden: ${_order.docNum}`}</ModalHeader>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="p-3 needs-validation">
                    <ModalBody>
                        <Row>
                            <Col lg={2}>
                                <InputApp name={"itemCode"} label={"No Articulo"} defaultValue={_item.itemCode} control={control} isReadOnly />
                            </Col>
                            <Col>
                                <InputApp name={"itemName"} label={"Descripcion"} defaultValue={_item.dscription} control={control} isReadOnly />
                            </Col>
                            <Col lg={2}>
                                <InputApp name={"quantity"} label={"Cantidad OC"} defaultValue={_item.openQty} control={control} isReadOnly />
                            </Col >
                            <Col lg={2}>
                                <InputApp name={"unitMsr"} label={"Unidad"} defaultValue={_item.unitMsr} control={control} isReadOnly />
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={"distNumber"} label={"Lote de Proveedor"} placeholder={"No Lote"} control={control} isLoading={_progress} />
                            </Col>
                            <Col>
                                <InputApp inputType={"date"} name={"expDate"} label={"Fecha de expiracion"} placeholder={"Fecha de vencimiento"} control={control} isLoading={_progress} />
                            </Col>
                            <Col>
                                <InputApp name={"lotQuantity"} label={"Cantidad"} placeholder={"Cantidad"} control={control} onlyNumbers={true} step={".1"} isLoading={_progress} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DropzoneInput label={"COA"} setUploadFiles={setUploadFiles} />
                            </Col>
                        </Row>
                        <Row>
                            <div className="control-btn-box">
                                <button type="submit" className="btn btn-primary">
                                    {_progress ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}   Agregar
                                </button>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <DataTableApp
                                    data={dataList}
                                    columns={columns}
                                    striped
                                    dense
                                    selectable
                                    conditionalRowStyles={conditionalRowStyles}
                                />
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <div className="control-btn-box">
                            <button type="button" className="btn btn-secondary" onClick={toggle}>
                                Cerrar
                            </button>
                            <button type="button" className="btn btn-success" onClick={() => onPrint()}>
                                <FontAwesomeIcon icon={"fa-solid fa-print"} /> Etiquetas
                            </button>
                        </div>

                    </ModalFooter>
                </form>
            </FormProvider>

        </Modal>
    )
}

export default BatchInformationDialog