import React, { useEffect, useState } from 'react'
import NotificationsMenu from './NotificationsMenu'
import { useCalendarContext } from '../../CalendarContext'
import mainCSS from '../../Styles/main.module.css'
export const Header = () => {
  const { events: _events } = useCalendarContext()
  const [events, setEvents] = useState([])
  useEffect(() => {
    if (_events)
      if (_events.length > 0) {
        setEvents(_events)
      }
  }, [_events])
  return (
    // <!-- Navbar -->
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* <!-- Left navbar links --> */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="/" className="nav-link">Inicio</a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href={"/calendar"} className="nav-link">Agenda</a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href={"/crm/activities"} className="nav-link">Actividades <span className="badge badge-danger navbar-badge">{events?.length}</span></a>
        </li>
       
        
      </ul>
      <NotificationsMenu />

    </nav>
  )
}
