import { gql } from "@apollo/client";

export const AUTHENTICATE = gql`
  mutation authenticate($code: String!, $password: String!) {
    authenticate(userCode: $code, password: $password)
  }
`;

export const DECRYPT = gql`
mutation decryptValue($token: String!) {
    decryptValue(token: $token) {
      id
      code
      fullName
      email
      roleID
      salesPersonCode
    }
  }
`;