import React, { useContext, useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
// apollo
import { SUBSCRIPTION_COMMODITIES_RECEIPTS_ADDED, SUBSCRIPTION_REQUEST_QUALITY_CERTIFICATE } from '../../graphql/Catalogs/MerchandiseReception/CommoditiesReceipt'
import { AlertApp } from '../Global/AlertApp'
import addNotification from 'react-push-notification'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../AuthContext'
import { DownloadingFiles } from '../../Utilities'

const NotificationsMenu = () => {
    const [notifications, setNotifications] = useState(0)
    const { user: userContext } = useContext(AuthContext)
    const navigate = useNavigate()

    const { loading, error, data } = useSubscription(SUBSCRIPTION_COMMODITIES_RECEIPTS_ADDED)
    const { loading: loadingQuality, error: errorQuality, data: dataQuality } = useSubscription(SUBSCRIPTION_REQUEST_QUALITY_CERTIFICATE)
    useEffect(() => {
        if (!loading)
            if (error) {
                AlertApp({ type: "errorToast", message: error })
            }
        if (data) {
            setNotifications(prev => prev + 1)

            AlertApp({
                icon: "ok",
                message: `Se inicio la recepcion de la OC: ${data.commodityReceiptAdded.relatedDocuments[0].docNum}`
            })
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingQuality) {
            if (errorQuality) {
                AlertApp({ type: "errorToast", message: errorQuality })
            }
            console.log(dataQuality)
            // if (dataQuality) {
            if (userContext.Rol === 1 || userContext.Rol === 6) {
                if (dataQuality) {
                    const certificate = dataQuality.requestForQualityCertificate
                    setNotifications(prev => prev + 1)
                    addNotification({
                        title: `Solicitud de Certificado ${certificate.id}`,
                        message: `Nueva solicitud de certificado de calidad para ${certificate.itemName} ${certificate.distNumber}`,
                        onClick: () => DownloadingFiles("QuaAtt", certificate.id, certificate.attached2, certificate.attached2Ext),
                        duration: 500000,
                        icon: require('../../assets/default.png'),
                        native: true // when using native, your OS will handle theming.
                    });
                }
            }
            // }
        }

    }, [loadingQuality, errorQuality, dataQuality])
    return (
        <ul className="navbar-nav ml-auto">
            <li className={"navbar-search-block"}>
                <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search"></i>
                </button>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                    <i className="far fa-bell"></i>
                    <span className="badge badge-warning navbar-badge">{notifications}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span className="dropdown-header">{`${notifications} Notificaciones`}</span>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item">
                        <i className="fas fa-envelope mr-2"></i> 4 actividades
                        <span className="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item dropdown-footer">Ver todas las notificaciones</a>
                </div>
            </li>
        </ul>

    )
}

export default NotificationsMenu