import React, { useState } from 'react'
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link, Outlet } from 'react-router-dom';
export const PublicRoute = () => {
    const [collapsed, setCollapsed] = useState(true)
    const toggleNavbar = () => {
        setCollapsed(!collapsed)

    }
    return (
        <>
           
            <div>
                <Outlet />
            </div>
        </>
    )
}
