import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardBody, Spinner, } from 'reactstrap';
import { AUTHENTICATE, DECRYPT } from '../graphql/Auth/Identity';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'
import { types } from '../Types';
import ButtonApp from './Layout/ButtonApp';


const Login = () => {
    const { dispatch } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        code: Yup.string().required('El código de usuario no es valido').test('empty-check', function (value) {
            return value.length > 0
        }),
        password: Yup.string().required('La contraseña no es valida')
            .test('empty-check', function (value) {
                return value.length > 0
            })
    })
    const { handleSubmit, register, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const [login] = useMutation(AUTHENTICATE)
    const [decrypt] = useMutation(DECRYPT)

    const submitHandler = async (data) => {
        // event.preventDefault();

        try {
            setLoading(true)
       
            const result = await login({
                variables: {
                    code: data.code,
                    password: data.password
                }
            })
            
            localStorage.setItem('token', result.data.authenticate)

            const { data: tokenData } = await decrypt({
                variables: {
                    token: result.data.authenticate,
                },
            })
            dispatch({
                type: types.login,
                payload: {
                    logged: true,
                    UserId: tokenData.decryptValue.id,
                    UserName: tokenData.decryptValue.fullName,
                    Email: tokenData.decryptValue.email,
                    SessionID: tokenData.decryptValue.password,
                    Rol: tokenData.decryptValue.roleID,
                    SalesPersonCode: parseInt(tokenData.decryptValue.salesPersonCode),
                },
            })
            navigate('/')
        } catch (error) {
            console.log(error)
            Swal.fire({
              
                icon: "error",
                title: error,
                showConfirmButton: false,
            })
        } finally {
            setLoading(false)
        }
    };
    return (
        <div className='login-page'>
            <div className='login-box'>
                <div className="login-logo">
                    <a href="https://galber.com/" target='_blank'><b>Galber</b></a>
                </div>
                <Card>
                    <CardBody className='login-card-body'>
                        <p className="login-box-msg">Iniciar tu sesión</p>
                        <form onSubmit={handleSubmit(submitHandler)} className="needs-validation">
                            <div className='input-group mb-3'>
                                <input className={`form-control${errors.code ? ' is-invalid' : ''}`} placeholder='Email o código de usuario' {...register('code')} autoComplete="off" disabled={loading}></input>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                                {errors.code && <p className="error invalid-feedback">{errors.code.message}</p>}
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className={`form-control${errors.code ? ' is-invalid' : ''}`} placeholder="Contraseña" {...register('password')} autoComplete="off" disabled={loading} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                                {errors.password && <p className="error invalid-feedback">{errors.password.message}</p>}
                            </div>
                            <div className='row'>
                                <div className="col-4">
                                    <ButtonApp buttonType={"submit"} text={"Iniciar"} processing={loading} faIcon={"fa-brands fa-react"} />
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default Login;