import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import DataTableApp from '../../Layout/DataTableApp'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'

// apollo
import { GET_ALL_BUSINESS_PARTNER } from '../../../graphql/QueryManager'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
const MyPortfolio = () => {
    const [dataList, setDataList] = useState([])

    const { loading, data, error } = useQuery(GET_ALL_BUSINESS_PARTNER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: '',
            type: 'C'
        }
    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'toastError', message: error })
            }
            let list = []

            if (data.allBusinessPartners) {
                data.allBusinessPartners.map((element) => {
                    let buttons = <ActionButtonsForRows
                        baseUrl={'crm/activities'}
                        element={element}
                        scheduleSalesVisitButton
                    />

                    let cloneElement = { ...element }
                    cloneElement.actions = buttons
                    return list.push(cloneElement)
                })
            }
            setDataList(list)
        }
    }, [loading, data, error])

    const columns = [
        {
            name: '#',
            selector: row => `${row.cardCode}`,
            width: '80px',
        },
        {
            name: 'Razon social',
            selector: row => `${row.cardName}`,
        },
        {
            name: 'RFC',
            selector: row => row.licTradNum,
        },
        {
            name: 'Territorio',
            selector: row => row.territry.descript,
        },
        {
            name: 'Industria',
            selector: row => row.industry.indName,
        },
        {
            name: '',
            selector: row => row.actions
        },
    ]
    return (
        <DashboardLayout>
            <CardBox
                title={"Mis clientes"}
                loading={loading}
                content={<>
                    <Row>
                        {
                            
                        }
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={dataList}
                                columns={columns}
                                dense
                                filter
                            />
                        </Col>
                    </Row>
                </>}
            />
        </DashboardLayout>
    )
}

export default MyPortfolio