import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import DataTableApp from '../../Layout/DataTableApp'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
import { Badge, Progress, Row, Col, FormGroup, Label } from 'reactstrap'
import { DocOpptSBO, DocStatesSBOColors, ListOfDocStatesSBO } from '../../../Types'
import moment from 'moment'
// apollo
import { GET_ALL_OPPORTUNITIES, GET_ALL_OPPORTUNITIES_DEFECT_CAUSE } from '../../../graphql/Catalogs/Opportunities'
import { GET_ALL_COMMERCIAL_STAGES } from '../../../graphql/Catalogs/CommercialStage'
import { GET_ALL_SALES_PERSONS } from '../../../graphql/QueryManager'
import CalendarApp from '../../Layout/CalendarApp'
import InputApp from '../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import { GET_ALL_COMMERCIAL_PROCESSES } from '../../../graphql/Catalogs/CommercialProcesses'
import { AuthContext } from '../../../AuthContext'
import CloseOpportunityModal from './Opportunities/CloseOpportunityModal'

function Opportunities() {
    const { user: userContext } = useContext(AuthContext)
    const [dataList, setDataList] = useState([])
    const [eventsList, setEventList] = useState([])
    const [queryFilter, setQueryFilter] = useState([])
    const [filterList, setFilterList] = useState([])
    const [filterEventList, setFilterEventList] = useState([])
    const [listOfCommercialProcess, setListOfCommercialProcess] = useState([])
    const [listOfStages, setListOfStages] = useState([])
    const [listOfSalesPerson, setListOfSalesPerson] = useState([])
    const [listOfDefectCause, setListOfDefectCause] = useState([])
    const [selectedRow, setSelectedRow] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const { control, getValues, setValue } = useForm()

    const { loading, error, data } = useQuery(GET_ALL_OPPORTUNITIES, {
        fetchPolicy: 'network-only'
    })
    const { loading: loadingCommercialProcess, error: errorCommercialProcess, data: dataCommercialProcess } = useQuery(GET_ALL_COMMERCIAL_PROCESSES, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingOpportunitiesStage, error: errorOpportunitiesStage, data: dataOpportunitiesStage } = useQuery(GET_ALL_COMMERCIAL_STAGES, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingDefectCause, error: errorDefectCause, data: dataDefectCause } = useQuery(GET_ALL_OPPORTUNITIES_DEFECT_CAUSE, {
        fetchPolicy: 'cache-and-network'
    })


    useEffect(() => {
        if (!loading) {
            if (error) {

                AlertApp({
                    type: 'error',
                    message: error,
                })
            }

            let list = []
            let events = []
            if (!error) {
                data.allOpportunities.map((element) => {

                    let buttons = <ActionButtonsForRows
                        baseUrl={'crm/opportunities'}
                        element={element}
                        editButton
                        showButton
                        deleteButton
                        closeOpportunityButtonOptions
                        closeOpportunityOptionsAction={() => {
                            console.log(element)
                            setSelectedRow(element)
                            setModalIsOpen(true)
                        }}
                    />

                    let cloneElement = { ...element }
                    cloneElement.actions = buttons

                    if (element.status === "O")
                        events.push({
                            id: element.cardCode,
                            title: `${element.id} ${element.title}`,
                            start: element.start,
                            end: element.predEnd,
                            slpCode: element.slpCode,
                            status: element.status,
                            baseID: element.baseID,
                            allDay: true,
                            display: 'block'
                        })
                    return list.push(cloneElement)
                })
            }


            setDataList(list)
            setEventList(events)
            setQueryFilter({ baseID: 1 })
            setValue('slpCode', userContext.SalesPersonCode)
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingCommercialProcess) {
            if (errorCommercialProcess) {
                AlertApp({ type: 'errorToast', message: errorCommercialProcess })
            }
            let list = []
            if (dataCommercialProcess.allCommercialProcess) {

                dataCommercialProcess.allCommercialProcess.map((element) => {
                    return list.push({
                        value: element.id,
                        label: element.name,
                    })
                })

            }

            setListOfCommercialProcess(list)
            setValue('commercialPorcessID', 1)
        }
    }, [loadingCommercialProcess, errorCommercialProcess, dataCommercialProcess])

    useEffect(() => {
        if (!loadingOpportunitiesStage) {
            if (errorOpportunitiesStage) {
                AlertApp({ type: 'errorToast', message: errorOpportunitiesStage })
            }
            setListOfStages(dataOpportunitiesStage.allCommercialStage)
        }
    }, [loadingOpportunitiesStage, errorOpportunitiesStage, dataOpportunitiesStage])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                        disabled: element.active === "N",
                    })
                })

            }
            setListOfSalesPerson(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])

    useEffect(() => {
        if (!loadingDefectCause) {
            if (errorDefectCause) {
                AlertApp({ type: 'errorToast', message: errorDefectCause })
            } else {
                let list = []
                
                dataDefectCause.allOpportunitiesDefectCause.map((element) => {
                    return list.push({
                        value: element.num,
                        label: element.descript,
                        target: element,
                    })
                })
                setListOfDefectCause(list)
            }
        }
    }, [loadingDefectCause, errorDefectCause, dataDefectCause])

    useEffect(() => {
        setFilterList(dataList.filter(item => {
            for (let key in queryFilter) {
                if (item[key] === undefined || item[key] != queryFilter[key])
                    return false;
            }
            return true;
        }))
        setFilterEventList(eventsList.filter(item => {
            for (let key in queryFilter) {
                if (item[key] === undefined || item[key] != queryFilter[key])
                    return false;
            }
            return true;
        }))
    }, [queryFilter])

    const columns = [
        {
            name: '#',
            selector: row => row.opprId ? `${row.opprId}` : row.id,
            width: '80px',
        },
        {
            name: 'Fecha de apertura',
            selector: row => moment(new Date(row.start)).format('YYYY-MM-DD'),
            width: '130px',
        },
        {
            name: 'Socio de negocio',
            selector: row => `${row.cardCode} - ${row.cardName}`,
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => <Badge color={DocStatesSBOColors[row.status]}> {DocOpptSBO[row.status]}</Badge>,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Representante',
            selector: row => row.salesPerson?.slpName,

        },
        {
            name: 'Etapa',
            selector: row => row.reason,

        },
        {
            name: 'Progreso',
            width: '500 px',
            selector: row => <Progress value={row.cloPrcnt} style={{
                width: '150px'
            }}> {`${row.cloPrcnt} %`} </Progress>,
            sortable: false,
        },
        {
            name: 'Tareas Completadas',
            width: '500 px',
            selector: row => `${row.completedTasks} / ${row.content.length}`,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    const handlerFilter = (event) => {
        const slpCode = getValues('slpCode')
        const status = getValues('status')
        const stage = getValues('commercialPorcessID')
        let query = {}

        if (stage != "")
            query.baseID = stage

        if (slpCode != -1 && slpCode != '')
            query.slpCode = slpCode

        if (status != "")
            query.status = status

        setQueryFilter(query)


    }

    const smallBoxInfo = (stage) => {
        return <Col lg={2} xs={4} key={stage.id}>
            <div className={"small-box bg-info"} >
                <div className={"inner"}>
                    <h3>{filterList.filter((element) => element.currentStage === stage.entry).length}</h3>

                    <p>{stage.name}</p>
                </div>
                <div className={"icon"}>
                    <i className={"ion ion-stats-bars"}></i>
                </div>
                <a href="#" className="small-box-footer">Mas info <i className="fas fa-arrow-circle-right"></i></a>
            </div>
        </Col>

    }
    return (

        <DashboardLayout>
            <CardBox
                title={"Oportunidades"}
                loading={loading || loadingCommercialProcess || loadingSalesPerson || loadingOpportunitiesStage}
                cardTools
                content={<>
                    <Row>
                        <Col>
                            <InputApp inputType={"select"} name={"commercialPorcessID"} label={"Proceso Comercial"} control={control} listOfOptions={listOfCommercialProcess} changeAction={(e) => handlerFilter(e)} />
                        </Col>
                    </Row>
                    <Row>
                        {listOfStages.map((element) => {
                            if (element.baseID === parseInt(getValues('commercialPorcessID')))
                                return smallBoxInfo(element)
                        })}
                    </Row>
                    <Row>
                        {userContext.Rol === 1 && (<Col>
                            <InputApp inputType={"select"} name={"slpCode"} label={"Vendedor"} control={control} listOfOptions={listOfSalesPerson} changeAction={(e) => handlerFilter(e)} />
                        </Col>)}
                        {userContext.Rol === 3 && (<Col>
                            <InputApp inputType={"select"} name={"slpCode"} isReadOnly={true} label={"Vendedor"} control={control} listOfOptions={listOfSalesPerson} changeAction={(e) => handlerFilter(e)} />
                        </Col>)}
                        <Col>
                            <InputApp inputType={"select"} name={"status"} label={"Status"} control={control} listOfOptions={ListOfDocStatesSBO} changeAction={(e) => handlerFilter(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={filterList}
                                columns={columns}
                                dense
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CalendarApp data={filterEventList} />
                        </Col>
                    </Row>

                </>}
            />
            <CloseOpportunityModal _Opportunity={selectedRow} _setOpportunity={setSelectedRow} _setDialog={setModalIsOpen} _dialog={modalIsOpen} _listOfDefectCause={listOfDefectCause} />
        </DashboardLayout>
    )
}

export default Opportunities