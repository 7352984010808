export const types = {
  login: "[auth] login",
  logout: "[auth] logout",
};

export const typesCalendar = {
  save: "save",
  load: "load",
  get: "get"
};

export const StrikesCategorys = {
  1: "Programado",
  2: "Proceso",
  3: "Producción",
  4: "Calidad",
  5: "Maquinaria",
  6: "Almacén",
  7: "Externos",
};

export const StrikesCategorysOptions = [
  { value: "1", label: "Programado" },
  { value: "2", label: "Proceso" },
  { value: "3", label: "Producción" },
  { value: "4", label: "Calidad" },
  { value: "5", label: "Maquinaria" },
  { value: "6", label: "Almacén" },
  { value: "7", label: "Externos" },
];

export const CardTypeOptions = [
  { value: "C", label: "Cliente" },
  { value: "S", label: "Proveedor" },
  { value: "L", label: "Lead" },
];

export const SalesCardTypeOptions = [
  { value: "C", label: "Cliente" },  
  { value: "L", label: "Lead" },
];

export const DivisionTypeOptions = [
  { value: "DAS", label: "DAS" },
  { value: "DPE", label: "DPE" },
  { value: "DQI", label: "DQI" },
];

export const ManufacturingRecordContentTypes = {
  L: "Registro",
  S: "Scrap",
  P: "Paro",
};

export const ScrapCategorys = {
  1: "Moldeo",
  2: "Horno",
  3: "Empaque",
  4: "Serigrafía",
  5: "Preexpansion",
};

export const ListOfDocStatesSBO = [
  { value: "", label: "" },
  { value: "O", label: "Abierto" },
  { value: "C", label: "Cancelado" },
  { value: "L", label: "Cerrado" },
  { value: "W", label: "Ganada" },
];
export const ListOfOpptStatesSBO = [
  { value: "O", label: "Abierta" }, 
  { value: "L", label: "Perdida" },
  { value: "W", label: "Ganada" },
];

export const ListOfOpptStatesValuesSBO ={
  O: "sos_Open",
  L: "sos_Missed",
  W: "sos_Sold",
};

export const ColorsOfOpptStatesSBO = {
  'O': "info",
  'L': "danger",
  'W': "success",
};

export const DocStatesSBO = {
  O: "Abierto",
  C: "Cancelado",
  L: "Cerrado",
  W: "Ganada",
};

export const DocOpptSBO = {
  O: "Abierto",
  L: "Perdida",
  W: "Ganada",
  tYES: 'Completado',
};

export const StateActivitysSBO = {
  '-3': "Concluido",
  '-2': 'No Iniciado',
  '1': 'Reagendado',
  '2': 'Iniciado',
  'tNO': 'Abierto',
  'tYES': 'Cerrado',
  'C': 'Cancelado',
  'L': 'Cerrado'
};

export const YesNoActivitysSBO = {
  tNO: "Pendiente",
  tYES: 'Completado',
};

export const DocStatesSBOColors = {
  O: "primary",
  C: "danger",
  L: "danger",
  W: "success",
  tYES: 'success',
};

export const ActivitysStatesSBOColors = {
  '1': "info",
  '-3': 'success',
  '-2': 'warning',
  'tNO': 'info',
  'tYES': 'success',
  'C': 'danger'
};

export const ActivitysStatesSBOSelect = [
  { value: "", label: "" },
  { value: "tYES", label: "Abierto" },
  { value: "tNO", label: "Cerrado" }
]


export const AddressesSBOType = {
  S: "Entrega",
  B: "Facturacion",
};
export const ActionsSBOType = {
  C: "Llamada telefónica",
  M: "Reunión",
  T: "Tarea",
  E: "Nota",
  P: "Campaña",
  N: "Otro",
};
export const ActionsSBO = [
  { value: "cn_Conversation", label: "Llamada telefónica" },
  { value: "cn_Meeting", label: "Reunión" },
  { value: "cn_Task", label: "Tarea" },
  { value: "cn_Note", label: "Nota" },
  { value: "cn_Campaign", label: "Campaña" },
  { value: "cn_Other", label: "Otro" },
];

export const CurrencyCodesTypesSBO = [
  { value: "##", label: "Monedas (todas)" },
  { value: "CAN", label: "CAN" },
  { value: "EUR", label: "EUR" },
  { value: "MXP", label: "MXP" },
  { value: "USD", label: "USD" },
];

export const paymentTermCodesTypesSBO = [
  { value: 1, label: "Contado CXC" },
  { value: 2, label: "Contado CXP" },
];
export const ContactTypes = [
  { value: "Contacto Compras", label: "Contacto Compras" },
  { value: "Contacto Pagos", label: "Contacto Pagos" },
  { value: "Contacto Almacén", label: "Contacto Almacén" },
  { value: "Contacto Procesos", label: "Contacto Procesos" },
  { value: "Contacto Usuario", label: "Contacto Usuario" },
];

export const ScalePH = {
  0: 'Muy ácido',
  1: 'Muy ácido',
  2: 'Moderadamente ácido',
  3: 'Moderadamente ácido',
  3.5: 'Moderadamente ácido',
  4: 'Moderadamente ácido',
  5: 'Ligeramente ácido',
  6: 'Ligeramente ácido',  
  6.1: 'Neutro',
  7: 'Neutro',
  7.9: 'Neutro',
  8: 'Ligeramente alcalino',
  9: 'Ligeramente alcalino',
  10: 'Moderadamente alcalino',
  11: 'Moderadamente alcalino',
  12: 'Moderadamente alcalino',
  13: 'Muy alcalino',
  14: 'Muy alcalino'
}
export const ScaleNumPH = {
  0: 'Ácido',
  1: 'Ácido',
  2: 'Ácido',
  3: 'Ácido',
  3: 'Ácido',
  4: 'Ácido',
  5: 'Ácido',
  6: 'Ácido',  
  6.1: 'Neutro',
  7: 'Neutro',
  7.9: 'Neutro',
  8: 'Alcalino',
  9: 'Alcalino',
  10: 'Alcalino',
  11: 'Alcalino',
  12: 'Alcalino',
  13: 'Alcalino',
  14: 'Alcalino'
}

export const ScalePHColors = {
  0: '#ff1621',
  1: '#ff6616',
  2: '#ffc501',
  3: '#f9ee00',
  4: '#a9d408',
  5: '#5cc42f',
  6: '#01b93d',
  7: '#00aa40',
  8: '#00b566',
  9: '#00b8b6',
  10: '#0192d1',
  11: '#2252a7',
  12: '#5e50a7',
  13: '#6c43a1',
  14: '#4d2887',
}

export const DistributionByGroupColors = {
  CROMICO: '#ff1621',
  CROMATOS: '#ff6616',
  ACIDOS: '#ffc501',
  METALES: '#f9ee00',
  CIANUROS: '#a9d408',
  MATERIAPRIMA: '#00aa40',
  ALCALINOS: '#00b566',
  NEUTROS: '#5e50a7',

}

export const ItemsClasesTypeColor = {
  A: 'primary',
  B: 'secondary',
  C: 'success',
  D: 'info',
  AA: 'warning',
  PF: 'danger',
}