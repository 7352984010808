import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Progress, ButtonGroup, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Card, CardBody, CardHeader } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import DataTableApp from '../../../Layout/DataTableApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColorsOfOpptStatesSBO, ListOfOpptStatesSBO, ListOfOpptStatesValuesSBO } from '../../../../Types'
import { AlertApp } from '../../../Global/AlertApp'
import { currencyFormat } from '../../../../Utilities'

const CloseOpportunityModal = ({ _Opportunity, _setOpportunity, _dialog, _setDialog, _listOfStages = [], _listOfDefectCause = [], _update, _user }) => {
    const [listOfItems, setListOfItems] = useState([])
    const toggle = () => {
        _setDialog(!_dialog)
    }
    const { control, getValues, reset, } = useForm()

    useEffect(() => {
        if (_Opportunity) {
            reset(_Opportunity)
            let list = []

            if (_Opportunity.wspOpptItems)
                _Opportunity.wspOpptItems.map((element) => {
                    let cloneElement = { ...element }

                    cloneElement.inputQuantity = <input type={"number"} id={`rowQuantity_${cloneElement.id}_${element.lineNum}`} className={"form-control form-control-sm"} defaultValue={element.quantity} readOnly={readOnlyRows}/>
                    cloneElement.inputValue = <input type={"number"} id={`rowValue_${cloneElement.id}_${element.lineNum}`} className={"form-control form-control-sm"} defaultValue={parseFloat(element.price).toFixed(2)} prefix={element.currency} readOnly={readOnlyRows}/>
                    cloneElement.inputReason = <select className={"custom-select"} >
                        <option disabled value="-1" selected>
                            Seleccione una opción
                        </option>
                        {_listOfDefectCause.map(element => {
                            return <option key={element.value} value={element.value} disabled={element.disabled} >  {element.label}</option>
                        })}
                    </select>
                    // cloneElement.saveButton = <button className={'btn btn-success btn-sm'} ><FontAwesomeIcon icon="fa-solid fa-save" /></button>
                    list.push(cloneElement)
                })
            setListOfItems(list)
        }
    }, [_Opportunity])

    const readOnlyRows = !(_user.Rol === 4 || _user.Rol === 1)
    const columns = [

        {
            name: 'SKU',
            selector: row => row.itemCode,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,

        },
        {
            name: 'Volumen',
            selector: row => row.inputQuantity,
        },
        {
            name: 'Precio',
            selector: row => row.inputValue,
        },
        {
            name: 'Precio Total',
            selector: row => `${row.lineTotal.toFixed(2)} ${row.currency}`,
        },

        {
            name: '',
            selector: row => row.saveButton,
        },
    ]

    const onSubmit = async () => {
        try {

            let list = []
                let index = 0;
                listOfItems.map((row) => {
                    list.push({
                        index: row.id,
                        price: parseFloat( document.getElementById(`rowValue_${row.id}_${row.lineNum}`).value),

                    })
                    index++;
                })
                
            const result = await _update({
                variables: {
                    id: _Opportunity.id,
                    input: {
                        currency: getValues("currency"),
                        currentStage: parseInt(getValues("currentStage")),
                        reason: parseInt(getValues("numReason")) === 0 ? null : parseInt(getValues("numReason")),
                        status:ListOfOpptStatesValuesSBO[_Opportunity.status],
                        maxLocalTotal: listOfItems.length === 0 ? 1.0 : listOfItems.reduce((n, { lineTotal }) => n + lineTotal, 0),
                        items: list,
                    }
                }
            })
            if (result.errors) {
                AlertApp({ type: 'errorToast', message: result.errors })
            }
            if (result.data.updateQuotationOpportunity) {
                AlertApp({type: 'okToast', message: 'Operacion finalizada con exito'})
                toggle()
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }

    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl' className={`card card-${ColorsOfOpptStatesSBO[_Opportunity.status]} card-outline`}>
            <ModalHeader>
                {`Oportunidad ${(_Opportunity.opprId ? _Opportunity.opprId : _Opportunity.id)} ${_Opportunity.title}`}
            </ModalHeader>
            <ModalBody>
                <form>
                    <Row>
                        <Col sm={{ size: 3, offset: 9 }}>
                            <InputApp name={"currentStage"} label={"Etapa"} inputType={"select"} control={control} listOfOptions={_listOfStages} changeAction={(e) => _setOpportunity((prevState) => ({ ...prevState, currentStage: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} >
                            <InputApp name={"start"} label={"Fecha de inicio"} inputType='date' control={control} isReadOnly={true} />
                        </Col>

                        <Col sm={{ size: 3, offset: 6 }}>
                            <InputApp name={"status"} label={`Status`} inputType={"select"} listOfOptions={ListOfOpptStatesSBO} control={control} changeAction={(e) => _setOpportunity((prevState) => ({ ...prevState, status: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <InputApp name={"currency"} label={"Moneda"} control={control} isReadOnly={true} />
                        </Col>
                        <Col sm={{ size: 3, offset: 6 }}>
                            <InputApp name={"numReason"} label={`Razones`} inputType={"select"} listOfOptions={_listOfDefectCause} defaultOptionValue={"-1"} control={control} isReadOnly={_Opportunity.status !== "L"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 3, offset: 9 }}>
                            <div className="table-responsive">
                                <table className="table">
                                    <tr>
                                        <th>Total:</th>
                                        <td>{`${currencyFormat(listOfItems.length > 0 ? listOfItems.reduce((n, { lineTotal }) => n + lineTotal, 0) : 0,_Opportunity.currency )}`}</td>
                                    </tr>
                                </table>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col  >
                            <DataTableApp
                                data={listOfItems}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter>
                <div className="control-btn-box">
                    <button type={"submit"} className="btn btn-primary" onClick={() => onSubmit()}>
                        Guardar
                    </button>
                    <button type={"button"} className="btn btn-secondary" onClick={toggle}>
                        Cerrar
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CloseOpportunityModal