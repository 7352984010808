
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
import moment from 'moment'

// apollo
import { GET_OPEN_TRANSFERS_REQUEST } from '../../../graphql/QueryManager'
import DataTableApp from '../../Layout/DataTableApp'
export const TransferRequestsList = () => {
    const [dataList, setDataList] = useState()

    const { loading, error, data } = useQuery(GET_OPEN_TRANSFERS_REQUEST)

    useEffect(() => {
        if (!loading) {
            if (error) {
                Swal.fire({
                    title: 'Ocurrio un error inesperado',
                    text: error,
                    icon: 'error'
                })
            }
            let list = []
            data.openTransfersRequest.items.map((element) => {

                let buttons = <ActionButtonsForRows
                    baseUrl={'shopping/purchaseorders'}
                    element={element}
                    showButton
                    goToButton
                    qualityCertificateButton
                />

                return list.push({
                    id: element.id,
                    docEntry: element.docEntry,
                    docNum: element.docNum,
                    docStatus: element.docStatus,
                    docDate: element.docDate,
                    docDueDate: element.docDueDate,
                    cardCode: element.cardCode,
                    cardName: element.cardName,
                    numAtCard: element.numAtCard,
                    indicator: element.indicatorName,
                    whsCode: element.whsCode,
                    sucursal: element.sucursal,
                    totalQuantity: element.totalQuantity,
                    totalOpenQty: element.totalOpenQty,
                    actions: buttons
                })
            })
            setDataList(list)
        }
    }, [loading, error, data])

    const columns = [
        {
            name: '#',
            selector: row => row.docEntry,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Número de documento',
            selector: row => row.docNum,
            sortable: false,
        },
        {
            name: 'Sucursal',
            selector: row => row.sucursal,
            sortable: false,
        },
              {
            name: 'Fecha de contabilización',
            selector: row => row.docDate,
            sortable: false,
            format: row => moment(row.docDate).format('YYYY-MM-DD'),
        },
        {
            name: 'Fecha de vencimiento',
            selector: row => row.docDueDate,
            sortable: false,
            format: row => moment(row.docDueDate).format('YYYY-MM-DD'),
        },
                {
            name: 'Número de referencia',
            selector: row => row.numAtCard,
            sortable: false,
        },
        {
            name: 'Total',
            selector: row => row.totalQuantity,
            sortable: false,
        },
        {
            name: 'Pendiente',
            selector: row => row.totalOpenQty,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    return (
        <DashboardLayout>
            <CardBox
                title={"Solicitudes de traslado"}
                content={
                    <>
                        <DataTableApp
                            data={dataList}
                            columns={columns}
                            pagination
                            striped
                            dense
                        />
                    </>
                }
            />
        </DashboardLayout>
    )
}
