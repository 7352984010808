import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, FormGroup, Label, Row } from 'reactstrap'

const DropzoneInput = ({ label, setUploadFiles = null, noKeyboard = false, uploadMode = true, uploadAction = null, downloadAction = null }) => {
    const [files, setFiles] = useState([])
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        open
    } = useDropzone({ maxFiles: 1, noKeyboard: noKeyboard, noClick: !uploadMode })

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
    const focusedStyle = {
        borderColor: '#2196f3'
    }

    const acceptStyle = {
        borderColor: '#00e676'
    }

    const rejectStyle = {
        borderColor: '#ff1744'
    }

    const validatedStyle = {
        borderColor: '#28a745'
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(files.length ? validatedStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
        files
    ])

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            const list = []
            const upload = []
            acceptedFiles.map(file => {
                list.push(`${file.path} - ${file.size} bytes`)

                upload.push(file)

            })
            setFiles(list)
            if (setUploadFiles)
                setUploadFiles(upload)

        }
    }, [acceptedFiles])

    return (
        <FormGroup>
            <div {...getRootProps({ style, })}>
                <input {...getInputProps()} />
                <p>{`${files.length > 0 ? files[0] : 'Arrastre y suelte archivos aquí o haga clic para seleccionar archivos'}`}</p>
                <div className="btn-group w-100">
                    <button type={"button"} className="btn btn-success col fileinput-button" onClick={() => {
                        if (uploadMode) {
                            if (uploadAction)
                                uploadAction()
                        } else {
                            if (downloadAction)
                                downloadAction()
                        }

                    }}>
                        <i className="fas fa-file"></i>
                        <span>{' '}{label}</span>
                    </button>
                    <button type={"button"} className="btn btn-primary col start" onClick={() => {
                        open()
                    }}>
                        <i className="fas fa-upload"></i>
                        <span> Seleccionar</span>
                    </button>
                    <button type={"button"} className="btn btn-warning col cancel" onClick={() => {
                        setFiles([])
                        if (setUploadFiles)
                            setUploadFiles([])
                    }}>
                        <i className="fas fa-times-circle"></i>
                        <span> Limpiar</span>
                    </button>
                </div>
            </div>

        </FormGroup>
    )
}

export default DropzoneInput