import { gql } from "@apollo/client";

export const GET_ALL_ACTIVITIES_TYPES = gql`
query allActivityTypes {
  allActivityTypes {
    code
    name
  }
}`

export const GET_ONE_ACTIVITY = gql`
query oneActivity($entry: Int!) {
  oneActivity(entry: $entry) {
    activityCode
    cardCode
    cardName
    notes
    activityDate
    activityTime
    startDate
    closed
    closeDate
    phone
    fax
    subject
    docType
    docNum
    docEntry
    priority
    details
    activityProperty
    activityType
    location
    startTime
    endTime
    duration
    durationType
    salesEmployee
    contactPersonCode
    contactPerson {
      cntctCode
      cardCode
      name
      firstName
      lastName
      position
      address
      tel1
      tel2
      cellolar
      e_MailL
    }
    handledBy
    reminder
    reminderPeriod
    reminderType
    city
    personalflag
    street
    parentobjectId
    parentobjecttype
    room
    inactiveflag
    state
    previousActivity
    country
    status
    tentativeflag
    endDuedate
    docTypeEx
    attachmentEntry
    recurrencePattern
    endType
    seriesStartDate
    seriesEndDate
    maxOccurrence
    interval
    sunday
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    repeatOption
    belongedSeriesNum
    isRemoved
    addressName
    addressType
    handledByEmployee
    recurrenceSequenceSpecifier
    recurrenceDayInMonth
    recurrenceMonth
    recurrenceDayOfWeek
    salesOpportunityId
    salesOpportunityLine
    activityCheckIns
    handledByRecipientList
    office365EventId
    activityRecipients
    cardStatus
    assignedTask {
      id
      notes
      userID
      slpCode
      action
      status
    }
    taskContent {
      id
      baseID
      taskID
      task {
        name
        validationType
      }
      completed
      answer
      attachment {
        id
        fileName
        attachmentDate
      }      
    }
    attachments {
        id
        fileName
        freeText
        attachmentDate
        fileExt
    }
  }
}
`

export const GET_ALL_ACTIVITIES = gql`
query allActivities {
    allActivities {
      activityCode
      cardCode
      cardName
      notes
      activityDate
      activityTime
      startDate
      start
      allDay
      closed
      closeDate
      phone
      fax
      subject
      docType
      docNum
      docEntry
      priority
      details
      activityProperty
      activityType
      location
      startTime
      endTime
      duration
      durationType
      salesEmployee
      contactPersonCode
      contactPerson {
        cntctCode
        cardCode
        name
        firstName
        lastName
        position
        address
        tel1
        tel2
        cellolar
        e_MailL
      }
      handledBy
      reminder
      reminderPeriod
      reminderType
      city
      personalflag
      street
      parentobjectId
      parentobjecttype
      room
      inactiveflag
      state
      previousActivity
      country
      status
      tentativeflag
      endDuedate
      docTypeEx
      attachmentEntry
      recurrencePattern
      endType
      seriesStartDate
      seriesEndDate
      maxOccurrence
      interval
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      repeatOption
      belongedSeriesNum
      isRemoved
      addressName
      addressType
      handledByEmployee
      recurrenceSequenceSpecifier
      recurrenceDayInMonth
      recurrenceMonth
      recurrenceDayOfWeek
      salesOpportunityId
      salesOpportunityLine
      activityCheckIns
      handledByRecipientList
      office365EventId
      activityRecipients
      assignedTask {
        id
        notes
        userID
        slpCode
        action
        status
      }
      taskContent {
        id
        baseID
        taskID
        task{
          name
        }
        completed
        answer
      }
    }
  }`

export const GET_ACTIVITIES_INBOX = gql`
query salesInbox($skip: Int!, $take: Int!) {
  salesInbox(skip: $skip, take: $take) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    tasksCount
    items {
      activityCode
      activityState
      activityStateIcon
      cardCode
      cardName
      notes
      activityDate
      activityTime
      startDate
      start
      allDay
      closed
      closeDate
      phone
      fax
      subject
      docType
      docNum
      docEntry
      priority
      details
      activityProperty
      activityType
      location
      startTime
      endTime
      duration
      durationType
      salesEmployee
      contactPersonCode
      contactPerson {
        cntctCode
        cardCode
        name
        firstName
        lastName
        position
        address
        tel1
        tel2
        cellolar
        e_MailL
      }
      handledBy
      reminder
      reminderPeriod
      reminderType
      city
      personalflag
      street
      parentobjectId
      parentobjecttype
      room
      inactiveflag
      state
      previousActivity
      country
      status
      tentativeflag
      endDuedate
      docTypeEx
      attachmentEntry
      recurrencePattern
      endType
      seriesStartDate
      seriesEndDate
      maxOccurrence
      interval
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      repeatOption
      belongedSeriesNum
      isRemoved
      addressName
      addressType
      handledByEmployee
      recurrenceSequenceSpecifier
      recurrenceDayInMonth
      recurrenceMonth
      recurrenceDayOfWeek
      salesOpportunityId
      salesOpportunityLine
      activityCheckIns
      handledByRecipientList
      office365EventId
      activityRecipients
      assignedTask {
        id
        notes
        userID
        slpCode
        action
        status
      }
      taskContent {
        id
        baseID
        taskID
        task{
          name
        }
        completed
        answer
      }
    }
  }
}
`

export const SET_RESPONSE_ACTIVITY = gql`
mutation saveActivityTaskResponse ($answer: String!, $baseID: Int!, $entry: Int!, $id: Int! ) {
  saveActivityTaskResponse(answer: $answer, baseID: $baseID, entry: $entry, id: $id)
}
`
export const CREATE_ACTIVITY = gql`
mutation createScheduleSalesVisit ($input: ScheduleSalesVisitInput!){
  createScheduleSalesVisit(input: $input){
    id
  }
} 
`

export const CANCEL_ACTIVITY = gql`
mutation cancelActivity($input: CancelSalesVisitInput!){
  cancelActivity(input: $input)
}
`
export const COMPLETE_ACTIVITY = gql`
mutation completeActivity($input: CompleteSalesVisitInput!){
  completeActivity(input: $input)
}`

export const UPATE_ACTIVITY = gql`
mutation updateActivity($input: ChangeSalesVisitInput!) {
  updateActivity(input: $input)
}`

export const ATTACHED_FILE_ACTIVITY = gql`
mutation attachmentToSalesVisit($input: AttachmentSalesVisitInput!) {
  attachmentToSalesVisit(input: $input)
}` 