import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap'
import DualListBox from 'react-dual-listbox'
import InputApp from '../../Layout/InputApp'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//apollo
import {
    CREATE_HAZARDOUS_SUBSTANCE_TYPE,
    GET_ALL_HAZARDOUS_SUBSTANCE_TYPES,
    GET_ONE_HAZARDOUS_SUBSTANCE_TYPE,
    UPDATE_COMPATIBILITI_SUSTANCE_TYPE
} from '../../../graphql/Catalogs/TypesOfHazardousSubstances'
import { GET_ALL_SGA } from '../../../graphql/Catalogs/SGA'
import { GET_ALL_ADR } from '../../../graphql/Catalogs/ADR'
import DataTableApp from '../../Layout/DataTableApp'


const DangerouesSubstanceNew = () => {
    const { id: _id, show } = useParams()
    const navigate = useNavigate()
    const [waiting, setWaiting] = useState(false)
    const [panel, setPanel] = useState(1)
    const [hazardousSubstance, setHazardouesSubtance] = useState({})
    const [optADR, setOptADR] = useState([])
    const [optSGA, setOptSGA] = useState([])
    const [optNOT, setOptNOT] = useState([])
    const [selectedSGA, setSelectedSGA] = useState([])
    const [selectedADR, setSelectedADR] = useState([])
    const [selectedNOT, setSelectedNOT] = useState([])
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre es requerido'),
    })

    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const { loading, error, data } = useQuery(GET_ONE_HAZARDOUS_SUBSTANCE_TYPE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })
    const { loading: loadingSGA, data: dataSGA, error: errorSGA } = useQuery(GET_ALL_SGA)
    const { loading: loadingADR, data: dataADR, error: errorADR } = useQuery(GET_ALL_ADR)
    const { loading: loadingCompatibility, data: dataCompatibility, error: errorCompatibility } = useQuery(GET_ALL_HAZARDOUS_SUBSTANCE_TYPES, {
        fetchPolicy: 'cache-and-network'
    })


    const [create] = useMutation(CREATE_HAZARDOUS_SUBSTANCE_TYPE)
    const [updateCompatibiliti] = useMutation(UPDATE_COMPATIBILITI_SUSTANCE_TYPE)

    useEffect(() => {
        if (_id)
            if (!loading) {
                if (error) {
                    AlertApp({ type: 'errorToast', message: error })
                } else {
                    setHazardouesSubtance(data.oneHazardousSubstanceType)
                    reset(data.oneHazardousSubstanceType)
                    // let categories = []
                    // data.oneHazardousSubstanceType.categories.map((category) => {
                    //     return categories.push(category.category.id)
                    // })
                    // setSelectedSGA(categories)
                    // let classes = []
                    // data.oneHazardousSubstanceType.classes.map((element) => {
                    //     return classes.push(element.class.id)
                    // })
                    // setSelectedADR(classes)

                    // let list = []
                    // data.oneHazardousSubstanceType.compatibility.map((element) => {
                    //     if (element.conventionID >= 1) {

                    //         return list.push(
                    //             element.id

                    //         )
                    //     }
                    // })

                    // setOptNOT(list)
                }
            }
    }, [loading, error, data, _id])

    useEffect(() => {
        if (!loadingSGA) {
            if (errorSGA) {
                AlertApp({ type: 'errorToast', message: errorSGA })
            } else {
                if (dataSGA.allRiskCategory) {
                    let list = []
                    dataSGA.allRiskCategory.map((element) => {
                        list.push({
                            value: element.id,
                            label: `${element.name} - ${element.description}`,
                            url: element.sga,
                        })
                    })
                    setOptSGA(list)
                }
            }
        }
    }, [loadingSGA, dataSGA, errorSGA])

    useEffect(() => {
        if (!loadingADR) {
            if (errorADR) {
                AlertApp({ type: 'errorToast', message: errorADR })
            } else {
                if (dataADR.allRiskClass) {
                    let list = []
                    dataADR.allRiskClass.map((element) => {
                        list.push({
                            value: element.id,
                            label: `${element.name} - ${element.description}`,
                            url: element.adr,
                        })
                    })
                    setOptADR(list)
                }
            }
        }
    }, [loadingADR, dataADR, errorADR])

    useEffect(() => {
        if (!loadingCompatibility) {
            if (errorCompatibility) {
                AlertApp({ type: 'errorToast', message: errorCompatibility })
            } else {
                if (dataCompatibility.allHazardousSubstanceTypes) {
                    setOptNOT(dataCompatibility.allHazardousSubstanceTypes)
                }
            }
        }
    }, [loadingCompatibility, dataCompatibility, errorCompatibility])
    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '70px',
        },
        {
            name: 'Nombre',
            selector: row => row.right.name,
            sortable: false,
        },
        {
            name: 'SGA',
            selector: row => <>{row.right.categories.map((element) => <img key={`row-${row.id}-${row.right.id}`} src={require(`../../../assets/images/SGA/${element.category.sga}`)} width="40px"></img>)}</>,
            sortable: false,
            width: '120px',
        },
        {
            name: 'Compatibilidad',
            selector: row => row.convention.description,
        },
        {
            name: 'Notas',
            selector: row => row.lvl.id,
        },

    ]
    const conditionalRowStyles = [
        {
            when: row => row.conventionID < 0,
            style: {
                backgroundColor: 'rgba(255,255,255)',
                // color: 'white',
                // '&:hover': {
                //     cursor: 'pointer',
                // },
            },
        },
        {
            when: row => row.conventionID == 1,
            style: {
                backgroundColor: 'rgba(0,128,0)',
                // color: 'white',
                // '&:hover': {
                //     cursor: 'pointer',
                // },
            },
        },
        {
            when: row => row.conventionID == 2,
            style: {
                backgroundColor: 'rgba(255,215,0)',
                // color: 'white',
                // '&:hover': {
                //     cursor: 'not-allowed',
                // },
            },
        },
        {
            when: row => row.conventionID == 3,
            style: {
                backgroundColor: 'rgba(178,34,34)',
                // color: 'white',
                // '&:hover': {
                //     cursor: 'not-allowed',
                // },
            },
        },
    ];

    const onChangeCompatibility = async (e, element) => {
        try {
            e.preventDefault()
            setWaiting(true)
            let next = element.conventionID + 1;
            if (next === 0) {
                next = 1;
            }
            if (next === 4) {
                next = -1;
            }

            const result = await updateCompatibiliti({
                refetchQueries: refetchAll,
                variables: {
                    id: element.id,
                    convention: next,
                    lvl: element.conventionlvl
                }
            })
            if (result.data.updateCompatibilitiSubstanceType) {
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        } finally {
            setWaiting(false)
        }
    }
    const onSubmit = async (data) => {
        try {
            const result = await create({
                variables: {
                    input: {
                        name: data.name,
                        categories: selectedSGA,
                        classes: selectedADR
                    }
                }
            })
            if (result.data.createHazardousSubstanceType) {
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                navigate('/settings/dangeroussubstances/classification')
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }

    return (
        <DashboardLayout>
            <CardBox
                loading={waiting || loading || loadingADR || loadingSGA}
                title={`${_id ? 'Sustancia peligrosa' : 'Nueva clase de sustancia peligrosa'}`}
                btnSubmit={!show ? handleSubmit(onSubmit) : null}
                btnReturn={() => navigate(-1)}
                footer
                content={<>

                    <Row>
                        <Col>
                            <InputApp name={"name"} label={"Descripcion"} control={control} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <div className={'card-tools'}>
                                        <ul className={'nav nav-pills ml-auto'}>
                                            <li className={'nav-item'}>
                                                <a className={`nav-link${panel === 1 ? ' active' : ''}`} href="#general" data-toggle="tab" onClick={() => setPanel(1)}>General</a>
                                            </li>
                                            <li className={'nav-item'}>
                                                <a className={`nav-link${panel === 2 ? ' active' : ''}`} href="#compatibilities" data-toggle="tab" onClick={() => setPanel(2)}>Compatibilidad</a>
                                            </li>
                                        </ul>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className={'tab-content p-0'}>
                                        <div className={`tab-pane ${panel === 1 ? ' active' : ''}`} id={'general'}>
                                            <Row>
                                                <Col>
                                                    <Label>ADR:</Label>
                                                    <DualListBox
                                                        options={optADR}
                                                        selected={selectedADR}
                                                        onChange={(newValue) => setSelectedADR(newValue)}
                                                    />
                                                </Col>
                                                <Col>
                                                    {!loadingADR && (<>
                                                        {selectedADR.map((element) => {
                                                            const sga = optADR.find((option) => option.value == element)
                                                            return <img src={require(`../../../assets/images/ADR/${sga.url}`)} key={element.value}></img>
                                                        })}
                                                    </>)}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>SGA:</Label>
                                                    <DualListBox
                                                        options={optSGA}
                                                        selected={selectedSGA}
                                                        onChange={(newValue) => setSelectedSGA(newValue)}
                                                    />
                                                </Col>
                                                <Col>
                                                    {!loadingSGA && (<>
                                                        {selectedSGA.map((element) => {
                                                            const sga = optSGA.find((option) => option.value == element)
                                                            return <img src={require(`../../../assets/images/SGA/${sga.url}`)} key={element.value}></img>
                                                        })}
                                                    </>)}
                                                </Col>
                                            </Row>

                                        </div>
                                        <div className={`tab-pane ${panel === 2 ? ' active' : ''}`} id={'compatibilities'}>
                                            <Row>
                                                <Col>
                                                    <Label>Sustancias peligrosas</Label>
                                                    <DataTableApp
                                                        data={hazardousSubstance?.compatibility}
                                                        columns={columns}
                                                        conditionalRowStyles={conditionalRowStyles}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </>}
            />
        </DashboardLayout>
    )
}

export default DangerouesSubstanceNew