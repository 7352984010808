import React from "react";
import { Button, Input, InputGroup } from "reactstrap";


const FilterComponent = ({ filterText, onFilter, onClear, ...props }) => (
    <InputGroup style={{ width: '300px'  }}  {...props}>
        <Button color="info" onClick={onClear}>X</Button>
        <Input
            type="text"
            placeholder="Filtrar datos de la tabla..."
            value={filterText}
            onChange={onFilter}
        />

    </InputGroup >
);

export default FilterComponent;