import { createContext, useContext, useReducer } from 'react'

const CalendarContext = createContext()

const inicialState = {
    events: []
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'save': {
            state.events = action.events
            sessionStorage.setItem('calendar', JSON.stringify(state.events))
            return { events: action.events }
        }
        case 'load': {
            state.events = JSON.parse(sessionStorage.getItem('calendar'))
           

            return { events: state.events }
        }
        default:
            {
                state.events = JSON.parse(sessionStorage.getItem('calendar'))
                return { events: state.events }
            }
    }
}

export const CalendarProvaider = (props) => {
    const [state, dispath] = useReducer(reducer, inicialState)

    return (
        <CalendarContext.Provider value={{ ...state, dispath }}>
            {props.children}
        </CalendarContext.Provider>
    )
}

export const useCalendarContext = () => {
    return useContext(CalendarContext)
}