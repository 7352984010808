import { gql } from "@apollo/client";

export const GET_ONE_ADR = gql`
query oneRiskClass($id: Int!) {
    oneRiskClass(id: $id) {
      id
      name
      description
      adr
    }
  }
`
export const GET_ALL_ADR = gql`
query allRiskClass {
    allRiskClass {
      id
      name
      description
      adr
    }
  }
`
export const CREATE_ADR = gql`
mutation createRiskClass($input: RiskCategoryInput!){
    createRiskClass(input: $input)
}
` 
export const UPDATE_ADR = gql`
mutation updateRiskClass($id: Int!, $input: RiskCategoryInput!){
    updateRiskClass(id: $id, input: $input)
  }
` 
export const DELETE_ADR = gql`
mutation deleteRiskClass($id: Int!){
    deleteRiskClass(id: $id)
  }
`