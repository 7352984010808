import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import DataTable from 'react-data-table-component'
import { GET_ALL_COMMERCIAL_PROCESSES } from '../../../../graphql/Catalogs/CommercialProcesses'
import { AlertApp } from '../../../Global/AlertApp'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows'
import { useQuery } from '@apollo/client'

const CommercialProcessList = () => {
  const [list, setList] = useState([])

  const { loading, error, data } = useQuery(GET_ALL_COMMERCIAL_PROCESSES)

  useEffect(() => {
    if (!loading) {
      if (error) {
        AlertApp({
          type: 'errorToast',
          message: error,
        })
      }

      let list = []
      data.allCommercialProcess.map((element) => {

        let buttons = <ActionButtonsForRows
          baseUrl={'settings/sales/commercialprocesses'}
          element={element}
          editButton
          showButton
          deleteButton
        />

        return list.push({
          id: element.id,
          name: element.name,         
          actions: buttons
        })
      })

      setList(list)
    }
  }, [loading, error, data])

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      sortable: false,
      width: '80px',
    },   
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: false,
    },    
    {
      name: '',
      selector: row => row.actions,
      sortable: false,
    },
  ]

  return (
    <DashboardLayout>
      <CardBox
        title={"Procesos comerciales"}
        cardTools
        content={<>
          <DataTable
          data={list}
          columns={columns}
          />
        </>}
      />
    </DashboardLayout>
  )
}

export default CommercialProcessList