import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { Col, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import { FormProvider, useForm } from 'react-hook-form'
import { CurrencyCodesTypesSBO, DivisionTypeOptions } from '../../../Types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import DataTableApp from '../../Layout/DataTableApp'
import ButtonApp from '../../Layout/ButtonApp'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertApp } from '../../Global/AlertApp'
import ChooseFromListModal from '../../Global/ChooseFromListModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIND_BO_ITEM } from '../../../graphql/Catalogs/BoItems'
import { CREATE_QUOTATION_OPPORTUNITY } from '../../../graphql/Catalogs/Opportunities'
import { AuthContext } from '../../../AuthContext'

// apollo
import { GET_ALL_BRANCHS, GET_ALL_BUSINESS_PARTNER, GET_ALL_INTEREST_LEVEL, GET_ALL_SALES_PERSONS, GET_BUSINESS_PARTNER, GET_BUSINESS_PARTNER_CONTACT_PERSONS } from '../../../graphql/QueryManager'

const SalesOpportunityNew = () => {
    const { id: _id, show, cardCode, type: _type, date: _date } = useParams()
    const { user: userContext } = useContext(AuthContext)
    const navigate = useNavigate()
    const [chooseFromList, setChooseFromList] = useState(false)
    const [cardList, setCarList] = useState([])
    const [businessPartner, setBusinessPartner] = useState({})
    const [optOfSalesPersons, setOptOfSalesPersons] = useState([])
    const [optOfContactPerson, setOptOfContactPerson] = useState([])
    const [optOfInterestLevel, setOptOfInterestLevel] = useState([])
    const [optOfBranchs, setOptOfBranchs] = useState([])
    const [contentItems, setContentItems] = useState([])
    const [radioValue, setRadioValue] = useState(1)

    const validationSchema = Yup.object().shape({
        start: Yup.date().required('La fecha de inicio es requerida'),
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        currency: Yup.string().required('La moneda del documento es obligatoria'),
        type: Yup.string().default("S"),
        cardCode: Yup.string().required('El Código de socio de negocio es obligatorio'),
        cardName: Yup.string().required('El Nombre de socio de negocio es obligatorio'),
        slpCode: Yup.number().required('El empleado de ventas es requerido'),
        division: Yup.string().required('Seleccionar una división'),
        intRate: Yup.number().required('Seleccionar una opcion'),
        branch: Yup.string().required('Se debe seleccionar una sucursal'),
        cprCode: Yup.number().notRequired().default(-1),
        indCode: Yup.number().notRequired(),
        memo: Yup.string().notRequired(),
        // quantity: Yup.string().notRequired(),
        // itemCode: Yup.string().notRequired(),
        // itemName: Yup.string().notRequired(),
        // scanner: Yup.string().notRequired(),
    })

    const { handleSubmit, control, methods, getValues, setValue, setFocus } = useForm({ resolver: yupResolver(validationSchema) })
    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingInterestLevel, error: errorInterestLevel, data: dataInterestLevel } = useQuery(GET_ALL_INTEREST_LEVEL, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingBranchs, error: errorBranchs, data: dataBranchs } = useQuery(GET_ALL_BRANCHS, {
        fetchPolicy: 'cache-and-network',
    })

    const [getBusinessPartner, { loading: loadingCard }] = useLazyQuery(GET_BUSINESS_PARTNER)
    const [getAllBusinessPartner, { loading: loadingAllCadrs }] = useLazyQuery(GET_ALL_BUSINESS_PARTNER)
    const [getContacPersons] = useLazyQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS)
    const [getItem] = useLazyQuery(FIND_BO_ITEM)

    const [create, { loading: onBeginCreate }] = useMutation(CREATE_QUOTATION_OPPORTUNITY)

    useEffect(() => {
        if (!_id) {
            setValue("start", moment(new Date()).format("yyyy-MM-DD"), { shouldDirty: true })
            setValue('currency', "MXP")
            setValue('priceType1', true)
            setValue('intRate', 1)
            setValue('division', "DAS")
        }
    }, [_id])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                    })
                })

            }
            setOptOfSalesPersons(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])

    useEffect(() => {
        if (!loadingInterestLevel) {
            if (errorInterestLevel) {
                AlertApp({ type: 'errorToast', message: errorInterestLevel })
            }
            let list = []
            if (dataInterestLevel) {

                dataInterestLevel.allInterestLevel.map((element) => {
                    return list.push({
                        value: element.num,
                        label: element.descript,
                    })
                })

            }
            setOptOfInterestLevel(list)
        }
    }, [loadingInterestLevel, errorInterestLevel, dataInterestLevel])

    useEffect(() => {
        if (!loadingBranchs) {
            if (errorBranchs) {
                AlertApp({ type: 'errorToast', message: errorBranchs })
            }
            let list = []
            if (dataBranchs) {

                dataBranchs.allBranches.map((element) => {
                    return list.push({
                        value: element.code,
                        label: element.code,
                    })
                })

            }
            setOptOfBranchs(list)
        }
    }, [loadingBranchs, errorBranchs, dataBranchs])

    const isWaiting = loadingSalesPerson || loadingCard || loadingAllCadrs

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            width: '80px',
        },
        {
            name: 'SKU',
            selector: row => row.itemCode,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,

        },
        {
            name: 'Cantidad',
            selector: row => row.inputQuantity,
        },
        {
            name: 'Precio Unitario',
            selector: row => row.price,
        },
        {
            name: '',
            selector: row => row.saveButton,
        },
    ]
    const columnCards = [
        {
            name: '#',
            selector: row => `${row.cardCode}`,
            width: '80px',
        },
        {
            name: 'Razon social',
            selector: row => `${row.cardName}`,
        },
        {
            name: 'RFC',
            selector: row => row.licTradNum,
        },
        {
            name: 'Territorio',
            selector: row => row.territry.descript,
        },
        {
            name: 'Industria',
            selector: row => row.industry.indName,
        },
        {
            name: '',
            selector: row => <button
                id={`selectButton${row.id}`}
                key={`selectButton${row.id}`}
                onClick={() => OnSelectRow(row)}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        },
    ]
    const radioOptions = [
        { label: "Una sola exhibicion", value: 1 },
        { label: "Convenio", value: 2 },
    ];
    const onChooseFromList = async () => {
        try {
            const result = await getAllBusinessPartner({
                variables: {
                    code: '',
                    type: 'C'
                }
            })
            setCarList(result.data.allBusinessPartners)
            setChooseFromList(!chooseFromList)
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onHandlerFindCode = async (event) => {
        event.preventDefault()
        try {

            if (event.target.name === "cardCode") {
                const code = getValues('cardCode')
                const result = await getBusinessPartner({
                    variables: {
                        code,
                        type: 'C'
                    }
                })

                if (result.data.oneBusinessPartner) {
                    onSetCard(result.data.oneBusinessPartner)
                }
            }
            if (event.target.name === "cardName") {
                const code = getValues('cardName')
                const result = await getAllBusinessPartner({
                    variables: {
                        code: code,
                        type: 'C'
                    }
                })

                if (result.data.allBusinessPartners) {
                    const list = result.data.allBusinessPartners

                    if (list.length === 1) {
                        onSetCard(list[0])
                        setBusinessPartner(list[0])
                    } else {
                        setCarList(list)
                        setChooseFromList(!chooseFromList)
                    }
                }
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    const OnSelectRow = async (row) => {
        try {
            onSetCard(row)
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setChooseFromList(!chooseFromList)
        }
    }
    const onSetCard = async (element) => {
        setValue("cardCode", element.cardCode, { shouldDirty: true })
        setValue("cardName", element.cardName, { shouldDirty: true })
        setValue("slpCode", element.slpCode, { shouldDirty: true })
        setValue('branch', element.u_Branch, { shouldDirty: true })
        setBusinessPartner(element)
        if (element.u_Division)
            setValue('division', element.u_Division, { shouldDirty: true })
        await onGetAllContacPersons(element.cardCode)
        setFocus("scanner")
    }
    const onGetAllContacPersons = async (cardCode) => {
        const contacs = await getContacPersons({
            variables: { code: cardCode }
        })

        let list = []
        if (contacs.data.allContactPersonByCard) {

            contacs.data.allContactPersonByCard.map((element) => {
                return list.push({
                    value: element.cntctCode,
                    label: element.name,
                })
            })
        }
        if (list.length === 1) {
            setValue('cprCode', list[0].value, { shouldDirty: true })
        }
        setOptOfContactPerson(list)
    }
    const HandlerFinItem = async (event) => {
        try {
            event.preventDefault()
            const scanner = getValues("scanner")
            const result = await getItem({
                variables: {
                    find: scanner
                }
            })

            if (result.data.findBoItem) {
                setValue('itemName', result.data.findBoItem[0].itemName)
                setFocus("quantity")
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    const onHandlerAddRow = async (event) => {
        try {

            const newRow = {
                id: contentItems.length,
                itemCode: getValues('scanner'),
                itemName: getValues('itemName'),
                price: parseFloat(0),
                quantity: parseFloat(getValues('quantity')),
                inputQuantity: <input type={"number"} className={"form-control form-control-sm"} defaultValue={parseFloat(getValues('quantity'))} />,
                inputValue: <input type={"number"} className={"form-control form-control-sm"} defaultValue={parseFloat(getValues('price'))} readOnly={true} />,
            }

            setContentItems(prev => [...prev, newRow])
            setValue('scanner', "")
            setValue('itemName', "")
            setValue('price', "")
            setValue('quantity', "")
            setFocus('scanner')
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
            console.log(error)
        }
    }
    const onSubmit = async (data) => {
        try {
            if (!onBeginCreate) {
                let list = []
                let index = 0;
                contentItems.map((row) => {
                    list.push({
                        id: `SalesOpportunities${index}`,
                        db: 'GALBER',
                        itemCode: row.itemCode,
                        itemName: row.itemName,
                        quantity: row.quantity,
                        itemValue: row.price,
                        line: index,
                        objType: 'SalesOppr',
                        docEntry: 0,
                        docNum: 0,
                        comments: '',

                    })
                    index++;
                })

                const result = await create({
                    variables: {
                        input: {
                            title: data.title,
                            start: data.start,
                            predEnd: moment(data.start).add('days', 7),
                            division: data.division,
                            memo: getValues("memo"),
                            baseID: 2,
                            currentStage: 1,
                            cardCode: businessPartner.cardCode,
                            cardName: businessPartner.cardName,
                            cardType: businessPartner.cardType,
                            slpCode: parseInt(data.slpCode),
                            cprCode: data.cprCode,
                            intRate: parseInt(data.intRate),
                            cloPrcnt: 0.0,
                            status: 'O',
                            reason: null,
                            stepId: 1,
                            currency: data.currency,
                            agreementType: radioValue,
                            branch: data.branch,
                            items: list
                        }
                    }
                })

                if (result.data.createQuotationOpportunity) {
                    AlertApp({ message: 'Operacion finalizada con exito' })
                    navigate('/crm/QuotationOpportunities')
                }
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
            console.log(error)
        }
    }
    return (
        <DashboardLayout >
            <CardBox
                title={"Oportunidad"}
                loading={isWaiting || onBeginCreate}
                footer
                btnSubmit={!show ? handleSubmit(onSubmit) : null}
                btnReturn={() => navigate("/crm/QuotationOpportunities")}
                content={<>
                    <FormProvider  {...methods}>
                        <form>
                            <Row>
                                <Col sm={3} >
                                    <InputApp name={"start"} label={"Fecha de inicio"} inputType='date' control={control} />
                                </Col>
                                <Col sm={5}>
                                    <InputApp name={"title"} label={"Objetivo"} placeholder={"Título o motivo de la solicitud"} control={control} />
                                </Col>
                                <Col sm={4}>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"currency"} label={"Moneda"} inputType={"select"} listOfOptions={CurrencyCodesTypesSBO} control={control} defaultValue={"Abierto"} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"status"} label={"Status"} control={control} defaultValue={"Abierto"} isReadOnly={true} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4}>
                                            <InputApp name={"cardCode"} label={"Código de negocio"} control={control} placeholder={'Enter para buscar'} inputType={`${_id ? 'text' : 'textButton'}`}
                                                faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de clientes`}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }} buttonAction={() => onChooseFromList()}
                                            />
                                        </Col>
                                        <Col sm={8}>
                                            <InputApp name={"cardName"} label={"Nombre de negocio"} control={control} placeholder={'Razón social'} faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de clientes`} inputType={`${_id ? 'text' : 'textButton'}`}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }} buttonAction={() => onChooseFromList()} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"cprCode"} label={"Contacto"} inputType='select' listOfOptions={optOfContactPerson} control={control} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"branch"} label={"Sucursal"} control={control} inputType={"select"} listOfOptions={optOfBranchs} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12}>
                                    <Row>
                                        <Col>
                                            <InputApp name={"slpCode"} label={"Representate"} control={control} inputType={"select"} listOfOptions={optOfSalesPersons} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"division"} label={"Division"} inputType='select' listOfOptions={DivisionTypeOptions} control={control} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"intRate"} label={"Nivel de interes"} control={control} inputType={"select"} listOfOptions={optOfInterestLevel} />
                                        </Col>
                                    </Row>
                                    <Row className={`align-self-center`}>
                                        <Col sm={6} md={3}>
                                            <InputApp name={"agreementType"} inputType={"radioCheck"} label={"Tipo"} listOfOptions={radioOptions} control={control} radioState={radioValue} setRadioState={setRadioValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm={6} md={3}>
                                    <InputApp name={"scanner"} label={"Articulo"} inputType={"textButton"}
                                        placeholder={'Enter para buscar'} control={control} faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de articulos`}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { HandlerFinItem(e) } }} />
                                </Col>
                                <Col sm={6} md={5}>
                                    <InputApp name={"itemName"} label={"Descripcion"} control={control} isReadOnly={true} />
                                </Col>
                                <Col sm={6} md={2}>
                                    <InputApp name={"quantity"} label={"Cantidad"} inputType={"text"} placeholder={"Teclear cantidad"} control={control} />
                                </Col>
                                <Col md={2} className={`align-self-center`}>
                                    <ButtonApp text={"Agregar"} additionalClass={"btn-md"} color="success" action={() => onHandlerAddRow()} actionButtonType={"button"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <DataTableApp
                                        columns={columns}
                                        data={contentItems}
                                        dense
                                    />
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <InputApp name={"memo"} label={"Notas"} placeholder={"Basado en solicitud de precio especial"} inputType={"textarea"} control={control} />
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <p className="lead">{`Fecha de vencimiento ${moment(getValues('start')).add('days', 7).format("yyyy-MM-DD")}`}</p>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th>Volumen:</th>
                                                    <td>{`${contentItems.length > 0 ? contentItems.reduce((n, { quantity }) => n + quantity, 0) : 0}`}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total:</th>
                                                    <td>{`${contentItems.length > 0 ? contentItems.reduce((n, { price, quantity }) => n + (price * quantity), 0) : 0} ${getValues('currency')}`}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>

                            </Row>

                        </form>
                    </FormProvider>

                </>}
            />
            <ChooseFromListModal
                chooseFromList={chooseFromList}
                setChooseFromList={setChooseFromList}
                data={cardList}
                columns={columnCards}
                filter={true}
                selectableRows={false}
                title={`Lista de socios comerciales`}
                size={"xl"} />
        </DashboardLayout>
    )
}

export default SalesOpportunityNew