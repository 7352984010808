import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertApp } from '../../../Global/AlertApp';
import { Col, Row } from 'reactstrap';
import InputApp from '../../../Layout/InputApp';
import { useMutation, useQuery } from '@apollo/client';


import { GET_ALL_COMMERCIAL_PROCESSES, } from '../../../../graphql/Catalogs/CommercialProcesses';
import { GET_BUSINESS_CATEGORY_TASK, CREATE_BUSINESS_CATEGORIE_TASK } from '../../../../graphql/Catalogs/CommercialProcesses/BusinessTaskCategories';
export const CommercialProcessTaskCategoryNew = () => {
    const { id: _id, show } = useParams()
    const [waiting, setWaiting] = useState(false)
    const [optOfCommercialProcesses, setOptOfCommercialProcesses] = useState([])
    const [optOfStages, setOptOfStages] = useState([])

    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        baseID: Yup.number().required('Se debe seleccionar la etapa de Oportunidad'),
        name: Yup.string().required('El nombre de la categoria es obligatorio'),
        commercialStage: Yup.object()
    })

    const { handleSubmit, reset, control, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const { loading, error, data } = useQuery(GET_BUSINESS_CATEGORY_TASK, {
        variables: {
            id: _id ? parseInt(_id) : 0
        },
         fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingCommercialProcess, error: errorCommercialProcess, data: dataCommercialProcess } = useQuery(GET_ALL_COMMERCIAL_PROCESSES)

    const [create] = useMutation(CREATE_BUSINESS_CATEGORIE_TASK)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({
                    type: 'errorToast',
                    message: error,
                })
            }
           
            reset(data.oneBusinessTaskCategory)
    
        }
       
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingCommercialProcess) {
            if (errorCommercialProcess) {
                AlertApp({
                    type: 'errorToast',
                    message: errorCommercialProcess,
                })
            }

            let list = []
            dataCommercialProcess.allCommercialProcess.map((element) => {

                return list.push({
                    value: element.id,
                    label: element.name,
                    source: element
                })
            })

            setOptOfCommercialProcesses(list)
        }
    }, [loadingCommercialProcess, errorCommercialProcess, dataCommercialProcess])

    const selectedCommercialProcess = (event) => {

        const commercialPorcess = optOfCommercialProcesses.find((element) => element.source.id === parseInt(event.target.value))
        if (commercialPorcess) {
            let list = []
            commercialPorcess.source.stages.map((element) => {
                return list.push({
                    value: element.id,
                    step: element.stepId,
                    label: element.name,
                })
            })
            setOptOfStages(list)
        }

    }

    const submitHandler = async (data) => {
        try {

            setWaiting(true)
            if (!_id) {
                await create({
                    variables: {
                        name: data.name,
                        baseID: data.baseID,
                    }
                })

                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialprocesstaskcategory')
            } else {

                await update({
                    variables: {
                        id: parseInt(_id),
                        input: {
                            name: data.name,
                            description: data.description,
                            baseID: data.baseID,
                            stepId: data.stepID,
                            hasValidations: false,
                        }
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/sales/commercialprocesstaskcategory')
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout >
            <CardBox
                title={"Categoria de proceso comercial"}
                loading={waiting}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/sales/commercialprocesstaskcategory")}
                footer
                content={
                    <FormProvider>
                        <form>
                            <Row>
                                <Col>
                                    <InputApp inputType='select' name={"commercialStage.id"} label={"Proceso"} control={control} listOfOptions={optOfCommercialProcesses} changeAction={(e) => selectedCommercialProcess(e)} />
                                </Col>
                                <Col>
                                    <InputApp inputType='select' name={"baseID"} label={"Etapa"} control={control} listOfOptions={optOfStages} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputApp name={"name"} label={"Nombre"} control={control} />
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>
                }
            />
        </DashboardLayout>
    )
}
