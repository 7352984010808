import React, { useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { Button, Col, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import { FormProvider, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataTableApp from '../../Layout/DataTableApp'
import { useLazyQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import { useParams } from 'react-router-dom'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'

// apollo
import { GET_ONE_BIN_LOCATION } from '../../../graphql/Catalogs/Warehouse'
import { USE_SCAN } from '../../../graphql/QueryManager'
import { GET_ONE_DANGEROUS_GOOD_CODE } from '../../../graphql/Catalogs/DangerousGoods'
import ChooseFromListModal from '../../Global/ChooseFromListModal'


const InternalTransferNew = () => {
    const { show } = useParams()
    const [transfer, setTransfer] = useState({
        from: '',
        to: '',
        itemCode: '',
        quantity: "",
        batchNum: "",
        uomCode: ""
    })
    const [waiting, setWaiting] = useState(false)
    const [chooseFromList, setChooseFromList] = useState(false)
    const [chooseFromListBinsTo, setChooseFromListBinsTo] = useState(false)
    const [binSource, setBinSource] = useState({})
    const [binTo, setBinTo] = useState({})
    const [boItem, setBoItem] = useState({})
    const [content, setContent] = useState([])
    const [batchesSource, setBatchesSource] = useState([])
    const [batchesTo, setBatchesTo] = useState([])

    const { control, handleSubmit, methods, getValues, setValue, setError, clearErrors, setFocus } = useForm()

    const [getBinLocation] = useLazyQuery(GET_ONE_BIN_LOCATION, {
        fetchPolicy: 'network-only'
    })
    const [findItem] = useLazyQuery(USE_SCAN, {

    })
    const [getDangerousInfo] = useLazyQuery(GET_ONE_DANGEROUS_GOOD_CODE, {

    })
    const columns = [{
        name: '#',
        selector: row => row.snBMDAbs,
        sortable: false,
        width: '120px',
    },
    {
        name: 'SKU',
        selector: row => row.itemCode,
        sortable: true,
        width: '80px',
    },
    {
        name: 'Descripcion',
        selector: row => row.itemName,
        sortable: true,
        grow: 6,
    },
    {
        name: 'Lote',
        selector: row => row.distNumber,
        sortable: true,
    },
    {
        name: 'Cantidad',
        selector: row => row.onHandQty,
        sortable: false,
    },
    {
        name: '',
        selector: row => row.action,
        sortable: false,
    },
    ]
    const columnsChooseFromList = [{
        name: '#',
        selector: row => row.snBMDAbs,
        sortable: false,
        width: '50px',
    },
    {
        name: 'SKU',
        selector: row => row.itemCode,
        sortable: true,
        width: '80px',
    },
    {
        name: 'Descripcion',
        selector: row => row.itemName,
        sortable: true,
        grow: 6,
    },
    {
        name: 'Lote',
        selector: row => row.distNumber,
        sortable: true,
    },
    {
        name: 'Cantidad',
        selector: row => row.onHandQty,
        sortable: false,
    },
    {
        name: '',
        selector: row => row.action,
        sortable: false,
    },]
    const onBinOrigin = async (event) => {
        try {
            event.preventDefault()
            clearErrors('source')
            setWaiting(true)
            const response = await getBinLocation({
                variables: {
                    code: getValues('source')
                }
            })
            if (response.data.oneBinLocation.absEntry > 0) {
                if (response.data.oneBinLocation.isEmpty) {
                    AlertApp({ type: 'error', message: `La ubicacion ${response.data.oneBinLocation.binCode} esta vacia` })
                    setError('source', { type: "validate", message: `La ubicacion ${response.data.oneBinLocation.binCode} esta vacia` })
                    setValue('source', '')
                } else {
                    let list = []
                    response.data.oneBinLocation.content.map((element) => {

                        element.batches.map((row) => {
                            let cloneElement = { ...row }
                            cloneElement.id = row.snBMDAbs

                            let buttons = <ActionButtonsForRows
                                baseUrl={'/warehouses/binlocations/transfers'}
                                element={row}
                                selectButton
                                selectedAction={() => {
                                    OnFindRowItem(row)
                                }}

                            />
                            cloneElement.action = buttons
                            list.push(cloneElement)
                        })
                    })
                    setBatchesSource(list)
                    setBinSource(response.data.oneBinLocation)

                }
                if (response.data.oneBinLocation.content.length === 1) {
                    setTransfer(prevState => ({ ...prevState, itemCode: response.data.oneBinLocation.content[0].itemCode }))
                    setTransfer(prevState => ({ ...prevState, batchNum: response.data.oneBinLocation.content[0].batchNum }))
                    setTransfer(prevState => ({ ...prevState, quantity: response.data.oneBinLocation.content[0].onHandQty.toString() }))
                }
            } else {
                AlertApp({ type: 'error', message: "No existe la ubicación proporcionada" })
                setError('source', { type: 'validate', message: "No existe la ubicación proporcionada" })
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }

    const onBinDestination = async (event) => {
        event.preventDefault()
        clearErrors('to')
        try {            
            const response = await getBinLocation({
                variables: {
                    code: getValues('to')
                }
            })

            if (response.data) {
                console.log(response.data)
                if (!response.data.oneBinLocation.isEmpty) {
                    AlertApp({ type: 'error', message: `La ubicacion ${response.data.oneBinLocation.binCode} excede su capacidad` })
                    setValue('source', '')
                }
                else {
               
                setBinTo(response.data.oneBinLocation)
                let list = []
                response.data.oneBinLocation.content.map((element) => {

                    element.batches.map((row) => {
                        let cloneElement = { ...row }
                        cloneElement.id = row.snBMDAbs

                        list.push(cloneElement)
                    })
                })
                setBatchesTo(list)
                }
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }

    const OnFindItem = async (event) => {
        event.preventDefault()
        clearErrors('itemCode')
        try {

            setValue('itemName', '')
            setValue('quantity', '')
            setValue('lotNumber', '')
            setBoItem({})
            const result = await findItem({
                variables: { qr: getValues('itemCode') }
            })


            await ValidateItemRows(result.data.useScan.target, result.data.useScan.source)


        } catch (error) {
            console.log(error)
            AlertApp({ type: 'error', message: error })
        }
    }
    const OnFindRowItem = async (row) => {

        clearErrors('itemCode')
        try {

            // setValue('itemName', row.itemCode)
            // setValue('quantity', row.onHandQty)
            // setValue('lotNumber', row.distNumber)
            console.log(binSource)
            setBoItem({})
            const result = await findItem({
                variables: { qr: row.itemCode }
            })
            await ValidateItemRows(result.data.useScan.target, result.data.useScan.source)
        } catch (error) {
            console.log(error)
            AlertApp({ type: 'error', message: error })
        }
    }
    const ValidateItemRows = async (oItem, source) =>{
        try {
            // Buscar el oItem en la lista de contenido de la orden de compra

            console.log(binSource.content)
            if (!binSource.content.some((element) => element.itemCode == oItem.itemCode)) {
                AlertApp({ type: 'error', message: `El ${oItem.itemName} No se encuentra en la ubicacion ${transfer.from}` })
                setError('itemCode', { type: 'notFound', message: `El ${oItem.itemName} No se encuentra en la ubicacion ${transfer.from}` })

                setBoItem({})
            } else {
                setBoItem(oItem)

                if (source == 'Batch') {
                    const batch = binSource.content.find((element) => element.itemCode == oItem.itemCode)
                    const lote = batch.batches.find((element) => element.itemCode == oItem.itemCode)
                    setValue('itemCode', oItem.itemCode)
                    setValue('itemName', oItem.itemName)
                    setValue('quantity', lote.onHandQty)
                    setValue('lotNumber', lote.distNumber)
                }
                if (source != 'Batch') {

                    setValue('itemCode', oItem.itemCode)
                    setValue('itemName', oItem.itemName)
                }
                if (oItem.hazardousMaterial === "Si") {
                    const response = await getDangerousInfo({
                        variables: {
                            id: oItem.itemCode
                        }
                    })
                    const dangerouesInfo = response.data.oneDangerouesItemByCode

                    console.log(binTo.content)
                }
            }
        } catch (error) {
            console.log(error)
            AlertApp({ type: 'error', message: error })
        }
    }
    const OnSelelectRowsSource = (selectedRows) => {
        clearErrors('to')
        if (binTo.binCode) {
            selectedRows.map((element) => {
                setContent(prevArray => [...prevArray, element])
            })
        } else {
            setChooseFromList(false)
            setError('to', { type: 'required', message: 'Se requiere la ubicación destino' })
            setFocus('to')
            AlertApp({ type: 'errorToast', message: 'No se a seleccionado la Ubicación destino' })
        }
    }
    const SuggestLocationsHandler = () => {
        clearErrors('source')
        clearErrors('itemCode')
        if (!binSource.binCode) {
            setError('source', { type: 'required', message: "Ubicacion origen" })
            return AlertApp({ type: 'errorToast', message: 'La ubicacion origen no se indicado' })
        }
        if (boItem.itemCode) {

        } else {
            setError('itemCode', { type: 'required', message: "Se debe capturar almenos un articulo" })
            return AlertApp({ type: 'errorToast', message: 'Se debe capturar almenos un articulo' })
        }
    }
    const submitHandler = (data) => {

    }
    return (
        <DashboardLayout>
            <CardBox
                title={`Generar traspaso interno`}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                content={<>
                    <FormProvider {...methods}>
                        <form>
                            <Row>
                                <Col lg={5}>
                                    <InputApp name={"source"} label={"Ubicación"} placeholder='Escanear código de ubicación origen' control={control}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { onBinOrigin(e) } }} isLoading={waiting} />
                                </Col>
                                <Col lg={1} className='d-flex align-items-center justify-content-center'>
                                    <Button type="button" color='success' onClick={() => SuggestLocationsHandler()} ><FontAwesomeIcon icon={"right-left"} /></Button>
                                </Col>
                                <Col lg={5}>
                                    <InputApp name={"to"} label={"Ubicación"} placeholder='Escanear código de ubicación destino' control={control}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { onBinDestination(e) } }} isLoading={waiting}
                                        inputType='textButton' buttonIsDisabled={!(binTo.content?.length > 0)} faIcon='fa-solid fa-list' buttonAction={() => {
                                            setChooseFromListBinsTo(!chooseFromListBinsTo)
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8}>
                                    <Row>
                                        <Col lg={4}>
                                            <InputApp name={"itemCode"} label={"Numero de articulo"} placeholder={"Codigo de barras, No de articulo o Lote"} isReadOnly={binSource.isEmpty} disabled={!binSource.content} control={control}
                                                inputType='textButton' faIcon='fa-solid fa-list'
                                                onKeyDown={(e) => { if (e.key === 'Enter') { OnFindItem(e) } }}
                                                buttonIsDisabled={!binSource.content} buttonAction={() => setChooseFromList(!chooseFromList)} />
                                        </Col>
                                        <Col>
                                            <InputApp name={"itemName"} label={"Descripcion de articulo"} control={control} disabled={!binSource.content} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <InputApp name={"quantity"} label={"Cantidad"} onlyNumbers control={control} disabled={!binSource.content} />
                                        </Col>
                                        <Col>
                                            <InputApp name={"lotNumber"} label={"No de Lote o serie"} control={control} disabled={!binSource.content} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button ><FontAwesomeIcon icon={"plus"} /> Agregar</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DataTableApp
                                        dense
                                        highlightOnHover
                                        handOnHover
                                        data={content}
                                        columns={columns}
                                    />
                                </Col>

                            </Row>
                        </form>
                    </FormProvider>
                </>}
            />
            <ChooseFromListModal
                chooseFromList={chooseFromList}
                setChooseFromList={setChooseFromList}
                data={batchesSource}
                columns={columnsChooseFromList}
                title={`Contenido de la Ubicación ${binSource.binCode}`}
                size={"xl"}
                onSelectedAction={OnSelelectRowsSource}
            />
            <ChooseFromListModal
                chooseFromList={chooseFromListBinsTo}
                setChooseFromList={setChooseFromListBinsTo}
                data={batchesTo}
                columns={columnsChooseFromList}
                title={`Contenido de la Ubicación ${binTo.binCode}`}
                size={"xl"}
            />
        </DashboardLayout>
    )
}

export default InternalTransferNew