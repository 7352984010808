import React from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DashboardLayout } from '../Layout/DashboardLayout'
import CardBox from '../Layout/CardBox'
import InputApp from '../Layout/InputApp'
import { useForm } from 'react-hook-form'
import { Row } from 'reactstrap'
export const InitialSettings = () => {

    const validationSchemaAdd = Yup.object().shape({
        dboConectionString: Yup.string().required('La cadena de conexion es obligatoria').test('empty-check', function (value) {
            return value.length > 0
        }),

    })

    const { control } = useForm ({
        resolver: yupResolver(validationSchemaAdd),
    })
    return (
        <DashboardLayout>
            <CardBox
                title={"Configuraciones iniciales"}
                content={<>
                    <form>
                        <Row>
                        <InputApp label={"Conexión a la base de datos"} name={"dboConectionString"} control={control} inputType={"textButton"} buttonActionText={"Validar"} />    
                        </Row>
                    </form>
                </>}
            />
        </DashboardLayout>
    )
}
