import { gql } from "@apollo/client";

export const GET_CALENDAR = gql`
query calendar {
    calendar {
      id
      groupId
      allDay
      start
      end
      startStr
      endStr
      title
      url
      classNames
      editable
      startEditable
      durationEditable
      resourceEditable
      display
      backgroundColor
      borderColor
      textColor
    }
  }
`

export const GET_CALENDAR_PURCHASE_ORDER = gql`
query calendar {
  calendarPurchaseOrders {
      id
      groupId
      allDay
      start
      end
      startStr
      endStr
      title
      url
      classNames
      editable
      startEditable
      durationEditable
      resourceEditable
      display
      backgroundColor
      borderColor
      textColor
    }
  }
`