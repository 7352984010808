import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col,  Row } from 'reactstrap'
import { InputApp } from '../../Layout/InputApp'
import { FormProvider, useForm } from 'react-hook-form';
import { ActionsSBO } from '../../../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parse, isDate } from "date-fns";
import DualListBox from 'react-dual-listbox';
import moment from 'moment';

function ScheduleSalesVisit({ opportunity = null, create = null, }) {
    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionsTask, setOptionsTask] = useState([])
    const [optionsToDo, setOptionsToDo] = useState([])
    const [selectedToDo, setSelectedToDo] = useState([])
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('El tipo de activadad es requerido'),
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        actionType: Yup.number().notRequired(),
        taskType: Yup.number().notRequired(),
        actionDate: Yup.date()
            .typeError('Expected a value of type ${type} but got: ${value}')
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date() - new Date(24 * 60 * 60 * 1000))
                return parsedDate
            })
            .typeError('Se debe seleccionar una fecha para la visita')
            .min( moment(new Date()).toDate(), `Min start date is ${new Date().toLocaleString('en-US', {dateStyle: 'short'})}`),
        tasksID: Yup.array(),
    })
    const { control, methods, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) })

    useEffect(() => {
        if (opportunity) {
            let categorys = []
            let options = []
            opportunity.commercialProcess.stages.map((element) => {

                element.categories.map((category) =>
                    category.tasks.map((task) => {
                        if (opportunity.content.some((row) => row.task.id == task.id)) {
                            const content = opportunity.content.find((row) => row.task.id == task.id)
                            if (!content.complete)
                                return options.push({
                                    value: task.id,
                                    label: task.name,
                                })
                        }
                    })
                )

                return categorys.push({
                    value: element.id,
                    label: element.name,
                })
            })
            setOptionsCategory(categorys)
            setOptionsToDo(options.sort((element) => element.id))
        }
    }, [opportunity])

    const onSeletCategory = (event) => {
        let task = []
        const stage = opportunity.commercialProcess.stages.find((element) => element.id == event.target.value)
        stage?.categories.map((element) => {
            return task.push({
                value: element.id,
                label: element.name,
                tasks: element.tasks,
            })
        })
        setOptionsTask(task)
    }
    const onSeletTasks = (event) => {

        const category = optionsTask.find((element) => element.value == event.target.value)
        if (optionsTask.length > 0) {
            let task = []

            category.tasks.map((element) => {
                if (opportunity.content.some((row) => row.task.id == element.id)) {
                    const content = opportunity.content.find((row) => row.task.id == element.id)
                    if (!content.complete)
                        if (!selectedToDo.some((row) => row == element.id))
                            return task.push({
                                value: element.id,
                                label: element.name,
                            })
                }
            })
            setOptionsToDo(task)
        }
    }
    const submitHandler = async (data) => {
        try {
            const taskList = []
            selectedToDo.map((row) => {
                const content = opportunity.content.find((element) => element.task.id == row)
                taskList.push(content.id)
            })
            await create({
                variables: {
                    input: {
                        opportunityID: opportunity.id,
                        action: data.action,
                        title: data.title,
                        cntctType: data.actionType,
                        cntctSbjct: data.taskType,
                        tasksID: selectedToDo,
                        actionDate: data.actionDate,
                        actionTime: data.actionTime,
                    }
                }
            })
        } catch (error) {
            
        }
    }
    return (
        <Card className='card-info'>
            <CardHeader>
                <CardTitle>Agendar visita</CardTitle>
            </CardHeader>
            <FormProvider  {...methods}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        General
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <InputApp name={"action"} label={"Actividad"} inputType='select' listOfOptions={ActionsSBO} control={control} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputApp name={"title"} label={"Titulo"} control={control} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputApp name={"actionType"} label={"Categoria"} inputType='select' listOfOptions={optionsCategory} control={control} changeAction={(e) => onSeletCategory(e)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputApp name={"taskType"} label={"Tarea"} inputType='select' listOfOptions={optionsTask} control={control} changeAction={(e) => onSeletTasks(e)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputApp name={"actionDate"} label={"Fecha de actividad"} inputType='date' control={control} />
                                            </Col>
                                            <Col>
                                                <InputApp name={"actionTime"} label={"Hora de actividad"} inputType='datetime' control={control} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <CardTitle><i className="ion ion-clipboard mr-1"></i> Lista de tareas por realizar</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <DualListBox
                                                    id={"tasksID"}
                                                    name={"tasksID"}
                                                    options={optionsToDo}
                                                    selected={selectedToDo}
                                                    onChange={(newValue) => setSelectedToDo(newValue)}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <button
                            type="submit"
                            onClick={() => { }}
                            className={`btn btn-primary`}
                        >
                            <FontAwesomeIcon icon={"plus"} /> Guardar
                        </button>
                        <button
                            type="button"
                            onClick={() => { }}
                            className="btn btn-secondary"
                        >
                            <FontAwesomeIcon icon={"times"} /> Cancelar
                        </button>
                    </CardFooter>
                </form>
            </FormProvider>
        </Card>
    )
}

export default ScheduleSalesVisit