import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export const AlertApp = async ({
    type = 'ok',
    message = '',    
    okButtonText = 'Ok',
    cancelButtonText = 'Cancelar',
    content = null,
    footer = "",
    ... props
}) => {
    const MySwal = withReactContent(Swal)
    switch (type) {
        case 'ok':
            return MySwal.fire({
                icon: 'success',
                text: `${message}`,
                customClass: {
                    confirmButton: 'btn btn-sm btn-accept',
                },
                confirmButtonText: okButtonText,
            })
        case 'error': {
            return MySwal.fire({
                icon: 'error',
                text: `${message}`,
                confirmButtonText: okButtonText,
                customClass: {
                    confirmButton: 'btn btn-sm btn-accept',
                },
                confirmButtonText: okButtonText,
                footer: footer,
                
            })
        }
        case 'question': {
            return MySwal.fire({
                icon: 'warning',
                text: `${message}`,                         
                showCancelButton: true,
                ...props
            })
        }
        case 'errorToast': {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            return Toast.fire({
                icon: "error",
                title: `${message}`,
            });
        }
        case 'infoToast': {
            const Toast = Swal.mixin({
            
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            return Toast.fire({
                icon: "info",
                title: `${message}`,
            });
        }
        case 'okToast': {
            const Toast = Swal.mixin({
                icon: "ok",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            return Toast.fire({
                
                title: `${message}`,
            });
        }
        case 'react': {

            return MySwal.fire({
                showConfirmButton: false,
                showCancelButton: false,
                width: '1000px',
                html: (content),
            })
        }
        default:
            return Swal.fire({
                text: `${message}`,
                customClass: {
                    confirmButton: 'btn btn-sm btn-accept',
                },
            })
    }
}
