import React, { useContext, useEffect } from 'react';
import { NavSidebar } from './NavSidebar';
import { Header } from "./Header";
import { AuthContext } from '../../AuthContext';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useCalendarContext } from '../../CalendarContext';

const BodyWrapper = ({ children }) => {
  const { user: userContext } = useContext(AuthContext)
  const { events, dispath: calendarDispath } = useCalendarContext()

  useEffect(() => {
    if (events) {
      if (events.length === 0) { 
        
      }
    }
  }, [events])
  return (
    <>
      <NavSidebar user={userContext} />
      <Header />
      <div className='content-wrapper'>
        {children}
      </div>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-inline">
          WMS
        </div>
        <strong>Galber, S.A de C.V &copy; <a href="https://galber.com/">AdminLTE.io</a>.</strong> All rights reserved.
      </footer>
    </>
  )
}

export default BodyWrapper;