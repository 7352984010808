import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner } from 'reactstrap'
import ButtonApp from '../../Layout/ButtonApp'
import { useQuery } from '@apollo/client'
import InputApp from '../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
// apollo
import { GET_ALL_ACTIVITIES_TYPES, GET_ACTIVITIES_INBOX } from '../../../graphql/Catalogs/Activities'
import { AlertApp } from '../../Global/AlertApp'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SalesInbox = () => {
  const [types, setTypes] = useState([])
  const [dataList, setDataList] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    skip: 0,
    take: 50,
  })
  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    tasksCount: 0,
  })
  const { loading, data, error, refetch } = useQuery(GET_ACTIVITIES_INBOX, {
    variables: {
      skip: pagination.skip,
      take: pagination.take,
    },
    fetchPolicy: 'cache-and-network'
  })
  const { loading: loadingTypes, error: errorTypes, data: dataTypes } = useQuery(GET_ALL_ACTIVITIES_TYPES, {
    fetchPolicy: 'cache-and-network'
  })

  const { control } = useForm()

  useEffect(() => {
    if (!loading) {
      if (error) {
        AlertApp({ type: 'errorToast', message: error })
      } else {
        let list = []


        setPageInfo({
          hasNextPage: data.salesInbox.pageInfo.hasNextPage,
          hasPreviousPage: data.salesInbox.pageInfo.hasPreviousPage,
          totalCount: data.salesInbox.totalCount,
          tasksCount: data.salesInbox.tasksCount,
        })
        data.salesInbox.items.map((element) => {

          let cloneElement = { ...element }
          cloneElement.id = element.activityCode

          let buttons = <ActionButtonsForRows
            baseUrl={'crm/activities'}
            element={{ id: element.activityCode }}
            editButton={element.closed == "tNO"}
            showButton
            deleteButton={element.closed == "tNO"}
            deleteAction={() => submitCancelActivity(element)}
          />

          cloneElement.actions = buttons
          return list.push(cloneElement)
        })
        setDataList(list)
      }
      
    }
  }, [loading, data, error])
  useEffect(() => {
    if (!loadingTypes) {
      if (errorTypes) {
        AlertApp({ type: 'errorToast', message: errorTypes })
      } else {
        let list = []
        dataTypes.allActivityTypes.map((element) => {
          return list.push({
            code: element.code,
            name: element.name,
            total: 0
          })
        })
        setTypes(list)
      }
    }
  }, [loadingTypes, errorTypes, dataTypes])

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      sortable: false,
      width: '70px',
    },
  ]
  return (
    <DashboardLayout>
      <Row>
        <Col lg={2} md={2}>
          <ButtonApp text={`Agendar`} additionalClass={"btn-block mb-3"} color={"primary"} />
          <Card>
            <CardHeader>
              <CardTitle>Actividades</CardTitle>
              <div className={"card-tool"}>
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </CardHeader>
            <CardBody className={"p-0"}>
              <ul className={"nav nav-pills flex-column"}>
                <li className="nav-item active">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon icon={"fa-solid fa-list-check"} />{' Tareas'}
                    <span className="badge bg-primary float-right">{pageInfo.tasksCount}</span>
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon icon={"fa-solid fa-handshake"} />{' Reuniones'}
                    <span className="badge bg-primary float-right">{dataList.filter((s) => s.closed === "tNO" && s.activityProperty === "cn_Meeting").length}</span>
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon icon={"fa-solid fa-square-phone"} /> {'Llamadas telefónica'}
                    <span className="badge bg-primary float-right">{dataList.filter((s) => s.closed === "tNO" && s.activityProperty === "cn_Conversation").length}</span>
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon icon={"fa-solid fa-square-check"} /> {'Finalizadas'}
                    <span className="badge bg-success float-right">{dataList.filter((s) => s.closed === "tYES").length}</span>
                  </a>
                </li>
                {/* {types.map((element) => {
                  return <li className="nav-item active">
                    <a href="#" className="nav-link">
                      <i className="fas fa-inbox"></i> {element.name}
                      <span className="badge bg-primary float-right">{element.total}</span>
                    </a>
                  </li>
                })} */}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col lg={10} md={10}>
          <Card className={"card card-primary card-outline"}>
            <CardHeader>
              <CardTitle>Inbox</CardTitle>
              <div className="card-tools">
                <InputApp name={"filter"} inputType={"textButton"} placeholder="Buscar Actividad" control={control} />
              </div>
            </CardHeader>
            <CardBody className={"p-0"}>
              <div className="mailbox-controls">
                <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square"></i>
                </button>
                <div className="btn-group">
                  <button type="button" className="btn btn-default btn-sm">
                    <i className="far fa-trash-alt"></i>
                  </button>
                  <button type="button" className="btn btn-default btn-sm">
                    <i className="fas fa-reply"></i>
                  </button>
                  <button type="button" className="btn btn-default btn-sm">
                    <i className="fas fa-share"></i>
                  </button>
                </div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => refetch({
                  skip: pagination.skip,
                  take: pagination.take,
                })}>
                  <i className="fas fa-sync-alt"></i>
                </button>

                {/* .float-right  */}
                <div className="float-right">
                  {`${pagination.page}-${Math.ceil(pageInfo.totalCount / pagination.take)}/${pageInfo.totalCount} `}
                  <div className="btn-group">
                    <button type="button" disabled={!pageInfo.hasPreviousPage} onClick={() => setPagination({ page: pagination.page - 1, skip: pagination.skip - 15, take: pagination.take })} className="btn btn-default btn-sm">
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <button type="button" disabled={!pageInfo.hasNextPage} onClick={() => setPagination({ page: pagination.page + 1, skip: pagination.skip + 15, take: pagination.take })} className="btn btn-default btn-sm">
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>

                </div>
              </div>
              <div className="table-responsive mailbox-messages">
                {loading ? (<Spinner >{' '}</Spinner>) : (
                  <table className={"table table-hover table-striped"}>
                    <tbody>
                      {dataList.map((element) => {
                        return <tr key={element.id}>
                          <td>
                            <div className="icheck-primary">
                              <input type="checkbox" value="" id={`table-check${element.id}`} />
                            </div>
                          </td>
                          <td><a href="#"><FontAwesomeIcon icon={element.activityStateIcon} className={`text-${element.activityState}`} /></a></td>
                          <td className="mailbox-name"><a href="read-mail.html">{element.details}</a></td>
                          <td className="mailbox-subject"><b>{element.cardCode}</b> {`- ${element.cardName}`}</td>
                          <td className="mailbox-attachment"><i class="fas fa-paperclip" /></td>
                          <td className="mailbox-date">{moment(element.activityDate).format('YYYY-MM-DD')}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                )}

              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  )
}

export default SalesInbox