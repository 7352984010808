import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import { useForm } from 'react-hook-form';
import { ActionsSBO } from '../../../Types';
import { parse, isDate } from "date-fns";
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SALES_VISIT, SAVE_ANSWER_TASK } from '../../../graphql/Catalogs/ScheduleSalesVisit';
import { AlertApp } from '../../Global/AlertApp';
import avatar from '../../../assets/images/AdminLTELogo.png'
import { GET_ALL_BUSINESS_CATEGORIES_TASK } from '../../../graphql/Catalogs/CommercialProcesses/BusinessTaskCategories';

function SalesVisitNew() {
    const { id: _id, show } = useParams()
    const [waiting, setWaiting] = useState(false)
    const [salesVisit, setSalesVisit] = useState([])
    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionsTask, setOptionsTask] = useState([])
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('El tipo de activadad es requerido'),
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        actionType: Yup.number().notRequired(),
        taskType: Yup.number().notRequired(),
        actionDate: Yup.date()
            .typeError('Expected a value of type ${type} but got: ${value}')
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date())

                return parsedDate
            })
            .typeError('Se debe seleccionar una fecha para la visita')
            .min(moment(new Date()).toDate(), `Min start date is ${new Date().toLocaleString('en-US', { dateStyle: 'short' })}`),
        actionTime: Yup.string(),
    })
    const { control, reset, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    const { loading, error, data } = useQuery(GET_SALES_VISIT, {
        variables: {
            id: _id ? parseInt(_id) : null
        }
    })

    const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(GET_ALL_BUSINESS_CATEGORIES_TASK)
    const [saveAnswer] = useMutation(SAVE_ANSWER_TASK)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'error', message: error })
            }
            if (data) {
                setSalesVisit(data.oneSalesVisit)
                reset(data.oneSalesVisit)

            }
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingCategories) {
            if (errorCategories) {
                AlertApp({ type: 'error', message: errorCategories })
            }
            if (dataCategories) {
                let list = []
                let tasks = []
                dataCategories.allBusinessTaskCategories.map((element) => {
                    element.tasks.map((task) => {
                        return tasks.push({
                            value: task.id,
                            label: task.name
                        })
                    })
                    return list.push({
                        value: element.id,
                        label: element.name,
                    })
                })
                setOptionsTask(tasks)
                setOptionsCategory(list)
            }
        }
    }, [loadingCategories, errorCategories, dataCategories])

    const confirmTask = async (element) => {

        try {
            setWaiting(true)
            const result = await saveAnswer({
                variables: {
                    baseID: parseInt(_id),
                    id: element.opportunityRowID,
                    answer: getValues(`content-answer-${element.id}`)
                }
            })
            AlertApp({type: 'okToast', message: 'Operacion finalizada con exito'})
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={`Visita ${_id}`}
                loading={loading}
                content={
                    <>
                        <Row>
                            <form>
                                <Row>
                                    <Col>
                                        <InputApp name={"title"} label={"Titulo"} control={control} isReadOnly={show} />
                                    </Col>
                                    <Col>
                                        <InputApp name={"action"} label={"Actividad"} inputType='select' listOfOptions={ActionsSBO} control={control} isReadOnly={show || _id} />
                                    </Col>
                                    <Col>
                                        <InputApp name={"actionDate"} label={"Fecha de actividad"} inputType='date' control={control} isReadOnly={show || _id} />
                                    </Col>
                                    <Col>
                                        <InputApp name={"actionTime"} label={"Hora de actividad"} inputType='datetime' control={control} isReadOnly={show || _id} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={3}>
                                        <InputApp name={"cntctType"} label={"Categoria"} inputType='select' listOfOptions={optionsCategory} control={control} changeAction={(e) => onSeletCategory(e)} isReadOnly={show || _id} />

                                    </Col>
                                    <Col lg={3} md={3}>
                                        <InputApp name={"cntctSbjct"} label={"Tarea"} inputType='select' listOfOptions={optionsTask} control={control} changeAction={(e) => onSeletTasks(e)} isReadOnly={show || _id} />
                                    </Col>
                                </Row>
                            </form>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>Tareas</CardHeader>
                                    <CardBody>
                                        {salesVisit.tasks?.map((element) => {
                                            return <div key={element.id} className='post clearfix'>
                                                <div className='user-block'>
                                                    <img className="img-circle img-bordered-sm" src={avatar} alt="User Image" />
                                                    <span className='username'>
                                                        <spam className="text-primary" >{element.detail.task.category.name}</spam>
                                                    </span>                                                    
                                                    <span className='description'></span>
                                                    <p>
                                                        {element.detail.task.name}
                                                    </p>
                                                    <p>
                                                        <button type={'button'} className="btn btn-success btn-sm mr-2" onClick={() => confirmTask(element)}><i className="far fa-file-alt mr-1"></i> Guardar</button>
                                                    </p>
                                                </div>
                                                <InputApp name={`content-answer-${element.id}`} defaultValue={element.detail.answer} additionalClass='form-control-sm' placeholder="Escribe un comentario" control={control} />
                                            </div>
                                        })}

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
            />
        </DashboardLayout>
    )
}

export default SalesVisitNew